import { downloadExcelFile } from "../util/downloadFiles";
import { getData } from "../util/localStorageService";
import apiInstance from "./apiInstance";

const subUrl = "/report";

const attentionReport = async (values, setLoading) => {

    const user = getData("user");
    const account = getData("account");
    await apiInstance.get(`${subUrl}/attention`, {
        responseType: "arraybuffer",
        params: {
            date_finish: values.dateFinish,
            date_init: values.dateInit,
            id_account: account.id
        },
        headers: {
            "x-auth-token": user.authToken
        }
    }).then((response) => {
        downloadExcelFile(response, "Sabana_datos");
        setLoading(false);
    });

}

export { attentionReport }

import { getData } from '../util/localStorageService';
import apiInstance from './apiInstance';

const subUrl = `/complexity`;

const findAll = async (setComplexities) => {

    const user = getData("user");
    await apiInstance.get(`${subUrl}/complexities`, {
        headers: {
            "x-auth-token": user.authToken
        }
    }).then((response) => {
        setComplexities(response.data);
    });

}

export { findAll }

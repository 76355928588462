import { getData } from '../util/localStorageService';
import apiInstance from './apiInstance';

const subUrl = `/subproblem`

const findAll = async (idProblem, setSubProblems) => {

    const user = getData("user");
    await apiInstance.post(`${subUrl}/subproblems`, {
        id_problem: idProblem
    },
        {
            headers: {
                "x-auth-token": user.authToken
            }
        }).then((response) => {
            setSubProblems(response.data);
        });
}

export { findAll }

import { Button, CircularProgress, Container, Grid, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect, useParams } from 'react-router-dom';
import { findById, update } from '../../api/account';
import { accountFormValidationSchema } from '../../validations/accountValidations';
import Section from '../Section';

const title = "Editar cuenta";

function EditAccount() {

  const { idCuenta } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState([]);

  useEffect(() => {
    findById(idCuenta, setAccount);
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: account?.name ?? "",
      description: account?.description ?? ""
    },
    onSubmit: (values) => {
      setLoading(true);
      update(idCuenta, values, setRedirect, setLoading);
    },
    validationSchema: accountFormValidationSchema
  });

  if (account === null) {
    return <Redirect to="/cuentas/index" />
  }

  if (redirect) {
    return <Redirect to="/cuentas/index" />
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>Los campos marcados con * son obligatorios.</Typography>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Nombre *"
                      type="text"
                      variant="outlined"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      label="Descripción"
                      type="text"
                      variant="outlined"
                      multiline
                      inputProps={{ maxLength: 1000 }}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                    />
                  </Grid>
                  {/* <Grid item={true} xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel control={<Switch checked color="primary" />} label="Habilitado" />
                    </FormGroup>
                  </Grid> */}

                  <Grid item={true} xs={6} container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      component={Link}
                      to="/cuentas/index"
                    >
                      Volver
                    </Button>
                  </Grid>

                  <Grid item={true} xs={6} justifyContent="flex-end" container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={25} color='inherit'/> : 'Guardar'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  )
}

export default EditAccount
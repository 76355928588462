import { Button, CircularProgress, Container, Grid, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { create } from '../../api/account';
import { accountFormValidationSchema } from '../../validations/accountValidations';
import Section from '../Section';

const title = "Crear cuenta";

function CreateAccount() {

  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: ""
    },
    onSubmit: (values) => {
      setLoading(true);
      create(values, setRedirect, setLoading)
    },
    validationSchema: accountFormValidationSchema
  });

  if (redirect) {
    return <Redirect to="/cuentas/index" />
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>Los campos marcados con * son obligatorios.</Typography>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Nombre *"
                      type="text"
                      variant="outlined"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      label="Descripción"
                      type="text"
                      variant="outlined"
                      multiline
                      inputProps={{ maxLength: 1000 }}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                    />
                  </Grid>
                  {/* <Grid item={true} xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel control={<Switch checked color="primary" />} label="Habilitado" />
                    </FormGroup>
                  </Grid> */}

                  <Grid item={true} xs={6} container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      component={Link}
                      to="/cuentas/index"
                    >
                      Volver
                    </Button>
                  </Grid>

                  <Grid item={true} xs={6} justifyContent="flex-end" container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={25} color='inherit' /> : 'Crear'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  )
}

export default CreateAccount
import { isRole } from "../config/roles";
import { downloadExcelFile } from "../util/downloadFiles";
import { getData } from "../util/localStorageService";
import { displayClientError, displaySuccessAlert } from "../util/swalMessages";
import { upperCaseWords } from "../validations/regexValidations";
import apiInstance from "./apiInstance";

const subUrl = `/account`;
//40.84.187.251:3003/account/list?id=8&type=0

const findAllActive = async (setAccounts) => {

  const user = getData("user");
  await apiInstance.get(`${subUrl}/list`, {
    params:{
      id: user.id,
      type: 1
    },
    headers:{
      "x-auth-token": user.authToken,
    }
  }).then((response) => {
    setAccounts(response.data);
  });

}

const findAll = async (values, setAccounts) => {

  const user = getData("user");
  await apiInstance.get(
    `${subUrl}/accounts`,
    {
      params: {
        id: isRole(user.description, 0, 1) ? "" : user.id,
        name: values?.name ?? "",
        description: values?.description ?? "",
      },
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    setAccounts(response.data);
  });

}

const create = async (values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/create`,
    {
      name: upperCaseWords(values.name),
      description: values.description,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then(() => {
    window.dispatchEvent(new Event("listUpdateStorage"));
    setRedirect(true)
    displaySuccessAlert();
  }).catch(() => {
    setLoading(false);
  });

}

const findById = async (id, setAccount) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/account`,
    {
      id,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    if (!response.data) { setAccount(null); displayClientError(); return }
    setAccount(response.data);
  });

}

const changeStatus = async (type, id, value, setAccountStatus) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/change_status`,
    new URLSearchParams({
      id,
      type,
      value,
    }),
    {
      headers: {
        "x-auth-token": user.authToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then(() => {
    setAccountStatus(true);
    window.dispatchEvent(new Event("listUpdateStorage"));
  });

}

const update = async (id, values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/update`,
    {
      id,
      name: upperCaseWords(values.name),
      description: values.description,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then(() => {
    window.dispatchEvent(new Event("listUpdateStorage"));
    setRedirect(true);
    displaySuccessAlert();
  }).catch(() => {
    setLoading(false);
  });

}

const exportExcel = async (values) => {

  const user = getData("user");
  await apiInstance.get(`${subUrl}/excel`, {
    responseType: "arraybuffer",
    params: {
      name: values.name,
      description: values.description
    },
    headers: {
      "x-auth-token": user.authToken,
    },
  }).then((response) => {
    downloadExcelFile(response, "Reporte_cuentas");
  });

}

export { findAllActive, findAll, create, findById, changeStatus, update, exportExcel }


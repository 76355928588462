import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton, TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Add, Delete, MeetingRoom, Remove } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { exportExcel, findAllByUser, findAllFilter } from "../../api/case";
import { findAll as findAllComplexities } from "../../api/complexities";
import { findAllNoFilter } from "../../api/person";
import { findAll as findAllProblems } from '../../api/problem';
import { findAll as findAllSubProblems } from "../../api/subProblem";
import { caseStatus, caseStatusSelect } from "../../config/caseStatus";
import { roles } from "../../config/roles";
import AuthContext from "../../context/AuthProvider";
import { getData, saveData } from "../../util/localStorageService";
import { formatRut } from "../../validations/regexValidations";
import { componentPropsDataGrid, customLocaleText } from "../CustomToolbar";
import Section from "../Section";
import { ChangeCaseStatus, DeleteCase } from "./CaseTableActions";
import { DeleteFilter } from "../DeleteFilter";

// const canSeeCase = roles;
const fromAdminToSupervisor = roles.slice(0, 2);
const canSeeActions = roles.slice(0, 3);

const title = "Casos";
const columns = [
  {
    field: "irAlCaso",
    headerName: "Caso",
    width: 70,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <>
          <IconButton
            component={Link}
            to={"/atenciones/index/" + params.row.id}
            variant="contained"
            size="small"
            color="secondary"
            title="Ir al caso"
          >
            <MeetingRoom />
          </IconButton>
        </>
      );
    },
  },
  {
    field: "acciones",
    headerName: "Eliminar",
    width: 70,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <DeleteCase caseId={params.row.id} />
    },
  },
  {
    field: "habilitado",
    headerName: "Habilitado",
    width: 70,
    sortable: false,
    headerAlign: "center",
    renderCell: (params) => {
      return <ChangeCaseStatus caseId={params.row.id} status={params.row.status} />
    },
  },
  {
    field: "user",
    headerName: "Usuario",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.user?.name} {params.row.user?.lastname}</div>
    }
  },
  {
    field: "rut_person",
    headerName: "RUT persona",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.person?.rut}</div>
    }
  },
  {
    field: "personName",
    headerName: "Nombre persona",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "left",
    renderCell: (params) => {
      return <div>{params.row.person?.name}</div>
    }
  },
  {
    field: "clientIdentifier",
    headerName: "Identificador sucursal",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "left",
    renderCell: (params) => {
      return <div>{params.row.person?.client?.identifier}</div>
    }
  },
  {
    field: "clientName",
    headerName: "Nombre sucursal",
    flex: 1,
    minWidth: 200,
    headerAlign: "center",
    align: "left",
    renderCell: (params) => {
      return <div>{params.row.person?.client?.branch_office}</div>
    }
  },
  {
    field: "problem",
    headerName: "Problemática",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "left",
    renderCell: (params) => {
      return <div>{params.row.sub_problem?.problem?.description}</div>
    }
  },
  {
    field: "subProblem",
    headerName: "Sub-problemática",
    flex: 1,
    minWidth: 250,
    headerAlign: "center",
    align: "left",
    renderCell: (params) => {
      return <div>{params.row.sub_problem?.description}</div>
    }
  },
  {
    field: "complexity",
    headerName: "Complejidad",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.complexity?.description}</div>
    }
  },
  // {
  //   field: "confidential",
  //   headerName: "Confidencial",
  //   flex: 1,
  //   minWidth: 80,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return <div>{params.row.confidential === 0 ? 'Si' : 'No'}</div>
  //   }
  // },
  // {
  //   field: "subject",
  //   headerName: "Asunto",
  //   flex: 1,
  //   minWidth: 150,
  //   headerAlign: "center",
  //   align: "center",
  //   renderCell: (params) => {
  //     return <div>{params.row.subject}</div>
  //   }
  // },
  {
    field: "caseStatus",
    headerName: "Estado",
    flex: 1,
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{caseStatus[params?.row?.case_status ?? 3].name}</div>
    }
  },
  // {
  //   field: "close_date",
  //   headerName: "Fecha de cierre",
  //   flex: 1,
  //   minWidth: 150,
  //   headerAlign: "center",
  //   align: "center",
  //   valueFormatter: (params) =>
  //     params.value !== null ? moment(params.value).format("DD/MM/YYYY hh:mm") : "",

  // },
  // {
  //   field: "created_at",
  //   headerName: "Fecha registro",
  //   flex: 1,
  //   minWidth: 150,
  //   headerAlign: "center",
  //   align: "center",
  //   valueFormatter: (params) =>
  //     moment(params.value).format("DD/MM/YYYY hh:mm"),

  // },
  // {
  //   field: "updated_at",
  //   headerName: "Fecha modificación",
  //   flex: 1,
  //   minWidth: 150,
  //   headerAlign: "center",
  //   align: "center",
  //   valueFormatter: (params) =>
  //     moment(params.value).format("DD/MM/YYYY hh:mm"),

  // },
];

const doubleAnswerSelect = [
  {
    name: "Si",
    id: 0
  },
  {
    name: "No",
    id: 1
  }
]


function IndexCase() {

  const filtersName = "caseFilters";
  const { role } = useContext(AuthContext);

  const [isEventChanged, setIsEventChanged] = useState(false);
  const [accountName, setaccountName] = useState(getData("account").name)
  const [savedFilters, setSavedFilters] = useState(getData(filtersName));

  const [filtro, setFiltro] = useState(false);
  const [pageSize, setPageSize] = React.useState(20);
  const [cases, setCases] = useState([]);

  const [persons, setPersons] = useState([]);
  const [complexities, setComplexities] = useState([]);
  const [problems, setProblems] = useState([]);
  const [subProblems, setSubProblems] = useState([]);

  useEffect(() => {
    findAllNoFilter(setPersons);
    findAllComplexities(setComplexities);
    findAllProblems(setProblems);
    handleSubProblemChange(savedFilters?.problem);
    setData();
  }, []);

  const handleFiltro = () => {
    setFiltro(!filtro);
  };

  const setData = () => {
    if (savedFilters) findAllFilter(savedFilters, setCases)
    else findAllByUser(setCases);
  }

  const saveFilters = (values) => {
    saveData(filtersName, JSON.stringify(values));
  }

  const columnVisibilityModel = {
    // irAlCaso: canSeeActions.includes(role),
    acciones: canSeeActions.includes(role),
    habilitado: canSeeActions.includes(role),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      person: savedFilters?.person ?? "",
      problem: savedFilters?.problem ?? "",
      subProblem: savedFilters?.subProblem ?? "",
      confidential: savedFilters?.confidential ?? "",
      complexity: savedFilters?.complexity ?? "",
      caseStatus: savedFilters?.caseStatus ?? "",
      subject: savedFilters?.subject ?? "",
      closeDate: savedFilters?.closeDate ?? "",
      rut: savedFilters?.rut ?? "",
      identifier: savedFilters?.identifier ?? "",
      client: savedFilters?.client ?? ""
    },
    onSubmit: (values) => {
      saveFilters(values);
      findAllFilter(values, setCases);
    }
  });

  const handleSubProblemChange = (value) => {
    if (value > 0) {
      findAllSubProblems(value, setSubProblems);
    } else {
      setSubProblems([]);
    }
  }

  const handleExport = () => {
    exportExcel(formik.values);
  }

  useEffect(() => {
    if (!savedFilters) formik.resetForm();
  }, [savedFilters])

  useEffect(() => {
    window.addEventListener('accountStorage', () => {
      if (!isEventChanged) {
        setIsEventChanged(true);
        findAllNoFilter(setPersons);
        findAllByUser(setCases);
        setaccountName(getData("account").name);
      }
    });
  }, []);


  return (
    <>
      <Helmet title={`${fromAdminToSupervisor.includes(role) ? "" : "Mis"} ${title}`} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} xs={12} xl={12} container>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h4" display="inline" > {`${fromAdminToSupervisor.includes(role) ? "" : "Mis"} ${title} -`} </Typography>
                <Typography variant="h4" display="inline" style={{ fontWeight: "bold" }}> {accountName} </Typography>
              </Grid>
              {
                canSeeActions.includes(role) &&
                <Grid item xs={12} md={6} lg={6} xl={6} container justifyContent="flex-end">
                  <Button
                    component={Link}
                    to="/casos/crear"
                    variant="contained"
                    size="large"
                    color="secondary"
                  >
                    Crear
                  </Button>
                </Grid>
              }
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <Chip
                avatar={<Avatar> {filtro ? <Remove /> : <Add />} </Avatar>}
                label={filtro ? "Ocultar filtros" : "Mostrar filtros"}
                onClick={handleFiltro}
                variant="outlined"
                style={{ fontSize: "18px" }}
              />
            </Grid>
            {filtro && (
              <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined">
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6} lg={2}>
                          <TextField
                            fullWidth
                            id="identifier"
                            name="identifier"
                            label="Identificador sucursal"
                            type="text"
                            variant="outlined"
                            value={formik.values.identifier}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                          <TextField
                            fullWidth
                            id="client"
                            name="client"
                            label="Nombre sucursal"
                            type="text"
                            variant="outlined"
                            value={formik.values.client}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                          <TextField
                            fullWidth
                            id="rut"
                            name="rut"
                            label="RUT"
                            type="text"
                            variant="outlined"
                            value={formik.values.rut}
                            onChange={formik.handleChange}
                            onKeyUp={(event) => formik.setFieldValue("rut", formatRut(event.target.value))}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                          <TextField
                            fullWidth
                            id="subject"
                            name="subject"
                            label="Asunto"
                            type="text"
                            variant="outlined"
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={2}>
                          <Autocomplete
                            disablePortal
                            id="problem"
                            name="problem"
                            options={problems}
                            value={{
                              id: formik.values.problem ?? "",
                              description: problems.find((problem) => problem.id === formik.values.problem)?.description ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.description}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Problemática"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              let valueProblem = value !== null ? value.id : formik.initialValues.problem;
                              formik.setFieldValue(
                                "problem",
                                valueProblem
                              );
                              handleSubProblemChange(valueProblem);
                            }}
                          />
                        </Grid>
                        {(subProblems.length > 0 || formik.values.subProblem !== "") && (
                          <Grid item={true} xs={12} md={6} lg={2}>
                            <Autocomplete
                              disablePortal
                              id="subProblem"
                              name="subProblem"
                              options={subProblems}
                              value={{
                                id: formik.values.subProblem ?? "",
                                description: subProblems.find((subProblem) => subProblem.id === formik.values.subProblem)?.description ?? ""
                              }}
                              getOptionSelected={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.description}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Sub problemática"
                                  variant="outlined"
                                />
                              )}
                              onChange={(e, value) => {
                                formik.setFieldValue(
                                  "subProblem",
                                  value !== null
                                    ? value.id
                                    : formik.initialValues.subProblem
                                );
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={6} lg={2}>
                          <Autocomplete
                            disablePortal
                            id="confidential"
                            name="confidential"
                            options={doubleAnswerSelect}
                            value={{
                              id: formik.values.confidential ?? "",
                              name: doubleAnswerSelect.find((das) => das.id === formik.values.confidential)?.name ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Confidencial"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "confidential",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.confidential
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                          <Autocomplete
                            disablePortal
                            id="complexity"
                            name="complexity"
                            options={complexities}
                            value={{
                              id: formik.values.complexity ?? "",
                              description: complexities.find((complexity) => complexity.id === formik.values.complexity)?.description ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.description}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Complejidad"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "complexity",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.complexity
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                          <Autocomplete
                            disablePortal
                            id="caseStatus"
                            name="caseStatus"
                            options={caseStatusSelect}
                            value={{
                              id: formik.values.caseStatus ?? "",
                              name: caseStatusSelect.find((css) => css.id === formik.values.caseStatus)?.name ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Estado"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "caseStatus",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.caseStatus
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                          <TextField
                            fullWidth
                            id="closeDate"
                            name="closeDate"
                            label="Fecha atención"
                            type="date"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={formik.values.closeDate}
                            onChange={formik.handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={10}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                          >
                            Filtrar
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            style={{ marginLeft: "2%" }}
                            onClick={handleExport}

                          >
                            Exportar
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                          <DeleteFilter filtersName={filtersName} setSavedFilters={setSavedFilters} formik={formik} />
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item xs={12} md={12}>
              <div style={{ height: 515, width: "100%" }}>
                <DataGrid
                  rows={cases}
                  columns={columns}
                  columnVisibilityModel={columnVisibilityModel}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  disableColumnMenu
                  disableSelectionOnClick
                  localeText={customLocaleText}
                  componentsProps={componentPropsDataGrid}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default IndexCase;

const caseStatus = {
    0: {
        name: "Abierto",
        id: 0
    },
    1: {
        name: "Cerrado",
        id: 1
    },
    2: {
        name: "Derivado",
        id: 2
    },
    3: {
        name: " ",
        id: 3
    }
}

const caseStatusSelect = [
    {
      name: "Abierto",
      id: 0
    },
    {
      name: "Cerrado",
      id: 1
    },
    {
      name: "Derivado",
      id: 2
    },
  ]

export {caseStatus, caseStatusSelect}
import { Helmet } from "react-helmet";
import Section from "../Section";
import { Avatar, Button, Card, CardContent, Chip, Container, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { Add, Delete, MeetingRoom, Remove } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { DataGrid } from "@mui/x-data-grid";
import { componentPropsDataGrid, customLocaleText } from "../CustomToolbar";
import { findHistoric } from "../../api/user";
import { getData, saveData } from "../../util/localStorageService";
import { Link } from "react-router-dom";
import { DeleteFilter } from "../DeleteFilter";
import Swal from "sweetalert2";

const title = "Historial";
const columns = [
    {
        field: "detail",
        headerName: "Detalle",
        width: 70,
        sortable: false,
        headerAlign: "center",
        align: "left",
        renderCell: (params) => {
            const url = params.row.type === 'Caso' ? '/atenciones/index/' : '/nomina-actividad/index/'
            return (
                <>
                    <IconButton
                        component={Link}
                        to={url + params.row.caseId}
                        variant="contained"
                        size="small"
                        color="secondary"
                        title="Detalle"
                    >
                        <MeetingRoom />
                    </IconButton>
                </>
            );
        },
    },
    {
        field: "caseId",
        headerName: "ID caso",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "date",
        headerName: "Fecha apertura caso",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "attentionDate",
        headerName: "Fecha atención / actividad",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "type",
        headerName: "Tipo",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "rutPerson",
        headerName: "RUT Persona",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "namePerson",
        headerName: "Nombre",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "channel",
        headerName: "Canal",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "subChannel",
        headerName: "SubCanal",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "confidential",
        headerName: "Confidencial",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "complexity",
        headerName: "Complejidad",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "problem",
        headerName: "Problemática",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "subProblem",
        headerName: "Sub-problematica",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "supportTool",
        headerName: "Herramienta de Apoyo",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "supportToolOption",
        headerName: "Sub Herramienta",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "report",
        headerName: "Informe",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "subject",
        headerName: "Asunto",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "description",
        headerName: "Descripción Caso",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "caseStatus",
        headerName: "Estado",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "closeDate",
        headerName: "Fecha Cierre caso",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "activityName",
        headerName: "Nombre Actividad",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "activityType",
        headerName: "Tipo actividad",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "activityDesc",
        headerName: "Descripción actividad",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "user",
        headerName: "Usuario",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "account",
        headerName: "Cuenta",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "zone",
        headerName: "Zona",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "division",
        headerName: "División / Mercado",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "enterprise",
        headerName: "Empresa / Formato",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "branch_office",
        headerName: "Nombre Sucursal",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
    {
        field: "identifier",
        headerName: "Identificador sucursal",
        width: 100,
        sortable: false,
        headerAlign: "center",
        align: "left",
    },
]

function IndexHistoric() {

    const filtersName = "historicFilters";

    const [filtro, setFiltro] = useState(false);
    const [historic, setHistoric] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [savedFilters, setSavedFilters] = useState(getData(filtersName));

    useEffect(() => {
        findHistoric(formik.initialValues, setHistoric);
    }, []);

    const saveFilters = (values) => {
        saveData(filtersName, JSON.stringify(values));
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            rut: savedFilters?.rut ?? "",
        },
        onSubmit: (values) => {
            saveFilters(values);
            findHistoric(values, setHistoric);
        }
    });

    const handleFiltro = () => {
        setFiltro(!filtro);
    };


    useEffect(() => {
        if (!savedFilters) formik.resetForm();
    }, [savedFilters]);

    useEffect(() => {
        if (historic.length < 1 && formik.values.rut) {
            Swal.fire({
                title: `Rut ${formik.values.rut} no posee datos asociados.`,
                icon: "warning"
            });
        }
    }, [historic]);

    return (
        <>
            <Helmet title={title} />
            <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
                <Container maxWidth="xl">
                    <Grid container={true} spacing={4}>
                        <Grid item lg={12} md={12} xs={12} xl={12} container>
                            <Typography variant="h4">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                            <Chip
                                avatar={<Avatar>{filtro ? <Remove /> : <Add />}</Avatar>}
                                label={filtro ? "Ocultar filtros" : "Mostrar filtros"}
                                onClick={handleFiltro}
                                variant="outlined"
                                style={{ fontSize: "18px" }}
                            />
                        </Grid>
                        {filtro && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <form onSubmit={formik.handleSubmit}>
                                            <Grid container={true} spacing={2} alignItems="center">
                                                <Grid item={true} xs={12} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        id="rut"
                                                        name="rut"
                                                        label="RUT"
                                                        type="text"
                                                        variant="outlined"
                                                        value={formik.values.rut}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item={true} xs={12} md={10}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        type="submit"
                                                    >
                                                        Filtrar
                                                    </Button>
                                                </Grid>
                                                <Grid item={true} xs={12} md={2}>
                                                    <DeleteFilter filtersName={filtersName} setSavedFilters={setSavedFilters} formik={formik} />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                        <Grid item={true} xs={12} md={12}>
                            <div style={{ height: 560, width: "100%" }}>
                                <DataGrid
                                    getRowId={(row) => row.caseId}
                                    rows={historic}
                                    columns={columns}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[5, 10, 15, 20]}
                                    disableSelectionOnClick
                                    disableColumnMenu
                                    localeText={customLocaleText}
                                    componentsProps={componentPropsDataGrid}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Section>
        </>
    )
}

export default IndexHistoric;
import { IconButton, Switch } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { changeStatus } from "../../api/label";

const ChangeLabelStatus = ({ status, labelId }) => {

    const [labelStatus, setLabelStatus] = useState(false);
    const [checkStatus, setCheckStatus] = useState(status === 0 ? true : false);
  
    const handleLabelStatus = (checked) => {
      changeStatus(1, labelId, checked ? 0 : 1, setLabelStatus);
    };
  
    useEffect(() => {
      if (labelStatus) {
        setCheckStatus(!checkStatus);
        setLabelStatus(false);
      }
    }, [labelStatus]);
  
    return (
      <Switch
        color="primary"
        checked={checkStatus}
        onClick={(e) => {
            handleLabelStatus(e.target.checked);
        }}
      />
    );
  };

  const DeleteLabel = ({ labelId}) => {
  
    const [labelStatus, setLabelStatus] = useState(false);
  
    const deleteLabel = () => {
      Swal.fire({
        title: "¿Eliminar etiqueta?",
        text: "No será posible revertir esta acción.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#10a4da",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          changeStatus(2, labelId, 0, setLabelStatus);
        }
      });
    };
  
      if (labelStatus) {
        window.location.reload();
      }
  
    return (
      <IconButton
        variant="contained"
        size="small"
        color="default"
        title="Borrar"
        onClick={() => deleteLabel()}
      >
        <Delete></Delete>
      </IconButton>
    );
  };
  
  export { ChangeLabelStatus, DeleteLabel };
  
import {
  Button, Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import {
  AssignmentInd,
  Business,
  ContactMail,
  ContactPhone,
  Person, Work
} from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import { Card, CardContent, Container } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect, useParams } from "react-router-dom";
import { findPersonCases } from "../../api/person";
import { roles } from "../../config/roles";
import AuthContext from "../../context/AuthProvider";
import { componentPropsDataGrid, customLocaleText } from "../CustomToolbar";
import Section from "../Section";

const title = "Ficha personal";
const loadingDots = "...";
const requires = roles.slice(0, 1);

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  chips: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  fontBold: {
    fontWeight: 600,
  },
  lists: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  subTitleList: {
    fontWeight: "bold",
    fontSize: "17px"
  }
}));

const columns = [
  {
    field: "acciones",
    headerName: "Acciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <>
          <IconButton
            component={Link}
            to={"/atenciones/index/" + params.row.id}
            variant="contained"
            size="small"
            color="secondary"
            title="Historial de atenciones"
          >
            <DescriptionIcon />
          </IconButton>
        </>
      );
    },
  },
  {
    field: "rut_person",
    headerName: "RUT persona",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.rut_person}</div>
    }
  },
  {
    field: "personName",
    headerName: "Nombre persona",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{`${params.row.name_person}`}</div>
    }
  },
  {
    field: "problem",
    headerName: "Problemática",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.sub_problem?.problem?.description}</div>
    }
  },
  {
    field: "subProblem",
    headerName: "Sub-problemática",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.sub_problem?.description}</div>
    }
  },
  {
    field: "complexity",
    headerName: "Complejidad",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.complexity?.description}</div>
    }
  },
  {
    field: "confidential",
    headerName: "Confidencial",
    flex: 1,
    minWidth: 50,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.confidential === 0 ? 'Si' : 'No'}</div>
    }
  },
  {
    field: "caseStatus",
    headerName: "Estado",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.case_status === 1 ? 'Cerrado' : 'Abierto'}</div>
    }
  },
  {
    field: "close_date",
    headerName: "Fecha de cierre",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      params.value !== null ? moment(params.value).format("DD/MM/YYYY hh:mm") : "",
  },
  {
    field: "created_at",
    headerName: "Fecha registro",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),

  },
  {
    field: "updated_at",
    headerName: "Fecha modificación",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),

  },
];


function ShowPersonFile() {

  const { idPersona } = useParams();
  const { role } = useContext(AuthContext);

  const columnVisibilityModel = {
    acciones: roles.includes(role),
    habilitado: requires.includes(role),
  };

  const classes = useStyles();
  const [pageSize, setPageSize] = useState(20);
  const [personCases, setPersonCases] = useState([]);

  useEffect(() => {
    findPersonCases(idPersona, setPersonCases);
  }, []);

  if (personCases === null) {
    return <Redirect to="/casos/index" />
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container={true} spacing={4}>
            <Grid item lg={12} md={12} xs={12} xl={12} container>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h4" >
                  Ficha personal
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} container justifyContent="flex-end">
                <Button
                  component={Link}
                  to={`/casos/crear/${personCases.id}`}
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={personCases?.status === 1}
                >
                  Crear caso
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                      <List className={classes.root}>
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <AssignmentInd color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="h6"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  RUT
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  className={[classes.inline, classes.subTitleList].join(' ')}
                                  color="textPrimary"
                                >
                                  {personCases.rut ?? loadingDots}
                                </Typography>
                              </React.Fragment>
                            }

                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <Person color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="h6"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  Nombre
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  className={[classes.inline, classes.subTitleList].join(' ')}
                                  color="textPrimary"
                                >
                                  {`${personCases.name ?? loadingDots}`}
                                </Typography>
                              </React.Fragment>
                            }

                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4} xl={4}>
                      <List className={classes.root}>
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <ContactMail color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="h6"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  Correo electrónico
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  className={[classes.inline, classes.subTitleList].join(' ')}
                                  color="textPrimary"
                                >
                                  {personCases.email ?? loadingDots}
                                </Typography>
                              </React.Fragment>
                            }

                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <ContactPhone color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="h6"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  Teléfono
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  className={[classes.inline, classes.subTitleList].join(' ')}
                                  color="textPrimary"
                                >
                                  {personCases.phone ?? loadingDots}
                                </Typography>
                              </React.Fragment>
                            }

                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                      <List className={classes.root}>

                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <Work color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="h6"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  Sucursal
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  className={[classes.inline, classes.subTitleList].join(' ')}
                                  color="textPrimary"
                                >
                                  {personCases.client?.branch_office ?? loadingDots}
                                </Typography>
                              </React.Fragment>
                            }

                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemIcon>
                            <Business color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="h6"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  Empresa
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  className={[classes.inline, classes.subTitleList].join(' ')}
                                  color="textPrimary"
                                >
                                  {personCases.client?.enterprise ?? loadingDots}

                                </Typography>
                              </React.Fragment>
                            }

                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <div style={{ height: 568, width: "100%" }}>
                <DataGrid
                  rows={personCases.cases ?? []}
                  columns={columns}
                  pageSize={pageSize}
                  columnVisibilityModel={columnVisibilityModel}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                  disableColumnMenu
                  localeText={customLocaleText}
                  componentsProps={componentPropsDataGrid}
                />
              </div>
            </Grid>
            <Grid item={true} xs={6}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                component={Link}
                to="/personas/index"
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default ShowPersonFile;

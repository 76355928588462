import { getData } from "../util/localStorageService";
import apiInstance from "./apiInstance";

const subUrl = `/region`;

const findAll = async (setRegions) => {

    const user = getData("user");
    await apiInstance.get(`${subUrl}/regions`, {
        headers: {
            "x-auth-token": user.authToken
        }
    }).then((response) => {
        setRegions(response.data);
    });

}

const findAllProvinces = async (regionId, setProvinces) => {

    const user = getData("user");
    await apiInstance.get(`${subUrl}/province`, {
        params: {
            region_id: regionId
        },
        headers: {
            "x-auth-token": user.authToken
        }
    }).then((response) => {
        setProvinces(response.data)
    });

}

const findAllCommunes = async (provinceId, setCommunes) => {

    const user = getData("user");
    await apiInstance.get(`${subUrl}/commune`, {
        params: {
            province_id: provinceId
        },
        headers: {
            "x-auth-token": user.authToken
        }
    }).then((response) => {
        setCommunes(response.data);
    });

}

export { findAll, findAllProvinces, findAllCommunes }

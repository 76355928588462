import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

export const DeleteFilter = ({ filtersName, setSavedFilters, formik }) => {
    return (
        <IconButton
            variant="contained"
            size="small"
            color="secondary"
            title="Borrar filtros"
            style={{ float: 'right' }}
            onClick={() => {
                setSavedFilters(undefined);
                window.sessionStorage.removeItem(filtersName);
                formik.resetForm();
            }}
        >
            <Delete />
        </IconButton>
    )
}
import {
  Button,
  CircularProgress,
  Container,
  FormControl, FormHelperText,
  Grid, TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { findById, update } from "../../api/attention";
import { attentionFormValidationSchema } from "../../validations/attentionValidations";
import Section from "../Section";

import { findAll as findAllSupports } from "../../api/support";
import { findAll as findAllOptionSubs } from "../../api/supportToolOptions";
import { findAll as findAllTools } from "../../api/tool";

import { Helmet } from "react-helmet";
import { findAll as findAllChannels } from "../../api/channel";
import { findAll as findAllChannelOptions } from "../../api/channelOptions";

const title = "Editar atención";

const doubleAnswerSelect = [
  {
    name: "Si",
    id: 0
  },
  {
    name: "No",
    id: 1
  }
]


function EditAttention() {

  const { idAtencion, idCaso } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attention, setAttention] = useState([]);
  const [supports, setSupports] = useState([]);
  const [tools, setTools] = useState([]);
  const [optionSubs, setOptionSubs] = useState([]);
  const [channels, setChannels] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);

  useEffect(() => {
    findAllSupports(setSupports);
  }, []);

  useEffect(() => {
    findAllChannels(setChannels);
  }, []);

  useEffect(() => {
    findById(idAtencion, setAttention);
  }, []);

  useEffect(() => {
    let channelId = attention?.channel_option?.channel?.id;
    if (channelId) {
      findAllChannelOptions(channelId, setChannelOptions);
    }
  }, [attention]);

  useEffect(() => {
    let supportId = attention?.support_tools_options_sub?.support_tools_option?.support_tool.id;
    if (supportId) {
      findAllTools(supportId, setTools);
    }
  }, [attention]);

  useEffect(() => {
    let toolId = attention?.support_tools_options_sub?.support_tools_option.id;
    if (toolId) {
      findAllOptionSubs(toolId, setOptionSubs);
    }
  }, [attention]);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      attentionDate: attention?.attention_date ?? "",
      channel: attention?.channel_option?.channel?.id ?? "",
      channelOption: attention?.id_channel_option ?? "",
      support: attention?.support_tools_options_sub?.support_tools_option?.support_tool.id ?? "",
      tool: attention?.support_tools_options_sub?.support_tools_option.id ?? "",
      optionSub: attention?.id_support_tools_options_subs ?? "",
      report: attention?.report ?? "",
      description: attention?.description ?? "",
      case: idCaso

    },
    onSubmit: (values) => {
      setLoading(true);
      update(idAtencion, values, setRedirect, setLoading);
    },
    validationSchema: attentionFormValidationSchema
  });

  const handleChannelOptionsChange = (channelId) => {
    if (channelId > 0) {
      findAllChannelOptions(channelId, setChannelOptions);
    } else {
      setChannelOptions([]);
    }
  }

  const handleToolChange = (supportId) => {
    if (supportId > 0) {
      findAllTools(supportId, setTools);
    } else {
      setTools([]);
    }
  }

  const handleOptionSubsChange = (toolId) => {
    if (toolId > 0) {
      findAllOptionSubs(toolId, setOptionSubs);
    } else {
      setOptionSubs([]);
    }
  }

  if (attention === null) {
    return <Redirect to={`/casos/index`} />
  }

  if (redirect) {
    return <Redirect to={`/atenciones/index/${idCaso}`} />
  }


  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">
                {title}
              </Typography>
              <Typography style={{ fontSize: "15px" }}>
                Los campos marcados con * son obligatorios.
              </Typography>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <form onSubmit={formik.handleSubmit} >
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="attentionDate"
                      name="attentionDate"
                      label="Fecha de atención *"
                      type="date"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={formik.values.attentionDate}
                      onChange={formik.handleChange}
                      error={formik.touched.attentionDate && Boolean(formik.errors.attentionDate)}
                      helperText={formik.touched.attentionDate && formik.errors.attentionDate}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        disablePortal
                        id="channel"
                        name="channel"
                        options={channels}
                        value={{
                          id: formik.values.channel ?? "",
                          description: channels.find((channel) => channel.id === formik.values.channel)?.description ?? "",
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option) => option.description}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Canal *"
                            variant="outlined"
                            error={
                              formik.touched.channel &&
                              Boolean(formik.errors.channel)
                            }
                          />
                        )}
                        onChange={(e, value) => {
                          let channelId = value !== null ? value.id : formik.initialValues.channel;
                          formik.setFieldValue("channel", channelId);
                          formik.setFieldValue("channelOption", "");
                          handleChannelOptionsChange(channelId);
                        }}
                      />
                      {formik.touched.channel && formik.errors.channel && (
                        <FormHelperText style={{ color: "red" }}>
                          {formik.errors.channel}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>


                  <Grid item={true} xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        disablePortal
                        id="channelOption"
                        name="channelOption"
                        options={channelOptions}
                        value={{
                          id: formik.values.channelOption ?? "",
                          description: channelOptions.find((channelOption) => channelOption.id === formik.values.channelOption)?.description ?? "",
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option) => option.description}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Opciones de canal *"
                            variant="outlined"
                            error={
                              formik.touched.channelOption &&
                              Boolean(formik.errors.channelOption)
                            }
                          />
                        )}
                        onChange={(e, value) => {
                          formik.setFieldValue(
                            "channelOption",
                            value !== null
                              ? value.id
                              : formik.initialValues.channelOption
                          );
                        }}
                      />
                      {formik.touched.channelOption && formik.errors.channelOption && (
                        <FormHelperText style={{ color: "red" }}>
                          {formik.errors.channelOption}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        disablePortal
                        id="support"
                        name="support"
                        options={supports}
                        value={{
                          id: formik.values.support ?? "",
                          description: supports.find((support) => support.id === formik.values.support)?.description ?? "",
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option) => option.description}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Herramientas de apoyo *"
                            variant="outlined"
                            error={
                              formik.touched.support &&
                              Boolean(formik.errors.support)
                            }
                          />
                        )}
                        onChange={(e, value) => {
                          let supportId = value !== null ? value.id : formik.initialValues.support;
                          formik.setFieldValue("support", supportId);
                          formik.setFieldValue("tool", "");
                          formik.setFieldValue("optionSub", "");
                          handleToolChange(supportId);
                        }}
                      />
                      {formik.touched.support && formik.errors.support && (
                        <FormHelperText style={{ color: "red" }}>
                          {formik.errors.support}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        disablePortal
                        id="tool"
                        name="tool"
                        value={{
                          id: formik.values.tool ?? "",
                          description: tools.find((tool) => tool.id === formik.values.tool)?.description ?? "",
                        }}
                        options={tools}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option) => option.description}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub Herramientas *"
                            variant="outlined"
                            error={
                              formik.touched.tool &&
                              Boolean(formik.errors.tool)
                            }
                          />
                        )}
                        onChange={(e, value) => {
                          let toolId = value !== null ? value.id : formik.initialValues.tool;
                          formik.setFieldValue("tool", toolId);
                          formik.setFieldValue("optionSub", "");
                          handleOptionSubsChange(toolId);
                        }}
                      />
                      {formik.touched.tool && formik.errors.tool && (
                        <FormHelperText style={{ color: "red" }}>
                          {formik.errors.tool}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {optionSubs.length > 0 && (
                    <Grid item={true} xs={12} md={6}>
                      <FormControl fullWidth variant="outlined">
                        <Autocomplete
                          disablePortal
                          id="optionSub"
                          name="optionSub"
                          value={{
                            id: formik.values.optionSub ?? "",
                            description: optionSubs.find((optionSub) => optionSub.id === formik.values.optionSub)?.description ?? "",
                          }}
                          options={optionSubs}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          getOptionLabel={(option) => option.description}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Opciones *"
                              variant="outlined"
                              error={
                                formik.touched.optionSub &&
                                Boolean(formik.errors.optionSub)
                              }
                            />
                          )}
                          onChange={(e, value) => {
                            formik.setFieldValue("optionSub", value !== null ? value.id : formik.initialValues.optionSub);
                          }}
                        />
                        {formik.touched.optionSub && formik.errors.optionSub && (
                          <FormHelperText style={{ color: "red" }}>
                            {formik.errors.optionSub}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="report"
                      name="report"
                      value={{
                        id: formik.values.report ?? "",
                        name: doubleAnswerSelect.find((answer) => answer.id === formik.values.report)?.name ?? "",
                      }}
                      options={doubleAnswerSelect}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Informe *"
                          variant="outlined"
                          error={
                            formik.touched.report &&
                            Boolean(formik.errors.report)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "report",
                          value !== null
                            ? value.id
                            : formik.initialValues.report
                        );
                      }}
                    />
                    {formik.touched.report && formik.errors.report && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.report}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item={true} xs={12} md={12}>
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      label="Descripción *"
                      type="text"
                      variant="outlined"
                      multiline
                      inputProps={{ maxLength: 1000 }}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                    />
                  </Grid>

                  <Grid item={true} xs={6} container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      component={Link}
                      to={`/atenciones/index/${idCaso}`}
                    >
                      Volver
                    </Button>
                  </Grid>

                  <Grid item={true} xs={6} justifyContent="flex-end" container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={25} color='inherit' /> : 'Guardar'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  )
}

export default EditAttention
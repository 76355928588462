import { IconButton, Switch } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { changeStatus } from "../../api/client";

const ChangeClientStatus = ({ status, clientId }) => {
  const [clientStatus, setClientStatus] = useState(false);
  const [checkStatus, setCheckStatus] = useState(status === 0 ? true : false);

  const handleClientStatus = (checked) => {
    changeStatus(1, clientId, checked ? 0 : 1, setClientStatus);
  };

  useEffect(() => {
    if (clientStatus) {
      setCheckStatus(!checkStatus);
      setClientStatus(false);
    }
  }, [clientStatus]);

  return (
    <Switch
      color="primary"
      checked={checkStatus}
      onClick={(e) => {
        handleClientStatus(e.target.checked);
      }}
    />
  );
};

const DeleteClient = ({ clientId }) => {
  const [clientStatus, setClientStatus] = useState(false);

  const deleteClient = () => {
    Swal.fire({
      title: "¿Eliminar sucursal?",
      text: "No será posible revertir esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#10a4da",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus(2, clientId, 0, setClientStatus);
      }
    });
  };

    if (clientStatus) {
      window.location.reload();
    }

  return (
    <IconButton
      variant="contained"
      size="small"
      color="default"
      title="Borrar"
      onClick={() => deleteClient()}
    >
      <Delete></Delete>
    </IconButton>
  );
};

export { ChangeClientStatus, DeleteClient };


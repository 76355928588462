import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl, FormHelperText,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select, TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect, useParams } from "react-router-dom";
import { findById, update } from "../../api/case";
import { findAll } from "../../api/complexities";
import { findAll as findAllLabels } from "../../api/label";
import { findAllNoFilter } from "../../api/person";
import { findAll as findAllProblems } from '../../api/problem';
import { findAll as findAllSubProblems } from "../../api/subProblem";
import { caseStatusSelect } from "../../config/caseStatus";
import { roles } from "../../config/roles";
import AuthContext from "../../context/AuthProvider";
import { caseFormValidationSchema } from "../../validations/caseValidations";
import Section from "../Section";

const title = "Editar caso";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const doubleAnswerSelect = [
  {
    name: "Si",
    id: 0
  },
  {
    name: "No",
    id: 1
  }
]


function EditCase() {

  const { role } = useContext(AuthContext);

  const { idCaso } = useParams();
  const redirectRoute = `/atenciones/index/${idCaso}`;

  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userCase, setUserCase] = useState([]);
  const [isEventChanged, setIsEventChanged] = useState(false);

  //case
  const [persons, setPersons] = useState([]);
  const [complexities, setComplexities] = useState([]);
  const [problems, setProblems] = useState([]);
  const [subProblems, setSubProblems] = useState([]);
  const [labels, setLabels] = useState([]);

  let labelsIds = [];

  useEffect(() => {
    findById(idCaso, setUserCase);
  }, []);

  useEffect(() => {
    findAllNoFilter(setPersons);
  }, []);

  useEffect(() => {
    findAll(setComplexities);
  }, []);

  useEffect(() => {
    findAllProblems(setProblems);
  }, []);

  useEffect(() => {
    findAllLabels(setLabels, 3);
  }, []);

  useEffect(() => {
    let problemId = userCase?.sub_problem?.problem?.id;
    if (problemId) {
      findAllSubProblems(problemId, setSubProblems);
    }
  }, [userCase]);

  if (userCase !== []) {
    userCase?.caseLabels?.map((label) => labelsIds.push(label.id))
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      person: userCase?.id_person ?? "",
      subject: userCase?.subject ?? "",
      problem: userCase?.sub_problem?.problem?.id ?? "",
      subProblem: userCase?.id_sub_problem ?? "",
      confidential: userCase?.confidential ?? "",
      complexity: userCase?.id_complexity ?? "",
      caseStatus: userCase?.case_status ?? "",
      labels: labelsIds,
    },
    onSubmit: (values) => {
      setLoading(true);
      update(idCaso, values, setRedirect, setLoading);
    },
    validationSchema: caseFormValidationSchema
  });

  const handleSubProblemChange = (value) => {
    if (value > 0) {
      findAllSubProblems(value, setSubProblems);
    } else {
      setSubProblems([]);
    }
  }

  useEffect(() => {
    window.addEventListener('accountStorage', () => {
      if (!isEventChanged) {
        setIsEventChanged(true);
        findAllNoFilter(setPersons);
      }
    });
  }, []);

  if (userCase === null) {
    return <Redirect to="/casos/index" />
  }

  if (roles[2].includes(role) && userCase.caseStatus === 1) {
    return <Redirect to={redirectRoute} />
  }

  if (redirect) {
    return <Redirect to={redirectRoute} />
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>
                Los campos marcados con * son obligatorios.
              </Typography>
              {
                formik.values.caseStatus > 0 &&
                <Typography style={{ fontSize: "15px", color: "red" }}>
                  Si cierras o derivas un caso, no le podrás registrar más atenciones como asistente/a.
                </Typography>
              }
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container={true} spacing={2}>

                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="person"
                      name="person"
                      options={persons}
                      value={{
                        id: formik.values.person ?? "",
                        name: persons.find((person) => person.id === formik.values.person)?.name ?? "",
                        rut: persons.find((person) => person.id === formik.values.person)?.rut ?? "",
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => `${option.name} (${option.rut})`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Persona *"
                          variant="outlined"
                          error={
                            formik.touched.person &&
                            Boolean(formik.errors.person)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "person",
                          value !== null
                            ? value.id
                            : formik.initialValues.person
                        );
                      }}
                    />
                    {formik.touched.person && formik.errors.person && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.person}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="subject"
                      name="subject"
                      label="Asunto *"
                      type="text"
                      variant="outlined"
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      error={formik.touched.subject && Boolean(formik.errors.subject)}
                      helperText={formik.touched.subject && formik.errors.subject}
                    />
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="problem"
                      name="problem"
                      options={problems}
                      value={{
                        id: formik.values.problem ?? "",
                        description: problems.find((problem) => problem.id === formik.values.problem)?.description ?? ""
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.description}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Problemática *"
                          variant="outlined"
                          error={
                            formik.touched.problem &&
                            Boolean(formik.errors.problem)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        let valueProblem = value !== null ? value.id : formik.initialValues.problem;
                        formik.setFieldValue("problem", valueProblem);
                        formik.setFieldValue("subProblem", "");
                        handleSubProblemChange(valueProblem);
                      }}
                    />
                    {formik.touched.problem && formik.errors.problem && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.problem}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="subProblem"
                      name="subProblem"
                      options={subProblems}
                      value={{
                        id: formik.values.subProblem ?? "",
                        description: subProblems.find((subProblem) => subProblem.id === formik.values.subProblem)?.description ?? ""
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.description}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub problemática *"
                          variant="outlined"
                          error={
                            formik.touched.subProblem &&
                            Boolean(formik.errors.subProblem)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "subProblem",
                          value !== null
                            ? value.id
                            : formik.initialValues.subProblem
                        );
                      }}
                    />
                    {formik.touched.subProblem && formik.errors.subProblem && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.subProblem}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="confidential"
                      name="confidential"
                      options={doubleAnswerSelect}
                      value={{
                        id: formik.values.confidential ?? "",
                        name: doubleAnswerSelect.find((answer) => answer.id === formik.values.confidential)?.name ?? ""
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Confidencial *"
                          variant="outlined"
                          error={
                            formik.touched.confidential &&
                            Boolean(formik.errors.confidential)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "confidential",
                          value !== null
                            ? value.id
                            : formik.initialValues.confidential
                        );
                      }}
                    />
                    {formik.touched.confidential && formik.errors.confidential && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.confidential}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="complexity"
                      name="complexity"
                      options={complexities}
                      value={{
                        id: formik.values.complexity ?? "",
                        description: complexities.find((complexity) => complexity.id === formik.values.complexity)?.description ?? ""
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.description}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Complejidad *"
                          variant="outlined"
                          error={
                            formik.touched.complexity &&
                            Boolean(formik.errors.complexity)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "complexity",
                          value !== null
                            ? value.id
                            : formik.initialValues.complexity
                        );
                      }}
                    />
                    {formik.touched.complexity && formik.errors.complexity && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.complexity}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="labls">Etiquetas</InputLabel>
                      <Select
                        labelId="accounts-label"
                        id="labels"
                        name="labels"
                        multiple
                        value={formik.values.labels}
                        onChange={formik.handleChange}
                        input={<Input />}
                        MenuProps={MenuProps}
                        renderValue={(selected) =>
                          labels
                            .filter((name) => selected.includes(name.id))
                            .map((record) => record.name)
                            .join(", ")
                        }
                      >
                        {labels.map((label) => (
                          <MenuItem key={label.id} value={label.id}>
                            <Checkbox
                              checked={
                                formik.values.labels.indexOf(label.id) > -1
                              }
                            />
                            <ListItemText primary={label.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="caseStatus"
                      name="caseStatus"
                      options={caseStatusSelect}
                      value={{
                        id: formik.values.caseStatus ?? "",
                        name: caseStatusSelect.find((answer) => answer.id === formik.values.caseStatus)?.name ?? ""
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Estado *"
                          variant="outlined"
                          error={
                            formik.touched.caseStatus &&
                            Boolean(formik.errors.caseStatus)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "caseStatus",
                          value !== null
                            ? value.id
                            : formik.initialValues.caseStatus
                        );
                      }}
                    />
                    {formik.touched.caseStatus && formik.errors.caseStatus && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.caseStatus}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item={true} xs={12} container>
                    <Grid item={true} xs={6} container>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        component={Link}
                        to={`/atenciones/index/${idCaso}`}
                      >
                        Volver
                      </Button>
                    </Grid>

                    <Grid
                      item={true}
                      xs={6}
                      justifyContent="flex-end"
                      container
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={25} color='inherit' /> : 'Guardar'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default EditCase;

import {
  Button,
  CircularProgress,
  Container, FormHelperText,
  Grid, TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { create } from "../../api/label";
import { findAll } from "../../api/place";
import { labelValidationSchema } from "../../validations/labelValidations";
import Section from "../Section";

const title = "Crear etiqueta";

function CreateLabel() {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      place: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      create(values, setRedirect, setLoading);
    },
    validationSchema: labelValidationSchema,
  });

  useEffect(() => {
    findAll(setPlaces);
  }, []);

  if (redirect) {
    return <Redirect to="/etiquetas/index" />;
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>
                Los campos marcados con * son obligatorios.
              </Typography>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Nombre *"
                      type="text"
                      variant="outlined"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="place"
                      name="place"
                      options={places}
                      getOptionLabel={(option) => option.description}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Lugar *"
                          variant="outlined"
                          error={formik.touched.place && Boolean(formik.errors.place)}
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "place",
                          value !== null ? value.id : formik.initialValues.place
                        );
                      }}
                    />
                    {formik.touched.place && formik.errors.place && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.place}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item lg={12} md={12} xs={12} xl={12} container>
                    <Grid item lg={6} md={6} xs={6} xl={6} container>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        component={Link}
                        to="/etiquetas/index"
                      >
                        Volver
                      </Button>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={6}
                      xl={6}
                      justifyContent="flex-end"
                      container
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={25} color='inherit'/> : 'Crear'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default CreateLabel;

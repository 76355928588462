import axios from "axios";
import { displayClientError, displayServerError } from "../util/swalMessages";

const apiInstance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

apiInstance.interceptors.response.use(
    (response) => response,
    (err) => {

        if (err.response.status === 400 || err.response.status === 404) {
            displayClientError( err.response.data?.message === undefined ? null : `${err.response.data?.message}` );
            return Promise.reject(err);
        }

        if (err.response.status >= 500) {
            displayServerError();
            return Promise.reject();
        }

        if (err.response.status === 401 || err.response.status === 403) {
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("account");
            window.location.reload();
        }
        
    }
);

export default apiInstance;
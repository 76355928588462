import * as yup from "yup"
import { passwordValidationRegEx } from "./regexValidations";
import { emailMessage, matchMessage, requiredMessage, passwordRegExMatchMessage } from "./validationMessages";

const doublePassValidation = yup.object({
    newPass: yup.string().trim().matches(passwordValidationRegEx, passwordRegExMatchMessage).required(requiredMessage),
    repeatNewPass: yup.string().trim().required(requiredMessage).oneOf([yup.ref('newPass'),null], matchMessage)
});

const LoginvalidationSchema = yup.object({
    email: yup.string().trim().email(emailMessage).required(requiredMessage),
    pass: yup.string().trim().required(requiredMessage)
});

const mailValidationSchema = yup.object({
    email: yup.string().trim().email(emailMessage).required(requiredMessage)
});

export{
    doublePassValidation,
    LoginvalidationSchema,
    mailValidationSchema
}
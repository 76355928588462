import { downloadExcelFile } from "../util/downloadFiles";
import { getData } from "../util/localStorageService";
import { displayAlert, displayClientError, displaySuccessAlert } from "../util/swalMessages";
import { upperCaseWords } from "../validations/regexValidations";
import apiInstance from "./apiInstance";

const subUrl = `/activity`;

const findAllActive = async (setClients) => {

  const user = getData("user");
  const account = getData("account");

  await apiInstance.get(`${subUrl}/list`, {
    params:{
      id_account: account.id
    },
    headers:{
      "x-auth-token": user.authToken,
    }
  }).then((response) => {
    console.log(response.data)
    setClients(response.data);
  });

}


const findAll = async (values, setActivities) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.get(
    `${subUrl}/activities`,
    {
      params: {
        name: values?.name ?? "",
        description: values?.description ?? "",
        id_account: values?.account === "" || values === null ? account.id : values?.account,
        status: values?.status ?? "",
        identifier: values?.identifier ?? "",
        type: "0"
      },
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    setActivities(response.data);
  });

}

const create = async (values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/create`,
    {
      name: upperCaseWords(values.name),
      description: values.description,
      id_account: values.account,
      identifier: values.identifier
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then(() => {
    setRedirect(true)
    displaySuccessAlert();
  }).catch(() => {
    setLoading(false);
  });

}

const changeStatus = async (type, id, value, setActivityStatus) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/change_status`,
    new URLSearchParams({
      id,
      value,
      type,
    }),
    {
      headers: {
        "x-auth-token": user.authToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then(() => {
    setActivityStatus(true);
  });


}

const update = async (id, values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/update`,
    {
      id,
      name: upperCaseWords(values.name),
      description: values.description,
      id_account: values.account,
      identifier: values.identifier
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then(() => {
    setRedirect(true);
    displaySuccessAlert();
  }).catch(() => {
    setLoading(false);
  });

}

const findById = async (id, setActivity) => {

  const user = getData("user");
  const request = await apiInstance.post(
    `${subUrl}/activity`,
    {
      id,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    if (!response.data) { setActivity(null); displayClientError(); return }
    setActivity(response.data);
  });

}

const exportExcel = async (values) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.get(`${subUrl}/excel`, {
    responseType: "arraybuffer",
    params: {
      name: values.name,
      description: values.description,
      id_account: values.account === "" ? account.id : values.account,
      identifier: values.identifier,
      status: ""
    },
    headers: {
      "x-auth-token": user.authToken,
    },
  }).then((response) => {
    downloadExcelFile(response, "Reporte_actividades");
  });

}

const masiveUpload = async (file, setRedirect, setLoading) => {

  const filename = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);

  if (filename !== "xlsx") {
    displayAlert("¡Ups!", "Archivo no permitido.", "error");
    return;
  }

  let formData = new FormData();
  formData.append("excel", file);

  const user = getData("user");
  await apiInstance.post(`${subUrl}/read`, formData, {
    headers: {
      "x-auth-token": user.authToken,
      "Content-Type": "multipart/form-data"
    }
  }).then((response) => {
    setLoading(false);
    let uniqueIds = [];
    setRedirect({
      status: true,
      data: response.data.activities.filter(activity => {
        let isDuplicated = uniqueIds.includes(activity.identifier);
        if (!isDuplicated) uniqueIds.push(activity.identifier)
        return Boolean(!isDuplicated);
      }),
      errors: response.data.data
    });
  }).catch(() => {
    setLoading(false);
  });

}

export { findAllActive, findAll, changeStatus, create, update, findById, exportExcel, masiveUpload }


import {
  Button,
  CircularProgress,
  Container, FormHelperText, Grid,
  TextField,
  Typography
} from "@material-ui/core";

import React, { useEffect } from "react";

import { Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment/moment";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect, useParams } from "react-router-dom";
import { findAll as findAllActivities } from "../../api/activity";
import { findAll as findAllActivityChannels } from '../../api/activityChannel';
import { findById, update } from "../../api/activityRecord";
import { activityRecordValidationSchema } from "../../validations/activityRecordValidations";
import Section from "../Section";
import { findAll } from "../../api/activityType";
import { findAllActive as findAllActiveClients } from "../../api/client";

const title = "Editar registro de actividad";

function EditActivityRecord() {

  const { idRegistroActividad } = useParams();

  const [activityRecord, setActivityRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [activityTypes, setactivityTypes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activityChannels, setActivityChannels] = useState([]);
  const [isEventChanged, setIsEventChanged] = useState(false);

  useEffect(() => {
    findById(idRegistroActividad, setActivityRecord);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: moment(activityRecord?.date)?.format("YYYY-MM-DD") ?? "",
      // time: moment(activityRecord?.date)?.format("HH:mm") ?? "",
      activity: activityRecord?.id_activity ?? "",
      activityChannel: activityRecord?.id_activity_channel ?? "",
      client: activityRecord?.id_client ?? null,
      activityType: activityRecord?.id_activity_record_type ?? "",
      description: activityRecord?.description ?? ""
    },
    onSubmit: (values) => {
      setLoading(true);
      update(idRegistroActividad, values, setRedirect, setLoading);
    },
    validationSchema: activityRecordValidationSchema
  });

  useEffect(() => {
    findAllActivities(null, setActivities);
    findAllActivityChannels(setActivityChannels);
    findAllActiveClients(setClients);
    findAll(setactivityTypes);
  }, []);


  useEffect(() => {
    window.addEventListener('accountStorage', () => {
      if (!isEventChanged) {
        setIsEventChanged(true);
        findAllActivities(null, setActivities);
        findAllActiveClients(setClients);

      }
    });
  }, []);

  if (activityRecord === null) {
    return <Redirect to="/registro-actividad/index" />
  }

  if (redirect) {
    return <Redirect to={`/nomina-actividad/index/${idRegistroActividad}`} />

  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>
                Los campos marcados con * son obligatorios.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <form onSubmit={formik.handleSubmit} >
                <Grid container={true} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="activity"
                      name="activity"
                      value={{
                        id: formik.initialValues.activity ?? "",
                        name: activities.find((activity) => activity.id === formik.values.activity)?.name ?? ""
                      }}
                      options={activities}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actividad *"
                          variant="outlined"
                          error={
                            formik.touched.activity && Boolean(formik.errors.activity)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "activity",
                          value !== null
                            ? value.id
                            : formik.initialValues.activity
                        );
                      }}

                    />
                    {formik.touched.activity && formik.errors.activity &&
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.activity}
                      </FormHelperText>
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="activityChannel"
                      name="activityChannel"
                      value={{
                        id: formik.initialValues.activityChannel ?? "",
                        description: activityChannels.find((activityChannel) => activityChannel.id === formik.values.activityChannel)?.description ?? ""
                      }}
                      options={activityChannels}
                      getOptionLabel={(option) => option.description}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Canal de actividad *"
                          variant="outlined"
                          error={
                            formik.touched.activityChannel && Boolean(formik.errors.activityChannel)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "activityChannel",
                          value !== null
                            ? value.id
                            : formik.initialValues.activityChannel
                        );
                      }}

                    />
                    {formik.touched.activityChannel && formik.errors.activityChannel &&
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.activityChannel}
                      </FormHelperText>
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="date"
                      name="date"
                      label="Fecha actividad *"
                      type="date"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={formik.values.date}
                      onChange={formik.handleChange}
                      error={formik.touched.date && Boolean(formik.errors.date)}
                      helperText={formik.touched.date && formik.errors.date}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="time"
                      name="time"
                      label="Hora actividad *"
                      type="time"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={formik.values.time}
                      onChange={formik.handleChange}
                      error={formik.touched.time && Boolean(formik.errors.time)}
                      helperText={formik.touched.time && formik.errors.time}
                    />
                  </Grid> */}
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      id="client"
                      name="client"
                      value={{
                        id: formik.values.client ?? "",
                        identifier: clients.find((client) => client.id === formik.values.client)?.identifier ?? "",
                        branch_office: clients.find((client) => client.id === formik.values.client)?.branch_office ?? "",
                        enterprise: clients.find((client) => client.id === formik.values.client)?.enterprise ?? "",
                      }}
                      options={clients}
                      getOptionLabel={(option) => `${option.enterprise} ${option.branch_office} ( ${option.identifier} )`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sucursal *"
                          variant="outlined"
                          error={
                            formik.touched.client &&
                            Boolean(formik.errors.client)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "client",
                          value !== null
                            ? value.id
                            : formik.initialValues.client
                        );
                      }}
                    />
                    {formik.touched.client && formik.errors.client && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.client}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      id="activityType"
                      name="activityType"
                      value={{
                        id: formik.values.activityType ?? "",
                        description: activityTypes.find((activityType) => activityType.id === formik.values.activityType)?.description ?? "",
                      }}
                      options={activityTypes}
                      getOptionLabel={(option) => `${option.description}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo *"
                          variant="outlined"
                          error={
                            formik.touched.activityType &&
                            Boolean(formik.errors.activityType)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "activityType",
                          value !== null
                            ? value.id
                            : formik.initialValues.activityType
                        );
                      }}
                    />
                    {formik.touched.activityType && formik.errors.activityType && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.activityType}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item={true} xs={12} md={12}>
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      label="Descripción"
                      type="text"
                      variant="outlined"
                      multiline
                      inputProps={{ maxLength: 500 }}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                    />
                  </Grid>

                  <Grid item={true} xs={6} container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      component={Link}
                      to="/registro-actividad/index"
                    >
                      Volver
                    </Button>
                  </Grid>

                  <Grid item={true} xs={6} justifyContent="flex-end" container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={25} color='inherit' /> : 'Guardar'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default EditActivityRecord;

import React, { useState } from "react";
import clsx from "clsx";
import {
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Routes from "./Routes";
import { Router } from "../util/router";

import {
  Home,
  AccountCircle,
  Build,
  PowerSettingsNew,
} from "@material-ui/icons";
import { alpha, Avatar, Collapse, CssBaseline, Menu, MenuItem, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import { logout } from "../api/auth";
import { getData, saveData } from "../util/localStorageService";
import { roles } from "../config/roles";
import { Autocomplete } from "@material-ui/lab";
import { useEffect } from "react";
import { findUserAccounts } from "../api/user";
import { useFormik } from "formik";
import { displaySelectedAccountAlert } from "../util/swalMessages";

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    background: "#fff",
    color: "black",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    color: "white",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(1, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    background: "#00a3d9",
    borderColor: "#00a3d9",
    color: "#fff",
  },
  colorWhite: {
    color: "#fff",
  },
  textDeco: {
    textDecoration: 'underline'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '30ch',
    padding: theme.spacing(1, 0, 1, 0),
  },
  inputInput: {
    // padding: theme.spacing(0, 0, 0, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      '&:focus': {
        width: '25ch',
      },
    },
  },
  lightBlue: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const listItemsDesktop = (classes, role) => {
  return (
    <>
      {
        roles.includes(role) &&
        <>
          <ListItem button component={Link} to="/casos/index">
            <ListItemText inset className={classes.textDeco}>Casos y consultas sociales</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/registro-actividad/index">
            <ListItemText inset className={classes.textDeco}>Act. socioeducativas</ListItemText>
          </ListItem>
          <ListItem button component={Link} to="/historial/index">
            <ListItemText inset className={classes.textDeco}>Historial</ListItemText>
          </ListItem>
        </>
      }
      {
        roles[2].includes(role) &&
        <ListItem button component={Link} to="/personas/index">
          <ListItemText inset className={classes.textDeco}>Personas</ListItemText>
        </ListItem>
      }
      {
        roles.slice(0, 2).includes(role) &&
        <>
          <ListItem button component={Link} to="/reporteria" >
            <ListItemText inset className={classes.textDeco}>Generar reporte</ListItemText>
          </ListItem>
        </>
      }
    </>
  )
}

const listItemMantainers = (classes, role) => {
  return (
    <>
      {
        roles.slice(0, 2).includes(role) && (
          <>
            <ListItem button component={Link} to="/actividades/index">
              <ListItemText inset className={classes.textDeco}>Actividades</ListItemText>
            </ListItem>

            <ListItem button component={Link} to="/personas/index">
              <ListItemText inset className={classes.textDeco}>Personas</ListItemText>
            </ListItem>

            <ListItem button component={Link} to="/clientes/index">
              <ListItemText inset className={classes.textDeco}>Sucursales</ListItemText>
            </ListItem>
          </>
        )

      }

      {
        roles.slice(0, 1).includes(role) && (
          <>
            <ListItem button component={Link} to="/cuentas/index">
              <ListItemText inset className={classes.textDeco}>Cuentas</ListItemText>
            </ListItem>

            <ListItem button component={Link} to="/etiquetas/index">
              <ListItemText inset className={classes.textDeco}>Etiquetas</ListItemText>
            </ListItem>

            <ListItem button component={Link} to="/usuarios/index">
              <ListItemText inset className={classes.textDeco}>Usuarios</ListItemText>
            </ListItem>
          </>
        )
      }
    </>
  );
}



function NavDrawer(props) {

  const { role } = useContext(AuthContext);
  const user = getData("user");

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAnchor = Boolean(anchorEl);

  const [openMatenedorList, setOpenMatenedorList] = useState(false);
  const [openEscritorioList, setOpenEscritorioList] = useState(false);

  const [userAccounts, setUserAccounts] = useState([]);
  const accountStorage = getData("account");

  const { setAuth } = useContext(AuthContext);

  const handleClick = () => {
    setOpenMatenedorList(!openMatenedorList);
    setOpen(true)
  };

  const handleClickEscritorio = () => {
    setOpenEscritorioList(!openEscritorioList);
    setOpen(true)
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenMatenedorList(false);
    setOpenEscritorioList(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      account: accountStorage?.id ?? ""
    }
  })

  useEffect(() => {
    findUserAccounts(setUserAccounts);
  }, []);

  useEffect(() => {
    window.addEventListener('listUpdateStorage', () => {
      findUserAccounts(setUserAccounts);
    })
  }, []);

  if (userAccounts.length > 0 && accountStorage === null) {
    displaySelectedAccountAlert(formik, userAccounts[0]);
  }

  return (
    <div>
      <CssBaseline />
      <AppBar
        elevation={1}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            className={classes.title}
          ></Typography>

          <div id="aaaa" style={{ marginRight: "2%" }} >
            <Autocomplete
              disablePortal
              id="account"
              name="account"
              value={{
                id: formik.values.account ?? "",
                name: userAccounts.find((userAccount) => userAccount.id === formik.values.account)?.name ?? "",
              }}
              getOptionSelected={(option, value) =>
                option.id === value.id
              }
              options={userAccounts}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cuenta *"
                  variant="outlined"
                />
              )}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(e, value) => {
                displaySelectedAccountAlert(formik, value);
              }}
            />
          </div>
          <div>
            <Avatar onClick={handleMenu} style={{ cursor: "pointer" }} className={classes.lightBlue}>
              {user?.name.toString().slice(0, 1)}{user?.lastname.toString().slice(0, 1)}
            </Avatar>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}

              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openAnchor}
              onClose={handleClose}
            >
              <MenuItem button={false} ><ListItemText style={{ textAlign: "center" }}>Usuario: {`${user?.name} ${user?.lastname}`}</ListItemText> </MenuItem>
              <MenuItem button={false}><ListItemText style={{ textAlign: "center" }}>Perfil: {user?.description}</ListItemText> </MenuItem>
              <MenuItem onClick={() => logout(setAuth)}>
                <PowerSettingsNew style={{}} />
                <ListItemText style={{ textAlign: "center" }}> Cerrar sesión</ListItemText>

              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(
            {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            },
            classes.paper
          ),
        }}
      >
        <div className={classes.toolbar}>
          <img src="https://probenefit.cl/wp-content/uploads/2020/07/Logo-probenefit-horizontal-fondo-blanco-300px.png" alt="Logo" style={{ width: "70%" }} />
          <IconButton onClick={handleDrawerClose} className={classes.colorWhite}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>

        <Divider />

        <List color="primary">
          <Router>
            <ListItem button onClick={handleClickEscritorio}>
              <ListItemIcon>
                <Home className={classes.colorWhite} />
              </ListItemIcon>
              <ListItemText primary={<Typography style={{ fontWeight: 600 }}>Escritorio</Typography>}></ListItemText>
            </ListItem>

            <Collapse in={openEscritorioList} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {listItemsDesktop(classes, role)}
              </List>
            </Collapse>

            {
              roles.slice(0, 2).includes(role) &&
              <ListItem button onClick={handleClick}>
                <ListItemIcon>
                  <Build className={classes.colorWhite} />
                </ListItemIcon>
                <ListItemText primary={<Typography style={{ fontWeight: 600 }}>Mantenedores</Typography>} />
              </ListItem>
            }


            <Collapse in={openMatenedorList} timeout="auto" unmountOnExit>

              {listItemMantainers(classes, role)}

            </Collapse>
          </Router>
        </List>
      </Drawer>

    </div>
  );
}
export default NavDrawer;

import * as yup from "yup";
import { requiredMessage } from "./validationMessages";

const activityFormValidationSchema = yup.object({
    identifier: yup.string().trim().required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
    description: yup.string().trim().required(requiredMessage),
    account: yup.number().required(requiredMessage)
});

export {activityFormValidationSchema}
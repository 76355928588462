import { getData } from '../util/localStorageService';
import apiInstance from './apiInstance';

const subUrl = `/channel`;

const findAll = async (setChannels) => {

    const user = getData("user");
    await apiInstance.get(`${subUrl}/channels`, {
        headers: {
            "x-auth-token": user.authToken
        }
    }
    ).then((response) => {
        setChannels(response.data);
    });

}

export { findAll }

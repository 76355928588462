import { downloadExcelFile } from '../util/downloadFiles';
import { getData } from "../util/localStorageService";
import { displayAlert, displayClientError, displaySuccessAlert } from '../util/swalMessages';
import apiInstance from './apiInstance';

const subUrl = `/listroll`;

const findAll = async (idActivityRecord, values, setActivityRecordRoster) => {

  const user = getData("user");
  await apiInstance.get(`${subUrl}/listroll`, {
    params: {
      id_activity_record: idActivityRecord,
      rut: values.rut,
      name: values.name,
      email: values.email,
      branch_office: values.client
    },
    headers: {
      "x-auth-token": user.authToken
    }
  }).then((response) => {
    setActivityRecordRoster(response.data);
  });


}

const associate = async (id, values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(`${subUrl}/associate`, {
    id,
    rut: values.rut,
    status: "0",
    user_id: user.id
  },
    {
      headers: {
        "x-auth-token": user.authToken
      }
    }).then(() => {
      setRedirect({
        status: true,
        // data: request.data.persons
      });
      displaySuccessAlert();
    }).catch(() => {
      setLoading(false);
    });

}

const changeStatus = async (type, id, value, setActivityRosterPerson) => {

  const user = getData("user");
  await apiInstance.put(
    `${subUrl}/change-status`,
    new URLSearchParams({
      id,
      value,
      type,
    }),
    {
      headers: {
        "x-auth-token": user.authToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then(() => {
    setActivityRosterPerson(true);
  });


}

const exportExcel = async (idActivityRecord, values) => {

  const user = getData("user");
  await apiInstance.get(`${subUrl}/excel`, {
    responseType: "arraybuffer",
    params: {
      id_activity_record: idActivityRecord,
      rut: values.rut,
      name: values.name,
      email: values.email,
      branch_office: values.client
    },
    headers: {
      "x-auth-token": user.authToken,
    },
  }).then((response) => {
    downloadExcelFile(response, "Reporte_nomina");
  });

}

const masiveUpload = async (id, file, setRedirect, setLoading) => {

  const filename = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);

  if (filename !== "xlsx") {
    displayAlert("¡Ups!", "Archivo no permitido.", "error");
    return;
  }

  const user = getData("user");
  const account = getData("account");

  let formData = new FormData();
  formData.append("excel", file);
  formData.append("id", id);
  formData.append("user_id", user.id)
  formData.append("id_account", account.id);

  await apiInstance.post(`${subUrl}/read`, formData, {
    headers: {
      "x-auth-token": user.authToken,
      "Content-Type": "multipart/form-data"
    }
  }).then((response) => {
    setLoading(false);
    let uniqueIds = [];
    setRedirect({
      status: true,
      data: response.data.list.filter(person => {
        let isDuplicated = uniqueIds.includes(person.rut);
        if (!isDuplicated) uniqueIds.push(person.rut)
        return Boolean(!isDuplicated);
      }),
      errors: response.data.data
    });
  }).catch(() => {
    setLoading(false);
  });


}

export { findAll, exportExcel, associate, masiveUpload, changeStatus }
import * as yup from "yup";
import { nineDigitsRegEx, rutRegEx, upperCaseWords } from "./regexValidations";
import {
  emailMessage,
  emptyArrayMessage,
  numberValidationMessage,
  requiredMessage,
  roleVisitanteUserValidationMessage,
} from "./validationMessages";

const createValidationSchema = yup.object({
  rut: yup.string().trim().matches(rutRegEx, "RUT inválido").required(requiredMessage),
  name: yup.string().trim().required(requiredMessage),
  lastname: yup.string().trim().required(requiredMessage),
  email: yup.string().trim().email(emailMessage).required(requiredMessage),
  phone: yup.string().trim().matches(nineDigitsRegEx, numberValidationMessage).min(9, "Debe tener 9 dígitos").max(9, "Debe tener 9 dígitos").required(requiredMessage),
  role: yup.number().required(requiredMessage),
  isAdmin: yup.boolean(),
  isVisitante: yup.boolean(),
  accounts: yup.array().when("isAdmin", {is: false, then: yup.array().of(yup.number()).min(1, emptyArrayMessage)})
  .when("isVisitante",{is: true, then: yup.array().of(yup.number()).min(1, emptyArrayMessage).max(1, roleVisitanteUserValidationMessage)}),
});

const userAccountValidationSchema = yup.object({
  accounts: yup.array().of(yup.number()).min(1, emptyArrayMessage),
})



export { createValidationSchema, userAccountValidationSchema };

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import { DataGrid, } from "@mui/x-data-grid";
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Section from "../Section";

import { Add, Delete, Remove } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import moment from "moment/moment";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { exportExcel, findAll, masiveUpload } from "../../api/activity";
import { findUserAccounts } from "../../api/user";
import { getData, saveData } from "../../util/localStorageService";
import { displayLoading } from "../../util/swalMessages";
import { componentPropsDataGrid, customLocaleText } from "../CustomToolbar";
import { ChangeActivityStatus, DeleteActivity } from "./ActivityTableActions";
import { DeleteFilter } from "../DeleteFilter";

const title = "Actividades";

const columns = [
  {
    field: "acciones",
    headerName: "Acciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <>
          <IconButton
            component={Link}
            to={"/actividades/" + params.row.id}
            variant="contained"
            size="small"
            color="secondary"
            title="Editar"
          >
            <EditIcon></EditIcon>
          </IconButton>
          <DeleteActivity activityId={params.row.id} />
        </>
      );
    },
  },
  {
    field: "habilitado",
    headerName: "Habilitado",
    width: 100,
    align: "center",
    headerAlign: "center",
    sortable: false,
    renderCell: (params) => {
      return <ChangeActivityStatus status={params.row.status} activityId={params.row.id} />
    },
  },
  // {
  //   field: "id",
  //   headerName: "#",
  //   width: 100,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "identifier",
    headerName: "Identificador",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "name",
    headerName: "Nombre",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "description",
    headerName: "Descripción",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "accountName",
    headerName: "Cuenta",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.account.name}</div>
    }
  },
  {
    field: "created_at",
    headerName: "Fecha registro",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
  {
    field: "updated_at",
    headerName: "Fecha modificación",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
];

function IndexActivity() {

  const filtersName = "activityFilters";

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [filtro, setFiltro] = useState(false);
  const [pageSize, setPageSize] = React.useState(20);
  const [redirect, setRedirect] = useState({ status: false, data: [], errors: [] });
  const [isEventChanged, setIsEventChanged] = useState(false);
  const [accountName, setaccountName] = useState(getData("account").name);
  const [savedFilters, setSavedFilters] = useState(getData(filtersName));

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    findAll(formik.initialValues, setActivities);
    findUserAccounts(setAccounts);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFiltro = () => {
    setFiltro(!filtro);
  };

  const handleExport = () => {
    exportExcel(formik.values);
  };

  const saveFilters = (values) => {
    saveData(filtersName, JSON.stringify(values));
  }

  useEffect(() => {
    window.addEventListener('accountStorage', () => {
      if (!isEventChanged) {
        setIsEventChanged(true);
        findAll(formik.values, setActivities);
        setaccountName(getData("account").name);
      }
    });
  }, []);

  const uploadExcel = (file) => {
    Swal.fire({
      title: "¿Cargar Excel?",
      text: file.name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#10a4da",
      cancelButtonColor: "#d33",
      confirmButtonText: "Cargar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        masiveUpload(file, setRedirect, setLoading);
      }
    });
  };

  useEffect(() => {
    if (loading) { displayLoading() } else { Swal.close() };
  }, [loading]);

  useEffect(() => {
    if (!savedFilters) formik.resetForm();
  }, [savedFilters]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: savedFilters?.name ?? "",
      description: savedFilters?.description ?? "",
      account: savedFilters?.account ?? "",
      status: savedFilters?.status ?? "",
      identifier: savedFilters?.identifier ?? "",
    },
    onSubmit: (values) => {
      saveFilters(values);
      findAll(values, setActivities)
    }
  });

  if (redirect.status) {
    return (
      <Redirect
        to={{
          pathname: "/actividades/resultado-carga",
          status: { data: redirect },
        }}
      />
    );
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container={true} spacing={4}>
            <Grid item lg={12} md={12} xs={12} xl={12} container>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h4" display="inline" > {title} - </Typography>
                <Typography variant="h4" display="inline" style={{ fontWeight: "bold" }}> {accountName} </Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={12} xl={6} container justifyContent="flex-end" >

                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="secondary"
                  variant="outlined"
                >
                  Opciones
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <input
                      // accept="image/*"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(e) => {
                        uploadExcel(e.target.files[0]);
                        e.target.value = null
                      }}
                    />
                    <label
                      htmlFor="contained-button-file"
                      style={{ cursor: "pointer" }}
                    >
                      Cargar Excel
                    </label>
                  </MenuItem>
                  <MenuItem component={Link} to="/actividades/crear">
                    Crear
                  </MenuItem>
                </Menu>
              </Grid>

            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <Chip
                avatar={<Avatar>{filtro ? <Remove /> : <Add />}</Avatar>}
                label={filtro ? "Ocultar filtros" : "Mostrar filtros"}
                onClick={handleFiltro}
                variant="outlined"
                style={{ fontSize: "18px" }}
              />
            </Grid>
            {filtro && (
              <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined">
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container={true} spacing={2} alignItems="center">
                        <Grid item={true} xs={12} ms={3} md={3}>
                          <TextField
                            fullWidth
                            id="identifier"
                            name="identifier"
                            label="Identificador"
                            type="text"
                            variant="outlined"
                            value={formik.values.identifier}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} ms={3} md={3}>
                          <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Nombre"
                            type="text"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} ms={3} md={3}>
                          <TextField
                            fullWidth
                            id="description"
                            name="description"
                            label="Descripción"
                            type="text"
                            variant="outlined"
                            multiline
                            value={formik.values.description}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3} lg={3}>
                          <Autocomplete
                            disablePortal
                            id="account"
                            name="account"
                            value={{
                              id: formik.values.account,
                              name: accounts.find(account => account.id === formik.values.account)?.name ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            options={accounts}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField {...params} label="Cuenta" variant="outlined" />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "account",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.account
                              );
                            }}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={10}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                          >
                            Filtrar
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            style={{ marginLeft: "2%" }}
                            onClick={handleExport}
                          >
                            Exportar
                          </Button>
                        </Grid>
                        <Grid item={true} xs={12} md={2}>
                          <DeleteFilter filtersName={filtersName} setSavedFilters={setSavedFilters} formik={formik} />
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item={true} xs={12} md={12}>
              <div style={{ height: 560, width: "100%" }}>
                <DataGrid
                  rows={activities}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                  disableColumnMenu
                  localeText={customLocaleText}
                  componentsProps={componentPropsDataGrid}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default IndexActivity;

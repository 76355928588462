import {
  Button,
  CircularProgress,
  Container, FormHelperText,
  Grid, TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { findAll, findAllActive } from "../../api/account";
import { create } from "../../api/activity";
import { activityFormValidationSchema } from "../../validations/activityValidations";
import Section from "../Section";

const title = "Crear actividad";

function CreateActivity() {

  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    findAllActive(setAccounts);
  }, []);

  const formik = useFormik({
    initialValues: {
      identifier: "",
      name: "",
      description: "",
      account: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      create(values, setRedirect, setLoading);
    },
    validationSchema: activityFormValidationSchema,
  });

  if (redirect) {
    return <Redirect to="/actividades/index" />
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>
                Los campos marcados con * son obligatorios.
              </Typography>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="identifier"
                      name="identifier"
                      label="Identificador *"
                      type="text"
                      variant="outlined"
                      value={formik.values.identifier}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.identifier &&
                        Boolean(formik.errors.identifier)
                      }
                      helperText={
                        formik.touched.identifier && formik.errors.identifier
                      }
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Nombre *"
                      type="text"
                      variant="outlined"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.name &&
                        Boolean(formik.errors.name)
                      }
                      helperText={
                        formik.touched.name && formik.errors.name
                      }
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      id="account"
                      name="account"
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.name}
                      options={accounts}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cuenta *"
                          variant="outlined"
                          error={
                            formik.touched.account &&
                            Boolean(formik.errors.account)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "account",
                          value !== null
                            ? value.id
                            : formik.initialValues.account
                        );
                      }}
                    />
                    {formik.touched.account && formik.errors.account && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.account}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item={true} xs={6} md={6}>
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      label="Descripción *"
                      type="text"
                      variant="outlined"
                      multiline
                      inputProps={{ maxLength: 1000 }}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.identifier && formik.errors.identifier
                      }
                    />
                  </Grid>

                  <Grid item={true} xs={6} container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      component={Link}
                      to="/actividades/index"
                    >
                      Volver
                    </Button>
                  </Grid>

                  <Grid item={true} xs={6} justifyContent="flex-end" container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={25} color='inherit' /> : 'Crear'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default CreateActivity;

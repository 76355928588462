import * as CryptoJS from 'crypto-js';

const saveData = (key, data) => {
    sessionStorage.setItem(key, encrypt(data));
}

const getData = (key) => {
    let data = sessionStorage.getItem(key);
    return JSON.parse(decrypt(data));
}

const encrypt = (data) => {
    try {
        return CryptoJS.AES.encrypt(data, process.env.REACT_APP_LOCAL_KEY).toString();
    } catch (error) {
        return null;        
    }
}

const decrypt = (key) => {
    try {
        return CryptoJS.AES.decrypt(key, process.env.REACT_APP_LOCAL_KEY).toString(CryptoJS.enc.Utf8);
    } catch (error) {
        return null;        
    }
}

export {saveData, getData}
import {
  Button,
  CircularProgress,
  Container, FormHelperText, Grid,
  TextField,
  Typography
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";

import { Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { findAll as findAllActivities, findAllActive } from "../../api/activity";
import { findAll as findAllActivityChannels } from '../../api/activityChannel';
import { create } from "../../api/activityRecord";
import { activityRecordValidationSchema } from "../../validations/activityRecordValidations";
import Section from "../Section";
import { findAllActive as findAllActiveClients } from "../../api/client";
import { findAll } from "../../api/activityType";

const title = "Crear registro de actividad";

const useStyles = makeStyles((theme) => ({
  right: {
    marginLeft: "auto",
  },
}));

function CreateActivityRecord() {

  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [activityChannels, setActivityChannels] = useState([]);
  const [clients, setClients] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [idActivityRecord, setIdActivityRecord] = useState(0);
  const [isEventChanged, setIsEventChanged] = useState(false);


  const formik = useFormik({
    initialValues: {
      date: "",
      // time: "",
      activity: "",
      activityChannel: "",
      client: null,
      activityType: "",
      description: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      create(values, setRedirect, setIdActivityRecord, setLoading);
    },
    validationSchema: activityRecordValidationSchema
  });

  useEffect(() => {
    findAllActive(setActivities);
    findAllActivityChannels(setActivityChannels);
    findAllActiveClients(setClients);
    findAll(setActivityTypes);
  }, []);

  useEffect(() => {
    window.addEventListener('accountStorage', () => {
      if (!isEventChanged) {
        setIsEventChanged(true);
        findAllActive(setActivities);
        findAllActiveClients(setClients);
      }
    });
  }, []);


  if (redirect && idActivityRecord > 0) {
    return <Redirect to={`/nomina-actividad/index/${idActivityRecord}`} />
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>Los campos marcados con * son obligatorios.</Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <form onSubmit={formik.handleSubmit} >
                <Grid container={true} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="activity"
                      name="activity"
                      options={activities}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actividad *"
                          variant="outlined"
                          error={
                            formik.touched.activity && Boolean(formik.errors.activity)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "activity",
                          value !== null
                            ? value.id
                            : formik.initialValues.activity
                        );
                      }}

                    />
                    {formik.touched.activity && formik.errors.activity &&
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.activity}
                      </FormHelperText>
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="activityChannel"
                      name="activityChannel"
                      options={activityChannels}
                      getOptionLabel={(option) => option.description}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Canal de actividad *"
                          variant="outlined"
                          error={
                            formik.touched.activityChannel && Boolean(formik.errors.activityChannel)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "activityChannel",
                          value !== null
                            ? value.id
                            : formik.initialValues.activityChannel
                        );
                      }}

                    />
                    {formik.touched.activityChannel && formik.errors.activityChannel &&
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.activityChannel}
                      </FormHelperText>
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="date"
                      name="date"
                      label="Fecha actividad *"
                      type="date"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={formik.values.date}
                      onChange={formik.handleChange}
                      error={formik.touched.date && Boolean(formik.errors.date)}
                      helperText={formik.touched.date && formik.errors.date}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="time"
                      name="time"
                      label="Hora actividad *"
                      type="time"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={formik.values.time}
                      onChange={formik.handleChange}
                      error={formik.touched.time && Boolean(formik.errors.time)}
                      helperText={formik.touched.time && formik.errors.time}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="client"
                      name="client"
                      options={clients}
                      getOptionLabel={(option) => `${option.enterprise} ${option.branch_office} ( ${option.identifier} )`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sucursal *"
                          variant="outlined"
                          error={
                            formik.touched.client && Boolean(formik.errors.client)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "client",
                          value !== null
                            ? value.id
                            : formik.initialValues.client
                        );
                      }}

                    />
                    {formik.touched.client && formik.errors.client &&
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.client}
                      </FormHelperText>
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="activityType"
                      name="activityType"
                      options={activityTypes}
                      getOptionLabel={(option) => `${option.description}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo *"
                          variant="outlined"
                          error={
                            formik.touched.activityType && Boolean(formik.errors.activityType)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "activityType",
                          value !== null
                            ? value.id
                            : formik.initialValues.activityType
                        );
                      }}

                    />
                    {formik.touched.activityType && formik.errors.activityType &&
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.activityType}
                      </FormHelperText>
                    }
                  </Grid>
                  <Grid item={true} xs={12} md={12}>
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      label="Descripción"
                      type="text"
                      variant="outlined"
                      multiline
                      inputProps={{ maxLength: 500 }}
                      value={formik.values.description}
                      onChange={formik.handleChange}

                    />
                  </Grid>

                  <Grid item={true} xs={6} container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      component={Link}
                      to="/registro-actividad/index"
                    >
                      Volver
                    </Button>
                  </Grid>

                  <Grid item={true} xs={6} justifyContent="flex-end" container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={25} color='inherit' /> : 'Crear'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default CreateActivityRecord;

import React, { useEffect, createContext, useState } from "react";
import { getData } from "../util/localStorageService";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

  let user = getData("user");
  const [auth, setAuth] = useState(() => user !== null ? true : false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (getData("user") !== null) {
      setLoading(false);
    }
  }, [loading]);

  return (
    <AuthContext.Provider value={{ auth, setAuth, loading, role: user?.description ?? "" }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import { getData } from '../util/localStorageService';
import { displayClientError, displaySuccessAlert } from '../util/swalMessages';
import apiInstance from './apiInstance';

const subUrl = `/attention`;

const create = async (values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(`${subUrl}/create`, {
    attention_date: values.attentionDate,
    id_channel_option: values.channelOption,
    id_support_tools_options_subs: values.optionSub,
    id_case: Number(values.case),
    report: values.report,
    description: values.description
  }, {
    headers: {
      "x-auth-token": user.authToken
    }
  }).then(() => {
    setRedirect(true);
    displaySuccessAlert();
  }).catch(() => {
    setLoading(false);
  });

}

const findByIdCase = async (id, setAttentions) => {

  const user = getData("user");
  await apiInstance.post(`${subUrl}/attention`, {
    id
  }, {
    headers: {
      "x-auth-token": user.authToken
    }
  }).then((response) => {
    setAttentions(response.data);
  });

}

const findById = async (id, setAttention) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/attentionsbyid`,
    {
      id,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    if (!response.data) { setAttention(null); displayClientError(); return }
    setAttention(response.data);
  });

}

const update = async (id, values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(`${subUrl}/update`, {
    id,
    attention_date: values.attentionDate,
    id_channel_option: values.channelOption,
    id_support_tools_options_subs: values.optionSub,
    id_case: Number(values.case),
    report: values.report,
    description: values.description
  }, {
    headers: {
      "x-auth-token": user.authToken
    }
  }).then(() => {
    setRedirect(true);
    displaySuccessAlert();
  }).catch(() => {
    setLoading(false);
  });

}

const changeStatus = async (type, id, value, setCaseStatus) => {
  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/change_status`,
    new URLSearchParams({
      id,
      type,
      value,
    }),
    {
      headers: {
        "x-auth-token": user.authToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then(() => {
    setCaseStatus(true);
  })

}



export { create, findByIdCase, update, findById, changeStatus }


import { IconButton, Switch } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { changeStatus } from "../../api/activityRecord";

const ChangeActivityRecordStatus = ({ status, activityRecordId }) => {
  const [activityRecordStatus, setActivityRecordStatus] = useState(false);
  const [checkStatus, setCheckStatus] = useState(status === 0 ? true : false);

  const handleActivityRecordStatus = (checked) => {
    changeStatus(1, activityRecordId, checked ? 0 : 1, setActivityRecordStatus);
  };

  useEffect(() => {
    if (activityRecordStatus) {
      setCheckStatus(!checkStatus);
      setActivityRecordStatus(false);
    }
  }, [activityRecordStatus]);

  return (
    <Switch
      color="primary"
      checked={checkStatus}
      onClick={(e) => {
        handleActivityRecordStatus(e.target.checked);
      }}
    />
  );
};

const DeleteActivityRecord = ({ activityRecordId }) => {
  const [activityRecordStatus, setActivityRecordStatus] = useState(false);

  const deleteActivityRecord = () => {
    Swal.fire({
      title: "¿Eliminar registro?",
      text: "No será posible revertir esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#10a4da",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus(2, activityRecordId, 0, setActivityRecordStatus);
      }
    });
  };

    if (activityRecordStatus) {
      window.location.reload();
    }

  return (
    <IconButton
      variant="contained"
      size="small"
      color="default"
      title="Borrar"
      onClick={() => deleteActivityRecord()}
    >
      <Delete></Delete>
    </IconButton>
  );
};

export { ChangeActivityRecordStatus, DeleteActivityRecord };


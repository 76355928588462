import { getData } from "../util/localStorageService";
import apiInstance from "./apiInstance";

const subUrl = '/activity_types';

const findAll = async (setActivityTypes) => {

    const user = getData("user");
  
    await apiInstance.get(`${subUrl}/types`, {
      headers:{
        "x-auth-token": user.authToken,
      }
    }).then((response) => {
        setActivityTypes(response.data);
    });  

}

export {findAll}
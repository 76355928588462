import moment from "moment/moment";

const downloadExcelFile = (request, name) => {
    const type = request.headers['content-type'];
    const blob = new Blob([request.data], { type: type, encoding: 'UTF-8' });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = `${name}-${moment().format('DD-MM-YYYY')}.xlsx`;
    link.click();
}

export {downloadExcelFile}
import { getData } from "../util/localStorageService";
import apiInstance from "./apiInstance";

const subUrl = `/problem`;

const findAll = async (setProblems) => {

    const user = getData("user");
    const account = getData("account");
    await apiInstance.get(`${subUrl}/problems`, {
        params: {
            id_account: account.id
        },
        headers: {
            "x-auth-token": user.authToken
        }
    }).then((response) => {
        setProblems(response.data);
    });

}

export { findAll }

import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { findAll, findAllActive } from "../../api/account";
import { findAllRoles, save } from "../../api/user";
import { isRole, roles as globalRoles } from "../../config/roles";
import { formatRut } from "../../validations/regexValidations";
import { createValidationSchema } from "../../validations/userValidations";
import Section from "../Section";

const title = "Crear usuario";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function CreateUser() {
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [roles, setRoles] = useState([]);

  const formik = useFormik({
    initialValues: {
      rut: "",
      name: "",
      lastname: "",
      email: "",
      phone: "",
      note: "",
      role: "",
      isAdmin: false,
      isVisitante: false,
      accounts: [],
    },
    onSubmit: (values) => {
      setLoading(true);
      save(values, setRedirect, setLoading);
    },
    validationSchema: createValidationSchema,
  });

  useEffect(() => {
    findAllRoles(setRoles);
  }, []);

  useEffect(() => {
    findAllActive(setAccounts);
  }, []);

  if (redirect) {
    return <Redirect to="/usuarios/index" />
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>
                Los campos marcados con * son obligatorios.
              </Typography>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container={true} spacing={2}>
                  {/* <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="foto"
                      label="Foto"
                      type="file"
                      variant="outlined"
                      InputLabelProps={{shrink: true}}
                    />
                  </Grid> */}
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="rut"
                      name="rut"
                      label="RUT *"
                      type="text"
                      variant="outlined"
                      value={formik.values.rut}
                      onChange={formik.handleChange}
                      error={formik.touched.rut && Boolean(formik.errors.rut)}
                      helperText={formik.touched.rut && formik.errors.rut}
                      onKeyUp={(event) => formik.setFieldValue("rut", formatRut(event.target.value))}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Nombre *"
                      type="text"
                      variant="outlined"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="lastname"
                      name="lastname"
                      label="Apellido *"
                      type="text"
                      variant="outlined"
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastname &&
                        Boolean(formik.errors.lastname)
                      }
                      helperText={
                        formik.touched.lastname && formik.errors.lastname
                      }
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Correo electrónico *"
                      type="text"
                      variant="outlined"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="phone"
                      name="phone"
                      label="Teléfono *"
                      type="text"
                      variant="outlined"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="note"
                      name="note"
                      label="Nota"
                      type="text"
                      variant="outlined"
                      value={formik.values.note}
                      onChange={formik.handleChange}
                      error={formik.touched.note && Boolean(formik.errors.note)}
                      helperText={formik.touched.note && formik.errors.note}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      id="role"
                      name="role"
                      options={roles}
                      getOptionLabel={(option) => option.description}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rol *"
                          variant="outlined"
                          error={
                            formik.touched.role && Boolean(formik.errors.role)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "role",
                          value !== null ? value.id : formik.initialValues.role
                        );
                        formik.setFieldValue("isAdmin", isRole(value.description, 0, 1));
                        formik.setFieldValue("isVisitante", globalRoles.slice(-1).includes(value.description));

                      }}
                    />
                    {formik.touched.role && formik.errors.role && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.role}
                      </FormHelperText>
                    )}
                  </Grid>
                  {/* !isAdmin && */}
                  {!formik.values.isAdmin && <Grid item={true} xs={12} md={6} lg={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="accnt">Cuentas *</InputLabel>
                      <Select
                        labelId="cuentas-label"
                        id="accounts"
                        name="accounts"
                        multiple
                        value={formik.values.accounts}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.accounts &&
                          Boolean(formik.errors.accounts)
                        }
                        input={<Input />}
                        MenuProps={MenuProps}
                        renderValue={(selected) =>
                          accounts
                            .filter((name) => selected.includes(name.id))
                            .map((record) => record.name)
                            .join(", ")
                        }
                      >
                        {accounts.map((account) => (
                          <MenuItem key={account.id} value={account.id}>
                            <Checkbox
                              checked={
                                formik.values.accounts.indexOf(account.id) > -1
                              }
                            />
                            <ListItemText primary={account.name} />
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.accounts && formik.errors.accounts && (
                        <FormHelperText style={{ color: "red" }}>
                          {formik.errors.accounts}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>}

                  <Grid item={true} xs={12} md={12} lg={12} xl={12} container>
                    <Grid item={true} xs={6} container>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        component={Link}
                        to="/usuarios/index"
                      >
                        Volver
                      </Button>
                    </Grid>

                    <Grid item={true} xs={6} justifyContent="flex-end" container>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={25} color='inherit' /> : 'Crear'}
                      </Button>
                    </Grid>
                  </Grid>

                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default CreateUser;

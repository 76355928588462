const roles = [
    'Administrador',
    'Supervisor',
    'Asistente social',
    'Visitante'
];

const loginRedirectByRole = {
    'Administrador': '/actividades/index',
    'Supervisor': '/actividades/index',
    'Asistente social': '/casos/index',
    'Visitante': '/casos/index',
    '': "/login"
}

const isRole = (roleName, start, end) => {
    return roles.slice(start, end).includes(roleName);
}

export {roles, isRole, loginRedirectByRole};
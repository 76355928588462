import React from "react";

import { Switch, Route, Router } from "../util/router";
import NotFoundPage from "../pages/404";

import IndexActivityRecord from "./activityrecord/IndexActivityRecord";
import CreateActivityRecord from "./activityrecord/CreateActivityRecord";
import EditActivityRecord from "./activityrecord/EditActivityRecord";

import IndexActivityRecordRoster from "./activityRecordRoster/IndexActivityRecordRoster";
import IndexActivityRecordRosterExcel from "./activityRecordRoster/IndexActivityRecordRosterExcel";
import CreateActivityRecordRoster from "./activityRecordRoster/CreateActivityRecordRoster";

import CreateCase from "./case/CreateCase";
import EditCase from "./case/EditCase";

import CreateAttention from "./attention/CreateAttention";
import EditAttention from "./attention/EditAttention";
import IndexAttention from "./attention/IndexAttention";
import IndexCase from "./case/IndexCase";

import ShowPersonFile from "./person/ShowPersonFile";

import IndexActivity from "./activity/IndexActivity";
import CreateActivity from "./activity/CreateActivity";
import EditActivity from "./activity/EditActivity";

import IndexAccount from "./account/IndexAccount";
import CreateAccount from "./account/CreateAccount";
import EditAccount from "./account/EditAccount";

import IndexLabel from "./label/IndexLabel";
import CreateLabel from "./label/CreateLabel";
import EditLabel from "./label/EditLabel";

import IndexPerson from "./person/IndexPerson";
import CreatePerson from "./person/CreatePerson";
import EditPerson from "./person/EditPerson";

import IndexClient from "./client/IndexClient";
import CreateClient from "./client/CreateClient";
import EditClient from "./client/EditClient";

import IndexUser from "./user/IndexUser";
import CreateUser from "./user/CreateUser";
import EditUser from "./user/EditUser";

import Reporting from "./reporting/Reporting";

import Login from "./auth/Login";
import AuthContext, { AuthProvider } from "../context/AuthProvider";
import { useContext } from "react";
import PrivateRoute from "./auth/PrivateRoute";
import ResetPass from "./auth/ResetPass";
import RequestResetPass from "./auth/RecoverPass";
import ActivateAccount from "./auth/ActivateAccount";
import { Redirect, useLocation } from "react-router-dom";
import PageLoader from "./PageLoader";
import IndexClientRosterExcel from "./client/IndexClientRosterExcel";
import IndexPersonRosterExcel from "./person/IndexPersonRosterExcel";
import IndexActivitiesExcel from "./activity/IndexActivitiesExcel";
import { loginRedirectByRole, roles } from "../config/roles";
import IndexHistoric from "./historic/IndexHistoric";

const onlyAdmin = roles.slice(0, 1);
const fromAdminToAsistente = roles.slice(0, 3);
const fromAdminToSupervisor = roles.slice(0, 2);

const Routes = () => {

  const { auth, role } = useContext(AuthContext);

  return (
    <Router>
      <>
        <Switch>

          <Route exact path="/" render={() => { return <Redirect to={loginRedirectByRole[role]} /> }} />

          <Route exact path="/login" render={() => { return (auth) ? <Redirect to={loginRedirectByRole[role]} /> : <Login /> }} />
          <Route exact path="/recover" render={() => { return (auth) ? <Redirect to={loginRedirectByRole[role]} /> : <RequestResetPass /> }} />
          <Route exact path="/activate" render={() => { return (auth) ? <Redirect to={loginRedirectByRole[role]} /> : <ActivateAccount /> }} />
          <Route exact path="/reset-password" render={() => { return (auth) ? <Redirect to={loginRedirectByRole[role]} /> : <ResetPass /> }} />

          <PrivateRoute path="/casos/index" requires={roles} exact component={IndexCase} />
          <PrivateRoute path="/casos/crear/:idPersona?" requires={fromAdminToAsistente} exact component={CreateCase} />
          <PrivateRoute path="/casos/:idCaso" requires={fromAdminToAsistente} exact component={EditCase} />

          <PrivateRoute path="/atenciones/index/:idCaso" requires={roles} exact component={IndexAttention} />
          <PrivateRoute path="/atenciones/crear/:idCaso" requires={fromAdminToAsistente} exact component={CreateAttention} />
          <PrivateRoute path="/atenciones/:idCaso/:idAtencion" requires={fromAdminToAsistente} exact component={EditAttention} />

          <PrivateRoute path="/registro-actividad/index" requires={roles} exact component={IndexActivityRecord} />
          <PrivateRoute path="/registro-actividad/crear" requires={fromAdminToAsistente} exact component={CreateActivityRecord} />
          <PrivateRoute path="/registro-actividad/:idRegistroActividad" requires={fromAdminToAsistente} exact component={EditActivityRecord} />

          <PrivateRoute path="/nomina-actividad/index/:idRegistroActividad" requires={roles} exact component={IndexActivityRecordRoster} />
          <Route path="/nomina-actividad/resultado-carga/:idRegistroActividad" render={(props) => {
            if (!auth) return <Redirect to={"/login"} />
            return fromAdminToAsistente.includes(role) ? <IndexActivityRecordRosterExcel {...props} /> : <Redirect to={"/casos/index"} />
          }} />
          <PrivateRoute path="/nomina-actividad/crear/:idRegistroActividad" requires={fromAdminToAsistente} exact component={CreateActivityRecordRoster} />


          <PrivateRoute path="/actividades/index" requires={fromAdminToSupervisor} exact component={IndexActivity} />
          <PrivateRoute path="/actividades/crear" requires={fromAdminToSupervisor} exact component={CreateActivity} />
          <Route exact path="/actividades/resultado-carga" render={(props) => {
            if (!auth) return <Redirect to={"/login"} />
            return fromAdminToSupervisor.includes(role) ? <IndexActivitiesExcel {...props} /> : <Redirect to={"/casos/index"} />
          }} />

          <PrivateRoute path="/actividades/:idActividad" requires={fromAdminToSupervisor} exact component={EditActivity} />

          <PrivateRoute path="/cuentas/index" requires={onlyAdmin} exact component={IndexAccount} />
          <PrivateRoute path="/cuentas/crear" requires={onlyAdmin} exact component={CreateAccount} />
          <PrivateRoute path="/cuentas/:idCuenta" requires={onlyAdmin} exact component={EditAccount} />

          <PrivateRoute path="/etiquetas/index" requires={onlyAdmin} exact component={IndexLabel} />
          <PrivateRoute path="/etiquetas/crear" requires={onlyAdmin} exact component={CreateLabel} />
          <PrivateRoute path="/etiquetas/:idEtiqueta" requires={onlyAdmin} exact component={EditLabel} />

          <PrivateRoute path="/personas/index" requires={fromAdminToAsistente} exact component={IndexPerson} />
          <PrivateRoute path="/personas/crear" requires={fromAdminToSupervisor} exact component={CreatePerson} />
          <Route exact path="/personas/resultado-carga"
            render={(props) => {
              if (!auth) return <Redirect to={"/login"} />
              return fromAdminToSupervisor.includes(role) ? <IndexPersonRosterExcel {...props} /> : <Redirect to={"/casos/index"} />
            }} />

          <PrivateRoute path="/personas/:idPersona" requires={onlyAdmin} exact component={EditPerson} />
          <PrivateRoute path="/personas/ficha/:idPersona" requires={fromAdminToAsistente} exact component={ShowPersonFile} />

          <PrivateRoute exact path="/clientes/index" requires={fromAdminToSupervisor} component={IndexClient} />
          <PrivateRoute exact path="/clientes/crear" requires={fromAdminToSupervisor} component={CreateClient} />
          <Route exact path="/clientes/resultado-carga"
            render={(props) => {
              if (!auth) return <Redirect to={"/login"} />
              return fromAdminToSupervisor.includes(role) ? <IndexClientRosterExcel {...props} /> : <Redirect to={"/casos/index"} />
            }} />
          <PrivateRoute exact path="/clientes/:idCliente" requires={fromAdminToSupervisor} component={EditClient} />

          <PrivateRoute path="/usuarios/index" requires={onlyAdmin} exact component={IndexUser} />
          <PrivateRoute path="/usuarios/crear" requires={onlyAdmin} exact component={CreateUser} />
          <PrivateRoute path="/usuarios/:idUsuario" requires={onlyAdmin} exact component={EditUser} />

          <PrivateRoute path="/historial/index" requires={fromAdminToAsistente} exact component={IndexHistoric} />

          <PrivateRoute path="/reporteria" requires={fromAdminToAsistente} exact component={Reporting} />

          <Route component={NotFoundPage} />

        </Switch>
      </>
    </Router>
  );
};

export default Routes;

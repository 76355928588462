import { getData, saveData } from "../util/localStorageService";
import { displayAlert } from "../util/swalMessages";
import apiInstance from "./apiInstance";
import { findUserAccounts } from "./user";

const subUrl = `/auth`;

const login = async (values, setErrorMsj, setUserAccounts) => {

  await apiInstance.post(`${subUrl}/signin/`, {
    email: values.email,
    password: values.pass,
  }).then((response) => {

    if (response.data.success === 0) { setErrorMsj(true); return; }

    const { id, active, auth_token, name, lastname } = response.data.data;
    const typeId = response.data.data.type.id;
    const description = response.data.data.type.description;

    const user = {
      id,
      active,
      authToken: auth_token,
      typeId,
      description,
      name,
      lastname
    };

    saveData("user", JSON.stringify(user));
    findUserAccounts(setUserAccounts);

  }).catch(() => {
    setErrorMsj(true);
  });

}

const logout = async (setAuth) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/logout/`,
    { id: user.id },
    { headers: { "x-auth-token": user.authToken } }
  ).then(() => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("account");
    setAuth(false);
  });

}

const recoverPass = async (values, setRedirect) => {

  await apiInstance.post(
    `${subUrl}/recover/`,
    {
      email: values.email,
    }
  ).then((response) => {

    if (response.data.success === 0) {
      displayAlert("¡Ups!", "El email ingresado no existe.", "error");
      return;
    }

    displayAlert(
      "Cuenta validada",
      "Te enviamos un correo electrónico con los pasos a seguir para que realices el cambio de tu contraseña.",
      "success",
      setRedirect
    );

  });



}

const resetPass = async (token, values, setRedirect, type) => {

  await apiInstance.post(
    `${subUrl}/reset_password`,
    {
      newPassword: values.newPass,
      token,
      type
    }
  ).then((response) => {
    // if(response.data.success === 0) {displayClientError(); return; }
    displayAlert(
      "Contraseña actualizada",
      "Tu contraseña se ha actualizado correctamente, ya puedes utilizarla para acceder al sistema.",
      "success",
      setRedirect
    );
  });

}

const activateAccount = async (token, values, setRedirect) => {

    await apiInstance.post(
      `${subUrl}/activate`,
      {
        password: values.newPass,
        token: token,
      }
    ).then((response) => {
      // if(response.data.success === 0) {displayClientError(); return; }
      displayAlert(
        "Cuenta activada",
        "Tu contraseña se ha actualizado correctamente, ya puedes utilizarla para acceder al sistema.",
        "success",
        setRedirect
      );
    })


}

export { login, logout, recoverPass, resetPass, activateAccount }


import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl, FormHelperText,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select, TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { findAll as findAllClients, findAllActive } from "../../api/client";
import { findAll } from "../../api/label";
import { create } from "../../api/person";
import { personFormValidationSchema } from "../../validations/personValidations";
import { formatRut } from "../../validations/regexValidations";
import Section from "../Section";

const title = "Crear persona";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CreatePerson() {
  const [labels, setLabels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [clients, setClients] = useState([]);
  const [isEventChanged, setIsEventChanged] = useState(false);

  const formik = useFormik({
    initialValues: {
      rut: "",
      name: "",
      email: "",
      phone: "",
      client: "",
      labels: [],
    },
    onSubmit: (values) => {
      setLoading(true);
      create(values, setRedirect, setLoading);
    },
    validationSchema: personFormValidationSchema,
  });

  useEffect(() => {
    findAll(setLabels, 2);
  }, []);

  useEffect(() => {
    findAllActive(setClients);
  }, []);

  useEffect(() => {
    window.addEventListener('accountStorage', () => {
      if (!isEventChanged) {
        setIsEventChanged(true);
        findAllActive(setClients);
      }
    });
  }, []);

  if (redirect) {
    return <Redirect to="/personas/index" />
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>
                Los campos marcados con * son obligatorios.
              </Typography>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="rut"
                      name="rut"
                      label="RUT *"
                      type="text"
                      variant="outlined"
                      value={formik.values.rut}
                      onChange={formik.handleChange}
                      error={formik.touched.rut && Boolean(formik.errors.rut)}
                      helperText={formik.touched.rut && formik.errors.rut}
                      onKeyUp={(event) => formik.setFieldValue("rut", formatRut(event.target.value))}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Nombre *"
                      type="text"
                      variant="outlined"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Correo electrónico"
                      type="text"
                      variant="outlined"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="phone"
                      name="phone"
                      label="Teléfono"
                      type="text"
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      // placeholder="9 1111 1111"
                      variant="outlined"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    // error={formik.touched.phone && Boolean(formik.errors.phone)}
                    // helperText={formik.touched.phone && formik.errors.phone}
                    // onKeyUp={(e) => formik.setFieldValue("phone", formatPhone(e.target.value))}

                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="note"
                      name="note"
                      label="Nota"
                      type="text"
                      variant="outlined"
                      value={formik.values.note}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      id="client"
                      name="client"
                      options={clients}
                      getOptionLabel={(option) => `${option.enterprise} ${option.branch_office} ( ${option.account.name} )`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sucursal *"
                          variant="outlined"
                          error={formik.touched.client && Boolean(formik.errors.client)}
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "client",
                          value !== null
                            ? value.id
                            : formik.initialValues.client
                        );
                      }}
                    />
                    {formik.touched.client && formik.errors.client && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.client}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="labls">Etiquetas</InputLabel>
                      <Select
                        labelId="accounts-label"
                        id="labels"
                        name="labels"
                        multiple
                        value={formik.values.labels}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.labels && Boolean(formik.errors.labels)
                        }
                        input={<Input />}
                        MenuProps={MenuProps}
                        renderValue={(selected) =>
                          labels
                            .filter((name) => selected.includes(name.id))
                            .map((record) => record.name)
                            .join(", ")
                        }
                      >
                        {labels.map((label) => (
                          <MenuItem key={label.id} value={label.id}>
                            <Checkbox
                              checked={
                                formik.values.labels.indexOf(label.id) > -1
                              }
                            />
                            <ListItemText primary={label.name} />
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </Grid>

                  <Grid item lg={12} md={12} xs={12} xl={12} container>
                    <Grid item lg={6} md={6} xs={6} xl={6} container>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        component={Link}
                        to="/personas/index"
                      >
                        Volver
                      </Button>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={6}
                      xl={6}
                      justifyContent="flex-end"
                      container
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={25} color='inherit' /> : 'Crear'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default CreatePerson;

import { getData } from "../util/localStorageService";
import apiInstance from "./apiInstance";

const subUrl = `/place`;

const findAll = async (setPlaces) => {

    const user = getData("user");
    await apiInstance.get(`${subUrl}/places`, {
        headers: {
            "x-auth-token": user.authToken
        }
    }).then((response) => {
        setPlaces(response.data);
    });

}

export { findAll }

import * as yup from "yup";
import { requiredMessage } from "./validationMessages";

const clientFormValidationSchema = yup.object({
    identifier: yup.string().trim().required(requiredMessage),
    isCreate: yup.boolean(),
    account: yup.number().when("isCreate", {is: false, then: yup.number().required(requiredMessage)}),
    branchOffice: yup.string().trim().required(requiredMessage),
    commune: yup.number().required(requiredMessage),
    division: yup.string().trim().required(requiredMessage),
    enterprise: yup.string().trim().required(requiredMessage),
    zone: yup.number().required(requiredMessage),
    region: yup.number().required(requiredMessage),
    province: yup.number().required(requiredMessage),
    // labels: yup.array().required(requiredMessage)

})

export {clientFormValidationSchema}
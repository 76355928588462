import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  AssignmentInd,
  Business,
  ContactMail,
  ContactPhone,
  Lock,
  Person,
  Work
} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { findByIdCase } from "../../api/attention";
import { findById } from "../../api/case";
import { caseStatus } from "../../config/caseStatus";
import { roles } from "../../config/roles";
import AuthContext from "../../context/AuthProvider";
import Section from "../Section";
import { ChangeAttentionStatus, DeleteAttention } from "./AttentionTableActions";

const title = "Administrar caso";
const loadingDots = "...";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  chips: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  fontBold: {
    fontWeight: 600,
  },
  lists: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  weight: {
    fontWeight: 500,
  },

  subTitleList: {
    fontWeight: "bold",
    fontSize: "17px"
  }

}));

function IndexAttention() {

  let history = useHistory();

  let numAttention = 0;
  const { idCaso } = useParams();
  const { role } = useContext(AuthContext);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [attentions, setAttentions] = useState([]);
  const [userCase, setUserCase] = useState([]);

  useEffect(() => {
    findById(idCaso, setUserCase);
  }, []);

  useEffect(() => {
    findByIdCase(idCaso, setAttentions);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  if (userCase === null) {
    return <Redirect to="/casos/index" />
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container spacing={4} >
            <Grid item lg={12} md={12} xs={12} xl={12} container >

              <Grid item lg={12} md={12} xs={12} xl={12} container style={{ marginBottom: "1%" }}>
                <Grid item xs={12} md={6} lg={6}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={history.goBack}
                  >
                    Volver
                  </Button>
                </Grid>
                {
                  (roles.slice(0, 2).includes(role) || (roles[2].includes(role) && userCase.case_status === 0)) &&
                  <Grid item xs={6} md={6} lg={6} xl={6} container justifyContent="flex-end">
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      color="secondary"
                      variant="outlined"
                    >
                      Opciones
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >

                      <MenuItem onClick={handleClose} component={Link} to={`/casos/${idCaso}`}>
                        Editar caso
                      </MenuItem>

                      {
                        userCase.case_status === 0 &&
                        <MenuItem component={Link} to={`/atenciones/crear/${idCaso}`}>
                          Registrar atención
                        </MenuItem>
                      }

                    </Menu>

                  </Grid>
                }
              </Grid>

              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Typography variant="h6">
                  {`${title} de ${userCase?.person?.name ?? ""}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12} style={{ marginBottom: "2%" }}>
                <Typography variant="h4">
                  {userCase.subject}
                </Typography>
              </Grid>

              <Grid item xs={12} md={12} lg={12} >
                <Card variant="outlined">
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12} md={6} lg={3} xl={3}>
                        <List className={classes.root}>
                          <ListItem alignItems="flex-start" key={"id"}>
                            <ListItemIcon>
                              <AssignmentInd color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                  >
                                    ID
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {userCase?.id ?? loadingDots}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem alignItems="flex-start" key={"rut"}>
                            <ListItemIcon>
                              <Person color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                  >
                                    Persona
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {userCase?.rut_person ?? loadingDots}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </List>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3} xl={3}>
                        <List className={classes.root}>
                          <ListItem alignItems="flex-start" key={"problem"}>
                            <ListItemIcon>
                              <ContactMail color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                  >
                                    Problemática
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {userCase?.sub_problem?.problem?.description ?? loadingDots}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem alignItems="flex-start" key={"sub-problem"}>
                            <ListItemIcon>
                              <ContactPhone color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                  >
                                    Sub-problemática
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {userCase?.sub_problem?.description ?? loadingDots}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} xl={3}>
                        <List className={classes.root}>
                          <ListItem alignItems="flex-start" key={"confidential"}>
                            <ListItemIcon>
                              <Work color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                  >
                                    Confidencial
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {userCase?.confidential === 0 ? "Si" : "No"}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem alignItems="flex-start" key={"complexity"}>
                            <ListItemIcon>
                              <Business color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                  >
                                    Complejidad
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {userCase?.complexity?.description}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} xl={3}>
                        <List className={classes.root}>
                          <ListItem alignItems="flex-start" key={"status"}>
                            <ListItemIcon>
                              <Lock color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                  >
                                    Estado
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {caseStatus[userCase?.case_status ?? 3].name}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />
                          <ListItem alignItems="flex-start" key={"complexity"}>
                            <ListItemIcon>
                              <Business color="primary" />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                  >
                                    Fecha {userCase.case_status !== 0 ? caseStatus[userCase?.case_status ?? 1].name.toLowerCase() : "Cerrado"}
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {userCase?.close_date ?? "N/A"}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h5">
                Atenciones
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} lg={12} xl={12} style={{ height: "43vh", overflowY: "scroll" }} >
              {attentions.map((attention, i) => (
                <Card variant="outlined" style={{ marginBottom: "1%" }} key={i}>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12} md={4} lg={3} xl={3}>
                        <List>
                          <ListItem alignItems="flex-start" >

                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                  >
                                    Numero de atención
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {numAttention += 1}
                                  </Typography>
                                </React.Fragment>
                              }
                            />

                          </ListItem>
                          <ListItem alignItems="flex-start">

                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                  >
                                    Canal
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {attention.channel_option?.channel?.description}
                                  </Typography>
                                </React.Fragment>
                              }
                            />

                          </ListItem>
                          <ListItem alignItems="flex-start">

                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                  >
                                    Herramienta de apoyo
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {attention.support_tools_options_sub?.support_tools_option?.support_tool?.des}
                                  </Typography>
                                </React.Fragment>
                              }
                            />

                          </ListItem>
                          <ListItem alignItems="flex-start">

                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                  >
                                    Fecha registro
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {moment(attention.created_at).format("DD/MM/YYYY")}
                                  </Typography>
                                </React.Fragment>
                              }
                            />

                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={12} md={4} lg={3} xl={3}>

                        <List>
                          <ListItem alignItems="flex-start" >

                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                  >
                                    Informe
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {attention?.report === 1 ? "No" : "Si"}
                                  </Typography>
                                </React.Fragment>
                              }
                            />

                          </ListItem>
                          <ListItem alignItems="flex-start">

                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                  >
                                    Opciones de canal
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {attention.channel_option?.description}
                                  </Typography>
                                </React.Fragment>
                              }
                            />

                          </ListItem>
                          <ListItem alignItems="flex-start">

                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                  >
                                    Sub herramienta de apoyo
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {attention.support_tools_options_sub?.support_tools_option?.description}
                                  </Typography>
                                </React.Fragment>
                              }
                            />

                          </ListItem>
                          <ListItem alignItems="flex-start">

                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    component="h6"
                                    variant="body2"
                                    className={classes.inline}
                                  >
                                    Fecha atención
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    className={[classes.inline, classes.subTitleList].join(' ')}
                                    color="textPrimary"
                                  >
                                    {moment(attention.attention_date).format("DD/MM/YYYY")}
                                  </Typography>
                                </React.Fragment>
                              }
                            />

                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={12} md={8} lg={6} xl={6}>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                          <Paper
                            style={{ marginBottom: "1%" }}
                            variant="outlined"
                            square
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              height="250px"
                              style={{
                                overflow: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              <Typography style={{ margin: "1%" }}>
                                Descripción: {attention.description}
                              </Typography>
                            </Box>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                          {
                            roles.slice(0, 2).includes(role) && userCase.case_status === 0 &&
                            <>
                              <Button >
                                <ChangeAttentionStatus idAttention={attention.id} status={attention.status} />
                              </Button>
                              <Button>
                                <DeleteAttention idAttention={attention.id} idCase={idCaso} />
                              </Button>
                              <Button
                                color="secondary"
                                component={Link}
                                to={`/atenciones/${idCaso}/${attention.id}`}
                              >
                                <EditIcon></EditIcon>
                              </Button>
                            </>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}

            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default IndexAttention;
import * as yup from "yup";
import { requiredMessage } from "./validationMessages";

const activityRecordValidationSchema = yup.object({
    date: yup.string().trim().required(requiredMessage),
    // time: yup.string().trim().required(requiredMessage),
    activity: yup.number().required(requiredMessage),
    activityChannel: yup.number().required(requiredMessage),
    activityType: yup.number().required(requiredMessage),
    client: yup.number().nullable(),
});

export { activityRecordValidationSchema }
import { downloadExcelFile } from "../util/downloadFiles";
import { displayAlert, displayClientError, displaySuccessAlert } from "../util/swalMessages";
import { getData } from "../util/localStorageService";
import { upperCaseWords } from "../validations/regexValidations";
import apiInstance from "./apiInstance";

const subUrl = `/person`;

const findAll = async (values, setPersons) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.get(`${subUrl}/persons`, {
    params: {
      name: values.name,
      phone: values.phone,
      note: values.note,
      email: values.email,
      rut: values.rut,
      id_client: values.client,
      status: values.status,
      id_account: account.id
    },
    headers: {
      "x-auth-token": user.authToken
    }
  }).then((response) => {
    setPersons(response.data);
  });

}

const findAllNoFilter = async (setPersons) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.get(`${subUrl}/allperson`,
    {
      params: {
        id_account: account.id
      },
      headers: {
        "x-auth-token": user.authToken
      }
    }).then((response) => {
      setPersons(response.data);
    });

}

const create = async (values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/create`,
    {
      rut: values.rut,
      name: upperCaseWords(values.name),
      email: values.email,
      phone: values.phone,
      note: values.note,
      id_client: values.client,
      labels: values.labels,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then(() => {
    setRedirect(true);
    displaySuccessAlert();
  }).catch(() => {
    setLoading(false);
  });

}

const changeStatus = async (type, id, value, setLabelStatus) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/change_status`,
    new URLSearchParams({
      id,
      value,
      type,
    }),
    {
      headers: {
        "x-auth-token": user.authToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then(() => {
    setLabelStatus(true);
  });


}

const update = async (id, values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/update`,
    {
      id,
      rut: values.rut,
      name: upperCaseWords(values.name),
      email: values.email,
      phone: values.phone,
      note: values.note,
      id_client: values.client,
      labels: values.labels,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then(() => {
    setRedirect(true);
    displaySuccessAlert();
  }).catch(() => {
    setLoading(false);
  });

}

const findById = async (id, setPerson) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/person`,
    {
      id,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    if (!response.data) { setPerson(null); displayClientError(); return }
    setPerson(response.data);
  });


}

const exportExcel = async (values) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.get(`${subUrl}/excel`, {
    responseType: "arraybuffer",
    params: {
      rut: values.rut,
      name: values.name,
      email: values.email,
      phone: values.phone,
      note: values.note,
      id_client: values.client,
      id_account: account.id,
      status: "",
    },
    headers: {
      "x-auth-token": user.authToken,
    },
  }).then((response) => {
    downloadExcelFile(response, "Reporte_personas");
  })

}

const masiveUpload = async (file, setRedirect, setLoading) => {

  const filename = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);

  if (filename !== "xlsx") {
    displayAlert("¡Ups!", "Archivo no permitido.", "error");
    return;
  }

  const account = getData("account");

  let formData = new FormData();
  formData.append("excel", file);
  formData.append("id_account", account.id);

  const user = getData("user");
  await apiInstance.post(`${subUrl}/read`, formData, {
    headers: {
      "x-auth-token": user.authToken,
      "Content-Type": "multipart/form-data"
    }
  }).then((response) => {
    setLoading(false);
    let uniqueIds = [];
    setRedirect({
      status: true,
      data: response.data.clients.filter(person => {
        let isDuplicated = uniqueIds.includes(person.rut);
        if (!isDuplicated) uniqueIds.push(person.rut)
        return Boolean(!isDuplicated);
      }),
      errors: response.data.data
    });
  }).catch(() => {
    setLoading(false);
  });

}

const findPersonCases = async (id, setPersonCases) => {

  const user = getData("user");
  await apiInstance.post(`${subUrl}/cases`, {
    id
  }, {
    headers: {
      "x-auth-token": user.authToken
    }
  }).then((response) => {
    if (!response.data) { setPersonCases(null); displayClientError(); return }
    setPersonCases(response.data);
  });

}

export {
  findAll,
  changeStatus,
  create,
  update,
  findById,
  exportExcel,
  masiveUpload,
  findAllNoFilter,
  findPersonCases
}
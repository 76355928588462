import React from "react";

import NavDrawer from "../components/NavDrawer";
import {
  createTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import AuthContext, { AuthProvider } from "../context/AuthProvider";
import Routes from "../components/Routes";
import { useContext } from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00a3d9",
      contrastText: "#fff",
    },
    secondary: {
      main: "#3eab34",
      contrastText: "#fff",
    },
  },
  overrides: {
    RaDatagrid: {
      headerCell: {
        fontWeight: 'bold',
      },
    }
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#fff",
        },
      },
    },
  }
});

const useStyles = makeStyles((style) => ({
  '@global': {
    '.swal2-popup': {
      fontFamily: "Montserrat, sans-serif"
    },
    '.swal2-confirm swal2-styled': {
      backgroundColor: "#00a3d9"
    },
    '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: "700 !important"
    },
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#00a3d9',
      borderRadius: 5
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: style.spacing(0, 1),
    // necessary for content to be below app bar
    ...style.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  root: {
    display: "flex",

  },
}));

function App(props) {
  const classes = useStyles();
  const { auth, loading } = useContext(AuthContext);
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        {(auth) && (
          <NavDrawer
            color="default"
            logo="https://probenefit.cl/wp-content/uploads/2020/07/Logo-probenefit-horizontal-fondo-blanco-300px.png"
            logoInverted="https://probenefit.cl/wp-content/uploads/2020/07/Logo-probenefit-horizontal-fondo-blanco-300px.png"
          />
        )}

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Routes />
        </main>
      </MuiThemeProvider>
    </div>
  );
}

export default App;

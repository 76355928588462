import { getData } from '../util/localStorageService';
import apiInstance from './apiInstance';

const subUrl = `/channeloption`;

const findAll = async (id, setChannelOptions) => {
        const user = getData("user");
        const request = await apiInstance.post(`${subUrl}/channeloptions`, {
            id
        },{
            headers: {
                "x-auth-token": user.authToken
            }
        }).then((response) => {
            setChannelOptions(response.data);
        });

}

export { findAll }


import { getData } from "../util/localStorageService";
import apiInstance from "./apiInstance";

const subUrl = `/zone`;

const findAll = async (setZones) => {

    const user = getData("user");
    await apiInstance.get(`${subUrl}/zones`, {
        headers: {
            "x-auth-token": user.authToken
        }
    }).then((response) => {
        setZones(response.data);
    });


}

export { findAll }

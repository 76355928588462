import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Add, Delete, Remove } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { Autocomplete } from "@material-ui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { findAll as findAllAccount, findAllActive } from "../../api/account";
import { exportExcel, findAll, masiveUpload } from "../../api/client";
import {
  findAll as findAllRegions,
  findAllCommunes,
  findAllProvinces
} from "../../api/region";
import { findUserAccounts } from "../../api/user";
import { findAll as findAllZones } from "../../api/zone";
import { getData, saveData } from "../../util/localStorageService";
import { displayLoading } from "../../util/swalMessages";
import { componentPropsDataGrid, customLocaleText } from "../CustomToolbar";
import Section from "../Section";
import { ChangeClientStatus, DeleteClient } from "./ClientTableActions";
import { DeleteFilter } from "../DeleteFilter";

const title = "Sucursales";

const columns = [
  {
    field: "acciones",
    headerName: "Acciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <>
          <IconButton
            component={Link}
            to={"/clientes/" + params.row.id}
            variant="contained"
            size="small"
            color="secondary"
            title="Editar"
          >
            <EditIcon></EditIcon>
          </IconButton>
          <DeleteClient clientId={params.row.id} />
        </>
      );
    },
  },
  {
    field: "habilitado",
    headerName: "Habilitado",
    width: 100,
    sortable: false,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <ChangeClientStatus
          status={params.row.status}
          clientId={params.row.id}
        />
      );
    },
  },
  // {
  //   field: "id",
  //   headerName: "#",
  //   width: 100,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "identifier",
    headerName: "Identificador",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "account",
    headerName: "Cuenta",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.account.name}</div>;
    },
  },
  {
    field: "enterprise",
    headerName: "Empresa",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "division",
    headerName: "División",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "description",
    headerName: "Zona",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.zone.description}</div>;
    },
  },
  {
    field: "branch_office",
    headerName: "Nombre sucursal",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "created_at",
    headerName: "Fecha registro",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
  {
    field: "updated_at",
    headerName: "Fecha modificación",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function IndexClient() {

  const filtersName = "clientFilters";

  const [pageSize, setPageSize] = React.useState(20);
  const [loading, setLoading] = useState(false);
  const [filtro, setFiltro] = useState(false);
  const [redirect, setRedirect] = useState({ status: false, data: [], errors: [] });
  const [isEventChanged, setIsEventChanged] = useState(false);
  const [accountName, setaccountName] = useState(getData("account").name)
  const [savedFilters, setSavedFilters] = useState(getData(filtersName));

  const [clients, setClients] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [zones, setZones] = useState([]);
  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [communes, setCommunes] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFiltro = () => {
    setFiltro(!filtro);
  };

  const saveFilters = (values) => {
    saveData(filtersName, JSON.stringify(values));
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      identifier: savedFilters?.identifier ?? "",
      enterprise: savedFilters?.enterprise ?? "",
      division: savedFilters?.division ?? "",
      status: savedFilters?.status ?? "",
      branchOffice: savedFilters?.branchOffice ?? "",
      zone: savedFilters?.zone ?? "",
      commune: savedFilters?.commune ?? "",
      account: savedFilters?.account ?? "",
      region: savedFilters?.region ?? "",
      province: savedFilters?.province ?? "",
    },
    onSubmit: (values) => {
      if (
        values.commune === "" &&
        (values.region !== "" || values.province !== "")
      ) {
        return;
      }
      saveFilters(values);
      console.log(values)
      findAll(values, setClients);
    },
  });

  useEffect(() => {
    findUserAccounts(setAccounts);
    findAllZones(setZones);
    findAllRegions(setRegions);
    findAll(formik.initialValues, setClients);
  }, []);

  useEffect(() => {
    if (savedFilters) {
      handleCommuneChange(formik.values.province);
      handleProvincesChange(formik.values.region);
    }
  }, [savedFilters]);

  const handleProvincesChange = (value) => {
    if (value !== null) {
      findAllProvinces(value, setProvinces);
    } else {
      setProvinces([]);
    }
  }

  const handleCommuneChange = (value) => {
    if (value !== null) {
      findAllCommunes(value, setCommunes);
    } else {
      setCommunes([]);
    }
  }

  const handleExport = () => {
    exportExcel(formik.values);
  }

  useEffect(() => {
    window.addEventListener('accountStorage', () => {
      if (!isEventChanged) {
        setIsEventChanged(true);
        findAll(formik.values, setClients);
        setaccountName(getData("account").name);
      }
    });
  }, []);

  const uploadExcel = (file) => {
    Swal.fire({
      title: "¿Cargar Excel?",
      text: file.name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#10a4da",
      cancelButtonColor: "#d33",
      confirmButtonText: "Cargar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        masiveUpload(file, setRedirect, setLoading);
      }
    });
  }

  useEffect(() => {
    if (!savedFilters) formik.resetForm();
  }, [savedFilters]);

  useEffect(() => {
    if (loading) { displayLoading() } else { Swal.close() };
  }, [loading]);

  if (redirect.status) {
    return (
      <Redirect
        to={{
          pathname: "/clientes/resultado-carga",
          status: { data: redirect },
        }}
      />
    );
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container={true} spacing={4}>
            <Grid item lg={12} md={12} xs={12} xl={12} container>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h4" display="inline" > {title} - </Typography>
                <Typography variant="h4" display="inline" style={{ fontWeight: "bold" }}> {accountName} </Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={12} xl={6} container justifyContent="flex-end" >
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="secondary"
                  variant="outlined"
                >
                  Opciones
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <input
                      // accept="image/*"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(e) => {
                        uploadExcel(e.target.files[0]);
                        e.target.value = null
                      }}
                    />
                    <label
                      htmlFor="contained-button-file"
                      style={{ cursor: "pointer" }}
                    >
                      Cargar Excel
                    </label>
                  </MenuItem>
                  <MenuItem component={Link} to="/clientes/crear">
                    Crear
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <Chip
                avatar={<Avatar>{filtro ? <Remove /> : <Add />}</Avatar>}
                label={filtro ? "Ocultar filtros" : "Mostrar filtros"}
                onClick={handleFiltro}
                variant="outlined"
                style={{ fontSize: "18px" }}
              />
            </Grid>
            {filtro && (
              <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined">
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container={true} spacing={2} alignItems="center">
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <TextField
                            fullWidth
                            id="identifier"
                            name="identifier"
                            label="Identificador"
                            type="text"
                            variant="outlined"
                            value={formik.values.identifier}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <Autocomplete
                            disablePortal
                            id="account"
                            name="account"
                            value={{
                              id: formik.values.account,
                              name: accounts.find(account => account.id === formik.values.account)?.name ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            options={accounts}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Cuenta"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "account",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.account
                              );
                            }}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <TextField
                            fullWidth
                            id="enterprise"
                            name="enterprise"
                            label="Empresa"
                            type="text"
                            variant="outlined"
                            value={formik.values.enterprise}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <TextField
                            fullWidth
                            id="division"
                            name="division"
                            label="División"
                            type="text"
                            variant="outlined"
                            value={formik.values.division}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <Autocomplete
                            disablePortal
                            id="zone"
                            name="zone"
                            value={{
                              id: formik.values.zone,
                              description: zones.find(zone => zone.id === formik.values.zone)?.description ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            options={zones}
                            getOptionLabel={(option) => option.description}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Zona"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "zone",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.zone
                              );
                            }}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <TextField
                            fullWidth
                            id="branchOffice"
                            name="branchOffice"
                            label="Nombre sucursal"
                            type="text"
                            variant="outlined"
                            value={formik.values.branchOffice}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <Autocomplete
                            disablePortal
                            id="region"
                            name="region"
                            value={{
                              id: formik.values.region,
                              region: regions.find(region => region.id === formik.values.region)?.region ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            options={regions}
                            getOptionLabel={(option) => option.region}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Región"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "region",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.region
                              );
                              handleProvincesChange(value.id);
                            }}
                          />
                        </Grid>

                        {(provinces.length >= 1 || formik.values.province !== "") && (
                          <Grid item={true} xs={12} md={6} lg={3}>
                            <Autocomplete
                              disablePortal
                              id="province"
                              name="province"
                              value={{
                                id: formik.values.province,
                                provincia: provinces.find(province => province.id === formik.values.province)?.provincia ?? ""
                              }}
                              getOptionSelected={(option, value) =>
                                option.id === value.id
                              }
                              options={provinces}
                              getOptionLabel={(option) => option.provincia}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Provincia"
                                  variant="outlined"
                                />
                              )}
                              onChange={(e, value) => {
                                formik.setFieldValue(
                                  "province",
                                  value !== null
                                    ? value.id
                                    : formik.initialValues.province
                                );
                                handleCommuneChange(value.id);
                              }}
                            />
                          </Grid>
                        )}
                        {(communes.length >= 1 || formik.values.commune !== "") && (
                          <Grid item={true} xs={12} md={6} lg={3}>
                            <Autocomplete
                              disablePortal
                              id="commune"
                              name="commune"
                              value={{
                                id: formik.values.commune,
                                comuna: communes.find(commune => commune.id === formik.values.commune)?.comuna ?? ""
                              }}
                              getOptionSelected={(option, value) =>
                                option.id === value.id
                              }
                              options={communes}
                              getOptionLabel={(option) => option.comuna}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Comuna"
                                  variant="outlined"
                                />
                              )}
                              onChange={(e, value) => {
                                formik.setFieldValue(
                                  "commune",
                                  value !== null
                                    ? value.id
                                    : formik.initialValues.commune
                                );
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item={true} xs={12} md={10}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                          >
                            Filtrar
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={handleExport}
                            style={{ marginLeft: "2%" }}
                          >
                            Exportar
                          </Button>
                        </Grid>
                        <Grid item={true} xs={12} md={2}>
                          <DeleteFilter filtersName={filtersName} setSavedFilters={setSavedFilters} formik={formik} />
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item={true} xs={12} md={12}>
              <div style={{ height: 515, width: "100%" }}>
                <DataGrid
                  rows={clients}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                  disableColumnMenu
                  localeText={customLocaleText}
                  componentsProps={componentPropsDataGrid}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default IndexClient;

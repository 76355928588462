import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { recoverPass } from "../../api/auth";
import { mailValidationSchema } from "../../validations/authValidations";
import Section from "../Section";

const title = "Recuperar contraseña";

function RecoverPass() {
  const [redirect, setRedirect] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      recoverPass(values, setRedirect);
    },
    validationSchema: mailValidationSchema,
  });

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Helmet title={title} />
      <Section
        bgImage="/fondoRAS.png"
        size="medium"
        // bgColor="transparent-blue"
        bgImageOpacity={0.8}
      >
        <Container maxWidth="sm">
          {/* <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
            <img src="https://probenefit.cl/wp-content/uploads/2020/07/Logo-probenefit-horizontal-fondo-blanco-300px.png" />
          </Grid> */}
          <Card style={{ marginTop: "5%", padding: "3%"  }}>
            <CardContent>
              <Grid container={true} spacing={4}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h4" style={{ textAlign: "center" }}>
                    {title}
                  </Typography>
                  <Typography style={{ textAlign: "center" }}>
                    Ingresa tu correo electrónico para que puedas recuperar el acceso a tu
                    cuenta
                  </Typography>
                </Grid>
                <Grid item={true} xs={12} md={12}>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container={true} spacing={2}>
                      <Grid item={true} xs={12} md={12}>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Correo electrónico"
                          type="text"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          type="submit"
                          fullWidth
                        >
                          Enviar
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography style={{ textAlign: "center" }}>
                          <Link href="/login">Volver</Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Section>
    </>
  );
}

export default RecoverPass;

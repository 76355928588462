import { Button, Container, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { masiveUpload } from "../../api/activityRecordRoster";
import { displayLoading } from "../../util/swalMessages";
import { componentPropsDataGrid, customLocaleText, CustomToolbar } from "../CustomToolbar";
import Section from "../Section";

const title = "Detalle de carga de nómina";
const colors = {
  0: {
    color: "#F31C4A",
    txt: "Persona no encontrada",
  },
  1: {
    color: "#58DE56",
    txt: "Ingresado",
  },
  2: {
    color: "#F31C4A",
    txt: "RUT inválido",
  },
  3: {
    color: "#E8B11C",
    txt: "Persona ya existente en nómina"
  },
};

const columns = [
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Estado",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      let { status } = params.row;
      return (
        <div style={{ color: colors[status].color }}> {colors[status].txt} </div>
      );
    },
  },
];

const errorsColumns = [

  {
    field: "linea",
    headerName: "Linea excel",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "error",
    headerName: "Dato incorrecto",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div style={{ color: 'red' }}>{params.row.error}</div>
      );
    },
  },
]

function IndexActivityRecordRosterExcel(props) {

  const { idRegistroActividad } = useParams();

  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState({ status: false, data: [], errors: [] });

  // if (props.localtion.status === undefined) {
  //     return <Redirect to={`/nomina-actividad/index/${idRegistroActividad}`} />
  // }

  function subirExcel(file) {
    Swal.fire({
      title: "¿Confirmas la carga de la nómina?",
      text: `${file.name}`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#10a4da",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, subir",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        masiveUpload(idRegistroActividad, file, setRedirect, setLoading);
      }
    });
  }

  useEffect(() => {
    if (loading) { displayLoading() } else { Swal.close() };
  }, [loading]);

  if (redirect.status) {
    props.location.status.data = redirect;
  }

  return (
    <>
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container={true} spacing={4}>
            <Grid item lg={12} md={12} xs={12} xl={12} container>
              <Grid item xs={6} md={6} lg={6}>
                <Typography variant="h4">
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6} container justifyContent="flex-end">
                <input
                  id="contained-button-file"
                  multiple
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    subirExcel(e.target.files[0]);
                    e.target.value = null
                  }}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="secondary" component="span">
                    Cargar Excel
                  </Button>
                </label>
              </Grid>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <div style={{ height: 515, width: "100%" }}>
                <DataGrid
                  rows={props.location.status.data.data}
                  columns={columns}
                  pageSize={pageSize}
                  getRowId={() => Math.random()}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                  disableColumnMenu
                  localeText={customLocaleText}
                  componentsProps={componentPropsDataGrid}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                />
              </div>
            </Grid>
            {
              props.location.status.data.errors.length > 0 &&
              <>
                <Grid item xs={12} md={10} lg={10} xl={10}>
                  <Typography variant="h4">Detalle de inconsistencias</Typography>
                </Grid>


                <Grid item={true} xs={12} md={12}>
                  <div style={{ height: 300, width: "100%" }}>
                    <DataGrid
                      rows={props.location.status.data.errors}
                      columns={errorsColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 15, 20]}
                      getRowId={() => Math.random()}
                      disableSelectionOnClick
                      localeText={customLocaleText}
                      componentsProps={componentPropsDataGrid}
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                    />
                  </div>
                </Grid>
              </>
            }
            <Grid item xs={12} md={12} lg={12}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/nomina-actividad/index/${idRegistroActividad}`}
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default IndexActivityRecordRosterExcel;

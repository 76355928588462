const rutRegEx = /^\d{7,8}[\dkK]$/;
const nineDigitsRegEx = /^[0-9]+$/;
const passwordValidationRegEx = /^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/

const upperCaseWords = (value) => {
    return value.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
};

const formatRut = (value) => {

    const cleanRut = value.replace(/[^0-9kK]/g, '');

    const body = cleanRut.slice(0, -1);
    const dv = cleanRut.slice(-1);

    if (cleanRut.length < 2) return cleanRut;

    let bodyFormat = body
        .toString()
        .split('')
        .reverse()
        .join('')
        .replace(/(?=\d*\.?)(\d{3})/g, '$1');

    bodyFormat = bodyFormat
        .split('')
        .reverse()
        .join('')
        .replace(/^[\.]/, '');

    return `${bodyFormat}${dv}`;
}

const formatPhone = (value) => {

    var spaceCount = (value.split(" ").length - 1);

    if (value.length === 1) {
        value = value + ' ';
    }

    if (value.length === 6) {
        value = value + ' ';
    }

    return value;

}

export { upperCaseWords, formatRut, rutRegEx, formatPhone, nineDigitsRegEx, passwordValidationRegEx }
import { IconButton, Switch } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { changeStatus } from "../../api/user";
import { getData } from "../../util/localStorageService";

const ChangeUserStatus = ({ status, userId }) => {

  const [userStatus, setUserStatus] = useState(false);
  const [checkStatus, setCheckStatus] = useState(status === 2 ? true : false);
  
  const handleUserStatus = (checked) => {
    
    const user = getData("user");
    if (userId === user.id && !checked) {
      Swal.fire({
        title: "No puedes deshabilitar el usuario en uso.",
        icon: "warning"
      });
      return;
    }
  
    changeStatus(1, userId, checked ? 2 : 3, setUserStatus);
    setUserStatus(false);
  };

  useEffect(() => {
    if (userStatus) {
      setCheckStatus(!checkStatus);
    }
  }, [userStatus]);

  return (
    <Switch
      color="primary"
      checked={checkStatus}
      onClick={(e) => {
        handleUserStatus(e.target.checked);
      }}
    />
  );
};

const DeleteUser = ({ userId }) => {
  
  const [userStatus, setUserStatus] = useState(false);
  
  const deleteUser = () => {
    
    const user = getData("user");
    if (userId === user.id) {

      Swal.fire({
        title: "No puedes eliminar el usuario en uso.",
        icon: "warning"
      });
      return;
    }

    Swal.fire({
      title: "¿Eliminar usuario?",
      text: "No será posible revertir esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#10a4da",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus(2, userId, 0, setUserStatus);
      }
    });
  };

    if (userStatus) {
      window.location.reload();
    }

  return (
    <IconButton
      variant="contained"
      size="small"
      color="default"
      title="Borrar"
      onClick={() => deleteUser()}
    >
      <Delete></Delete>
    </IconButton>
  );
};

export { ChangeUserStatus, DeleteUser };


import { downloadExcelFile } from "../util/downloadFiles";
import { displayAlert, displayClientError, displaySuccessAlert } from "../util/swalMessages";
import { getData } from "../util/localStorageService";
import { upperCaseWords } from "../validations/regexValidations";
import apiInstance from "./apiInstance";

const subUrl = `/client`

const findAllActive = async (setClients) => {

  const user = getData("user");
  const account = getData("account");

  await apiInstance.get(`${subUrl}/list`, {
    params:{
      id_account: account.id
    },
    headers:{
      "x-auth-token": user.authToken,
    }
  }).then((response) => {
    setClients(response.data);
  });

}

const findAll = async (values, setClients) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.get(
    `${subUrl}/clients`,
    {
      params: {
        identifier: values?.identifier ?? "",
        enterprise: values?.enterprise ?? "",
        division: values?.division ?? "",
        status: values?.status ?? "",
        branch_office: values?.branchOffice ?? "",
        id_zone: values?.zone ?? "",
        id_commune: values?.commune ?? "",
        id_account: values?.account === "" || values === null ? account.id : values?.account,
      },
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    setClients(response.data);
  });

}

const create = async (values, setRedirect, setLoading) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.post(
    `${subUrl}/create`,
    {
      identifier: upperCaseWords(values.identifier),
      id_account: account.id,
      enterprise: upperCaseWords(values.enterprise),
      division: upperCaseWords(values.division),
      id_zone: values.zone,
      branch_office: upperCaseWords(values.branchOffice),
      id_commune: values.commune,
      labels: values.labels,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then(() => {
    setRedirect(true);
    displaySuccessAlert();
  }).catch((error) => {
    setLoading(false);
  });

}

const changeStatus = async (type, id, value, setClientStatus) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/change_status`,
    new URLSearchParams({
      id,
      type,
      value,
    }),
    {
      headers: {
        "x-auth-token": user.authToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then(() => {
    setClientStatus(true);
  });

}

const findById = async (id, setClient) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/client`,
    {
      id,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    if (!response.data) { setClient(null); displayClientError(); return }
    setClient(response.data.data);
  });

}

const update = async (id, values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/update`,
    {
      id,
      enterprise: upperCaseWords(values.enterprise),
      division: upperCaseWords(values.division),
      status: values.status,
      branch_office: upperCaseWords(values.branchOffice),
      id_zone: values.zone,
      id_commune: values.commune,
      id_account: values.account,
      labels: values.labels,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then(() => {
    displaySuccessAlert();
    setRedirect(true);
  }).catch(() => {
    setLoading(false);
  });

}

const exportExcel = async (values) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.get(`${subUrl}/export`, {
    responseType: "arraybuffer",
    params: {
      identifier: values.identifier,
      enterprise: values.enterprise,
      division: values.division,
      status: values.status,
      branch_office: values.branchOffice,
      id_zone: values.zone,
      id_commune: values.commune,
      id_account: values.account === "" ? account.id : values.account,
    },
    headers: {
      "x-auth-token": user.authToken,
    },
  }).then((response) => {
    downloadExcelFile(response, "Reporte_sucursales");
  })


}

const masiveUpload = async (file, setRedirect, setLoading) => {

  const filename = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);

  if (filename !== "xlsx") {
    displayAlert("¡Ups!", "Archivo no permitido.", "error");
    return;
  }

  let formData = new FormData();
  formData.append("excel", file);

  const user = getData("user");
  await apiInstance.post(`${subUrl}/excel`, formData, {
    headers: {
      "x-auth-token": user.authToken,
      "Content-Type": "multipart/form-data"
    }
  }).then((response) => {
    setLoading(false);
    let uniqueIds = [];
    setRedirect({
      status: true,
      data: response.data.clients.filter(client => {
        let isDuplicated = uniqueIds.includes(client.identifier);
        if (!isDuplicated) uniqueIds.push(client.identifier)
        return Boolean(!isDuplicated);
      }),
      errors: response.data.data
    });
  }).catch(() => {
    setLoading(false);
  });

}

export { findAllActive, findAll, create, changeStatus, update, findById, exportExcel, masiveUpload }

import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl, FormHelperText,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select, TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect, useParams } from "react-router-dom";
import { create } from "../../api/case";
import { findAll } from "../../api/complexities";
import { findAllNoFilter } from "../../api/person";
import { findAll as findAllProblems } from '../../api/problem';
import { findAll as findAllSubProblems } from "../../api/subProblem";
import { caseAndAttentionFormValidationSchema } from "../../validations/caseValidations";
import Section from "../Section";

import { findAll as findAllLabels } from "../../api/label";

import { findAll as findAllSupports } from "../../api/support";
import { findAll as findAllOptionSubs } from "../../api/supportToolOptions";
import { findAll as findAllTools } from "../../api/tool";

import { findAll as findAllChannels } from "../../api/channel";
import { findAll as findAllChannelOptions } from "../../api/channelOptions";
import { caseStatusSelect } from "../../config/caseStatus";
import Swal from "sweetalert2";
import { displayGenericLoading } from "../../util/swalMessages";
import { findAll as findAllClients } from "../../api/client";
import { findById as findByIdPerson } from "../../api/person"


const title = "Crear caso";

const doubleAnswerSelect = [
  {
    name: "Si",
    id: 0
  },
  {
    name: "No",
    id: 1
  }
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CreateCase() {

  const { idPersona } = useParams();

  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEventChanged, setIsEventChanged] = useState(false);

  //case
  const [persons, setPersons] = useState([]);
  const [clients, setClients] = useState([]);
  const [complexities, setComplexities] = useState([]);
  const [problems, setProblems] = useState([]);
  const [subProblems, setSubProblems] = useState([]);
  const [labels, setLabels] = useState([]);

  //attention
  const [supports, setSupports] = useState([]);
  const [tools, setTools] = useState([]);
  const [optionSubs, setOptionSubs] = useState([]);
  const [channels, setChannels] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);

  // useEffect(() => {
  //   displayGenericLoading();
  //   findAllNoFilter(setPersons);
  //   Swal.close();
  // }, []);

  useEffect(() => {

    if (idPersona) {
      findByIdPerson(idPersona, setPersons);
    } else {
      findAllNoFilter(setPersons);
    }

    findAllClients(null, setClients);
    findAll(setComplexities);
    findAllProblems(setProblems);
    findAllSupports(setSupports);
    findAllChannels(setChannels);
    findAllLabels(setLabels, 3);

  }, []);


  const formik = useFormik({
    initialValues: {

      person: "",
      subject: "",
      problem: "",
      subProblem: "",
      confidential: "",
      complexity: "",
      caseStatus: "",
      labels: [],

      attentionDate: "",
      channel: "",
      channelOption: "",
      support: "",
      tool: "",
      optionSub: "",
      report: "",
      description: "",

    },
    onSubmit: (values) => {
      setLoading(true);
      create(values, setRedirect, setLoading);
    },
    validationSchema: caseAndAttentionFormValidationSchema
  });

  const handleSubProblemChange = (value) => {
    if (value > 0) {
      findAllSubProblems(value, setSubProblems);
    } else {
      setSubProblems([]);
    }
  }

  const handleChannelOptionsChange = (channelId) => {
    if (channelId > 0) {
      findAllChannelOptions(channelId, setChannelOptions);
    } else {
      setChannelOptions([]);
    }
  }

  const handleToolChange = (supportId) => {
    if (supportId > 0) {
      findAllTools(supportId, setTools);
    } else {
      setTools([]);
    }
  }

  const handleOptionSubsChange = (toolId) => {
    if (toolId > 0) {
      findAllOptionSubs(toolId, setOptionSubs);
    } else {
      setOptionSubs([]);
    }
  }

  useEffect(() => {
    window.addEventListener('accountStorage', () => {
      if (!isEventChanged) {
        setIsEventChanged(true);
        findAllNoFilter(setPersons);
      }
    });
  }, []);

  if (idPersona && persons?.status === 1) {
    return <Redirect to={`/personas/ficha/${idPersona}`} />
  }

  if (redirect) {
    return <Redirect to="/casos/index" />
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>
                Los campos marcados con * son obligatorios.
              </Typography>
              {
                formik.values.caseStatus > 0 &&
                <Typography style={{ fontSize: "15px", color: "red" }}>
                  Si cierras o derivas un caso, no le podrás registrar más atenciones como asistente/a.
                </Typography>
              }
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="person"
                      name="person"
                      options={Array.isArray(persons) ? persons : [persons]}
                      getOptionLabel={(option) => `${option.name}(${option.rut})`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Persona *"
                          variant="outlined"
                          error={
                            formik.touched.person &&
                            Boolean(formik.errors.person)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "person",
                          value !== null
                            ? value.id
                            : formik.initialValues.person
                        );
                      }}
                    />
                    {formik.touched.person && formik.errors.person && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.person}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="subject"
                      name="subject"
                      label="Asunto *"
                      type="text"
                      variant="outlined"
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      error={formik.touched.subject && Boolean(formik.errors.subject)}
                      helperText={formik.touched.subject && formik.errors.subject}
                    />
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="problem"
                      name="problem"
                      options={problems}
                      getOptionLabel={(option) => option.description}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Problemática *"
                          variant="outlined"
                          error={
                            formik.touched.problem &&
                            Boolean(formik.errors.problem)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        let valueProblem = value !== null ? value.id : formik.initialValues.problem;
                        formik.setFieldValue(
                          "problem",
                          valueProblem
                        );
                        handleSubProblemChange(valueProblem);
                      }}
                    />
                    {formik.touched.problem && formik.errors.problem && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.problem}
                      </FormHelperText>
                    )}
                  </Grid>

                  {(subProblems.length > 0) && (
                    <Grid item={true} xs={12} md={6}>
                      <Autocomplete
                        disablePortal
                        id="subProblem"
                        name="subProblem"
                        options={subProblems}
                        getOptionLabel={(option) => option.description}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub problemática *"
                            variant="outlined"
                            error={
                              formik.touched.subProblem &&
                              Boolean(formik.errors.subProblem)
                            }
                          />
                        )}
                        onChange={(e, value) => {
                          formik.setFieldValue(
                            "subProblem",
                            value !== null
                              ? value.id
                              : formik.initialValues.subProblem
                          );
                        }}
                      />
                      {formik.touched.subProblem && formik.errors.subProblem && (
                        <FormHelperText style={{ color: "red" }}>
                          {formik.errors.subProblem}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}

                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="confidential"
                      name="confidential"
                      options={doubleAnswerSelect}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Confidencial *"
                          variant="outlined"
                          error={
                            formik.touched.confidential &&
                            Boolean(formik.errors.confidential)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "confidential",
                          value !== null
                            ? value.id
                            : formik.initialValues.confidential
                        );
                      }}
                    />
                    {formik.touched.confidential && formik.errors.confidential && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.confidential}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="complexity"
                      name="complexity"
                      options={complexities}
                      getOptionLabel={(option) => option.description}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Complejidad *"
                          variant="outlined"
                          error={
                            formik.touched.complexity &&
                            Boolean(formik.errors.complexity)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "complexity",
                          value !== null
                            ? value.id
                            : formik.initialValues.complexity
                        );
                      }}
                    />
                    {formik.touched.complexity && formik.errors.complexity && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.complexity}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="labls">Etiquetas</InputLabel>
                      <Select
                        labelId="accounts-label"
                        id="labels"
                        name="labels"
                        multiple
                        value={formik.values.labels}
                        onChange={formik.handleChange}
                        input={<Input />}
                        MenuProps={MenuProps}
                        renderValue={(selected) =>
                          labels
                            .filter((name) => selected.includes(name.id))
                            .map((record) => record.name)
                            .join(", ")
                        }
                      >
                        {labels.map((label) => (
                          <MenuItem key={label.id} value={label.id}>
                            <Checkbox
                              checked={
                                formik.values.labels.indexOf(label.id) > -1
                              }
                            />
                            <ListItemText primary={label.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="caseStatus"
                      name="caseStatus"
                      options={caseStatusSelect}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Estado *"
                          variant="outlined"

                          error={
                            formik.touched.caseStatus &&
                            Boolean(formik.errors.caseStatus)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "caseStatus",
                          value !== null
                            ? value.id
                            : formik.initialValues.caseStatus
                        );

                      }}
                    />
                    {formik.touched.caseStatus && formik.errors.caseStatus && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.caseStatus}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h4">
                      Crear atención
                    </Typography>
                    <Typography style={{ fontSize: "15px" }}>
                      Los campos marcados con * son obligatorios.
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="attentionDate"
                      name="attentionDate"
                      label="Fecha de atención *"
                      type="date"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={formik.values.attentionDate}
                      onChange={formik.handleChange}
                      error={formik.touched.attentionDate && Boolean(formik.errors.attentionDate)}
                      helperText={formik.touched.attentionDate && formik.errors.attentionDate}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        disablePortal
                        id="channel"
                        name="channel"
                        options={channels}
                        getOptionLabel={(option) => option.description}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Canal *"
                            variant="outlined"
                            error={
                              formik.touched.channel &&
                              Boolean(formik.errors.channel)
                            }
                          />
                        )}
                        onChange={(e, value) => {
                          let channelId = value !== null ? value.id : formik.initialValues.channel;
                          formik.setFieldValue("channel", channelId);
                          handleChannelOptionsChange(channelId);
                        }}
                      />
                      {formik.touched.channel && formik.errors.channel && (
                        <FormHelperText style={{ color: "red" }}>
                          {formik.errors.channel}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {channelOptions.length > 0 && (
                    <Grid item={true} xs={12} md={6}>
                      <FormControl fullWidth variant="outlined">
                        <Autocomplete
                          disablePortal
                          id="channelOption"
                          name="channelOption"
                          options={channelOptions}
                          getOptionLabel={(option) => option.description}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Opciones de canal *"
                              variant="outlined"
                              error={
                                formik.touched.channelOption &&
                                Boolean(formik.errors.channelOption)
                              }
                            />
                          )}
                          onChange={(e, value) => {
                            formik.setFieldValue(
                              "channelOption",
                              value !== null
                                ? value.id
                                : formik.initialValues.channelOption
                            );
                          }}
                        />
                        {formik.touched.channelOption && formik.errors.channelOption && (
                          <FormHelperText style={{ color: "red" }}>
                            {formik.errors.channelOption}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item={true} xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        disablePortal
                        id="support"
                        name="support"
                        options={supports}
                        getOptionLabel={(option) => option.description}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Herramientas de apoyo *"
                            variant="outlined"
                            error={
                              formik.touched.support &&
                              Boolean(formik.errors.support)
                            }
                          />
                        )}
                        onChange={(e, value) => {
                          let supportId = value !== null ? value.id : formik.initialValues.support;
                          formik.setFieldValue("support", supportId);
                          handleToolChange(supportId);
                        }}
                      />
                      {formik.touched.support && formik.errors.support && (
                        <FormHelperText style={{ color: "red" }}>
                          {formik.errors.support}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {tools.length > 0 && (
                    <Grid item={true} xs={12} md={6}>
                      <FormControl fullWidth variant="outlined">
                        <Autocomplete
                          disablePortal
                          id="tool"
                          name="tool"
                          options={tools}
                          getOptionLabel={(option) => option.description}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sub Herramientas *"
                              variant="outlined"
                              error={
                                formik.touched.tool &&
                                Boolean(formik.errors.tool)
                              }
                            />
                          )}
                          onChange={(e, value) => {
                            let toolId = value !== null ? value.id : formik.initialValues.tool;
                            formik.setFieldValue("tool", toolId);
                            handleOptionSubsChange(toolId);
                          }}
                        />
                        {formik.touched.tool && formik.errors.tool && (
                          <FormHelperText style={{ color: "red" }}>
                            {formik.errors.tool}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  {optionSubs.length > 0 && (
                    <Grid item={true} xs={12} md={6}>
                      <FormControl fullWidth variant="outlined">
                        <Autocomplete
                          disablePortal
                          id="optionSub"
                          name="optionSub"
                          options={optionSubs}
                          getOptionLabel={(option) => option.description}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Opciones *"
                              variant="outlined"
                              error={
                                formik.touched.optionSub &&
                                Boolean(formik.errors.optionSub)
                              }
                            />
                          )}
                          onChange={(e, value) => {
                            formik.setFieldValue("optionSub", value !== null ? value.id : formik.initialValues.optionSub);
                          }}
                        />
                        {formik.touched.optionSub && formik.errors.optionSub && (
                          <FormHelperText style={{ color: "red" }}>
                            {formik.errors.optionSub}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      id="report"
                      name="report"
                      options={doubleAnswerSelect}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Informe *"
                          variant="outlined"
                          error={
                            formik.touched.report &&
                            Boolean(formik.errors.report)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "report",
                          value !== null
                            ? value.id
                            : formik.initialValues.report
                        );
                      }}
                    />
                    {formik.touched.report && formik.errors.report && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.report}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item={true} xs={12} md={12}>
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      label="Descripción *"
                      type="text"
                      variant="outlined"
                      multiline
                      inputProps={{ maxLength: 1000 }}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                    />
                  </Grid>

                  <Grid item={true} xs={12} container>
                    <Grid item={true} xs={6} container>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        component={Link}
                        to={idPersona ? `/personas/ficha/${idPersona}` : "/casos/index"}
                      >
                        Volver
                      </Button>
                    </Grid>

                    <Grid
                      item={true}
                      xs={6}
                      justifyContent="flex-end"
                      container
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={25} color='inherit' /> : 'Crear'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default CreateCase;

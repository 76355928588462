import { IconButton, Switch } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { changeStatus } from "../../api/activityRecordRoster";

const ChangeActivityRosterPersonStatus = ({ status, id }) => {
  const [activityRosterPerson, setActivityRosterPerson] = useState(false);
  const [checkStatus, setCheckStatus] = useState(status === 0 ? true : false);

  const handleactivityRosterPerson = (checked) => {
    changeStatus(1, id, checked ? 0 : 1, setActivityRosterPerson);
  };

  useEffect(() => {
    if (activityRosterPerson) {
      setCheckStatus(!checkStatus);
      setActivityRosterPerson(false);
    }
  }, [activityRosterPerson]);

  return (
    <Switch
      color="primary"
      checked={checkStatus}
      onClick={(e) => {
        handleactivityRosterPerson(e.target.checked);
      }}
    />
  );
};

const DeleteRosterPerson = ({ id }) => {

  const [activityRosterPersonStatus, setActivityRosterPersonStatus] = useState(false);

  const deleteRosterPerson = () => {
    Swal.fire({
      title: "¿Eliminar persona?",
      text: "No será posible revertir esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#10a4da",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus(0, id, 0, setActivityRosterPersonStatus);
      }
    });
  };

  if (activityRosterPersonStatus) {
    window.location.reload();
  }

  return (
    <IconButton
      variant="contained"
      size="small"
      color="default"
      title="Borrar"
      onClick={() => deleteRosterPerson()}
    >
      <Delete></Delete>
    </IconButton>
  );
};

export { ChangeActivityRosterPersonStatus, DeleteRosterPerson };


import { isRole, roles } from "../config/roles";
import { downloadExcelFile } from "../util/downloadFiles";
import { getData } from "../util/localStorageService";
import { displayClientError, displaySuccessAlert } from "../util/swalMessages";
import apiInstance from "./apiInstance";

const subUrl = `/activity_record`;

const findAll = async (values, setActivityRegister) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.get(`${subUrl}/activities`, {
    params: {
      id_activity: values.activity,
      id_activity_channel: values.activityChannel,
      date: values.date,
      status: "",
      identifier: values.identifier,
      branch_office: values.client,
      id_account: account.id,
      id_user: isRole(user.description, 0, 2) || roles.slice(-1).includes(user.description) ? "" : user.id,
      id_activity_record_type: values.activityType,
      rut: values.rut,
      enterprise: values.enterprise
      // id_filter: Number.isInteger(values.user) ? values.user : 0,
    },
    headers: {
      "x-auth-token": user.authToken
    }
  }).then((response) => {
    setActivityRegister(response.data);
  });

}

const create = async (values, setRedirect, setIdActivityRecord, setLoading) => {

  const user = getData("user");
  await apiInstance.post(`${subUrl}/create`, {
    date: values.date,
    // time: values.time,
    id_activity: values.activity,
    id_activity_channel: values.activityChannel,
    id_activity_record_type: values.activityType,
    id_client: values.client,
    description: values.description,
    id_user: user.id
  },
    {
      headers: {
        "x-auth-token": user.authToken
      }
    }).then((response) => {
      setIdActivityRecord(response.data.data.id);
      setRedirect(true);
      displaySuccessAlert();
    }).catch(() => {
      setLoading(false);
    });

}

const changeStatus = async (type, id, value, setAccountStatus) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/change_status`,
    new URLSearchParams({
      id,
      type,
      value,
    }),
    {
      headers: {
        "x-auth-token": user.authToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then(() => {
    setAccountStatus(true);
  });

}

const findById = async (id, setActivityRecord) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/activity`,
    {
      id,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    if (!response.data) { setActivityRecord(null); displayClientError(); return }
    console.log(response.data);
    setActivityRecord(response.data);
  });

}

const update = async (id, values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/update`,
    {
      id,
      date: values.date,
      // time: values.time,
      id_activity: values.activity,
      id_activity_channel: values.activityChannel,
      id_activity_record_type: values.activityType,
      id_client: values.client,
      description: values.description,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then(() => {
    setRedirect(true);
    displaySuccessAlert();
  }).catch(() => {
    setLoading(false);
  });

}

const exportExcel = async (values) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.get(`${subUrl}/excel`,
    {
      responseType: "arraybuffer",
      params: {
        id_activity: values.activity,
        id_activity_channel: values.activityChannel,
        date: values.date,
        status: "",
        identifier: values.identifier,
        branch_office: values.client,
        id_account: account.id,
        id_user: isRole(user.description, 0, 2) || roles.slice(-1).includes(user.description) ? "" : user.id,
      },
      headers: {
        "x-auth-token": user.authToken
      }
    }).then((response) => {
      downloadExcelFile(response, "Reporte_registro_actividades");
    });

}

export { findAll, create, changeStatus, findById, update, exportExcel }

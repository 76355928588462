import { IconButton, Switch } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { changeStatus } from "../../api/account";
import { getData } from "../../util/localStorageService";

const ChangeAccountStatus = ({ status, accountId }) => {
  const [accountStatus, setAccountStatus] = useState(false);
  const [checkStatus, setcheckStatus] = useState(status === 0 ? true : false);
  
  const handleAccountStatus = (checked) => {
    
    const selectedAccount = getData("account");
    if (accountId === selectedAccount.id && !checked) {
      Swal.fire({
        title: "No puedes deshabilitar la cuenta que tienes seleccionada.",
        icon: "warning"
      });
      return;
    }

    changeStatus(1, accountId, checked ? 0 : 1, setAccountStatus);
  };

  useEffect(() => {
    if (accountStatus) {
      setcheckStatus(!checkStatus);
      setAccountStatus(false);
    }
  }, [accountStatus]);

  return (
    <Switch
      color="primary"
      checked={checkStatus}
      onClick={(e) => {
        handleAccountStatus(e.target.checked);
      }}
    />
  );
};

const DeleteAccount = ({ accountId }) => {

  const [accountStatus, setAccountStatus] = useState(false);
  
  const deleteAccount = () => {
    
    const selectedAccount = getData("account");
    if (accountId === selectedAccount.id) {
      Swal.fire({
        title: "No puedes eliminar la cuenta que tienes seleccionada.",
        icon: "warning"
      });
      return;
    }

    Swal.fire({
      title: "¿Eliminar cuenta?",
      text: "No será posible revertir esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#10a4da",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus(2, accountId, 0, setAccountStatus);
      }
    });
  };

  if (accountStatus) {
    window.location.reload();
  }

  return (
    <IconButton
      variant="contained"
      size="small"
      color="default"
      title="Borrar"
      onClick={() => deleteAccount()}
    >
      <Delete></Delete>
    </IconButton>
  );
};

export { ChangeAccountStatus, DeleteAccount };

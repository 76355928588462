import { Button, CircularProgress, Container, Grid, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect, useParams } from 'react-router-dom';
import { associate } from '../../api/activityRecordRoster';
import { activityRecordRosterFormValidationSchema } from '../../validations/activityRecordRosterValidations';
import { formatRut } from '../../validations/regexValidations';
import Section from '../Section';

const title = "Agregar persona a nómina";

function CreateActivityRecordRoster() {

  const { idRegistroActividad } = useParams();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState({ status: false, data: [] });

  const formik = useFormik({
    initialValues: {
      rut: ""
    },
    onSubmit: (values) => {
      setLoading(true);
      associate(idRegistroActividad, values, setRedirect, setLoading);
    },
    validationSchema: activityRecordRosterFormValidationSchema
  });

  if (redirect.status) {
    return <Redirect to={`/nomina-actividad/index/${idRegistroActividad}`} />
  }

  // if (redirect.status) {
  //   return <Redirect
  //     to={{
  //       pathname: `/nomina-actividad/resultado-carga/${idRegistroActividad}`,
  //       status: { data: redirect.data },
  //     }}
  //   />
  // }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>Los campos marcados con * son obligatorios.</Typography>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container={true} spacing={2}>

                  <Grid item={true} xs={12} md={12}>
                    <TextField
                      fullWidth
                      id="rut"
                      name="rut"
                      label="RUT *"
                      type="text"
                      variant="outlined"
                      onKeyUp={(event) => formik.setFieldValue("rut", formatRut(event.target.value))}
                      value={formik.values.rut}
                      onChange={formik.handleChange}
                      error={formik.touched.rut && Boolean(formik.errors.rut)}
                      helperText={formik.touched.rut && formik.errors.rut}
                    />
                  </Grid>

                  <Grid item={true} xs={6} container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      component={Link}
                      to={`/nomina-actividad/index/${idRegistroActividad}`}
                    >
                      Volver
                    </Button>
                  </Grid>

                  <Grid item={true} xs={6} justifyContent="flex-end" container>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={25} color='inherit' /> : 'Crear'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default CreateActivityRecordRoster
import { CircularProgress } from "@material-ui/core";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }

const customLocaleText ={
  noRowsLabel: <CircularProgress size={25} color='primary' />,
  errorOverlayDefaultLabel: "Ocurrió un error.",
  toolbarExport: "Exportar"
}

const componentPropsDataGrid = {
  pagination: {
    labelRowsPerPage: "Filas por página: "
  }
}

  export {CustomToolbar, customLocaleText, componentPropsDataGrid}
  
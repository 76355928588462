import { getData } from "../util/localStorageService";
import { displayClientError, displaySuccessAlert } from "../util/swalMessages";
import { upperCaseWords } from "../validations/regexValidations";
import apiInstance from "./apiInstance";

const subUrl = `/label`;

const findAll = async (setLabels, idPlace) => {

  const user = getData("user");
  await apiInstance.get(
    `${subUrl}/labels`,
    {
      params: {
        id_place: idPlace
      },
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    setLabels(response.data);
  });

}

const findAllFilter = async (values, setLabels) => {

  const user = getData("user");
  await apiInstance.get(
    `${subUrl}/labelsa`,
    {
      params: {
        name: values.name,
        id_place: values.place,
      },
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    setLabels(response.data);
  });

}

const create = async (values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/create`,
    {
      name: upperCaseWords(values.name),
      id_place: values.place,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then(() => {
    setRedirect(true);
    displaySuccessAlert();
  }).catch(() => {
    setLoading(false);
  });

}

const findById = async (id, setLabel) => {
  const user = getData("user");
  const request = await apiInstance.post(
    `${subUrl}/label`,
    {
      id,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    if (!response.data) { setLabel(null); displayClientError(); return }
    setLabel(response.data);
  });

}

const update = async (id, values, setRedirect, setLoading) => {
  
    const user = getData("user");
    await apiInstance.post(
      `${subUrl}/update`,
      {
        id,
        name: upperCaseWords(values.name),
        id_place: values.place,
      },
      {
        headers: {
          "x-auth-token": user.authToken,
        },
      }
    ).then(() => {
      setRedirect(true)
      displaySuccessAlert();
    }).catch(() => {
      setLoading(false);
    });

}

const changeStatus = async (type, id, value, setLabelStatus) => {

    const user = getData("user");
    await apiInstance.post(
      `${subUrl}/change_status`,
      new URLSearchParams({
        id,
        value,
        type,
      }),
      {
        headers: {
          "x-auth-token": user.authToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    ).then(() => {
      setLabelStatus(true);
    });

}

export { findAll, create, findAllFilter, findById, update, changeStatus }


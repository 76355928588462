import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Add, Delete, Remove } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { Autocomplete } from "@material-ui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { findAll, findAllFilter } from "../../api/label";
import { findAll as findAllPlaces } from "../../api/place";
import { componentPropsDataGrid, customLocaleText } from "../CustomToolbar";
import Section from "../Section";
import { ChangeLabelStatus, DeleteLabel } from "./LabelTableActions";
import { getData, saveData } from "../../util/localStorageService";
import { DeleteFilter } from "../DeleteFilter";

const title = "Etiquetas";
const columns = [
  {
    field: "acciones",
    headerName: "Acciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <>
          <IconButton
            component={Link}
            to={"/etiquetas/" + params.row.id}
            variant="contained"
            size="small"
            color="secondary"
            title="Editar"
          >
            <EditIcon></EditIcon>
          </IconButton>
          <DeleteLabel labelId={params.row.id} />
        </>
      );
    },
  },
  {
    field: "habilitado",
    headerName: "Habilitado",
    width: 100,
    align: "center",
    headerAlign: "center",
    sortable: false,
    renderCell: (params) => {
      return <ChangeLabelStatus labelId={params.row.id} status={params.row.status} />;
    },
  },
  {
    field: "name",
    headerName: "Nombre",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "place",
    headerName: "Lugar de uso",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.place?.description}</div>
    }
  },
  {
    field: "created_at",
    headerName: "Fecha registro",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params?.value).format("DD/MM/YYYY hh:mm")
  },
  {
    field: "updated_at",
    headerName: "Fecha modificación",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params?.value).format("DD/MM/YYYY hh:mm")
  },
];

function IndexLabel() {

  const filtersName = "labelFilters";

  const [filtro, setFiltro] = useState(false);
  const [pageSize, setPageSize] = React.useState(20);
  const [savedFilters, setSavedFilters] = useState(getData(filtersName));

  const [labels, setLabels] = useState([]);
  const [places, setPlaces] = useState([]);

  const saveFilters = (values) => {
    saveData(filtersName, JSON.stringify(values));
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: savedFilters?.name ?? "",
      place: savedFilters?.place ?? ""
    },
    onSubmit: (values) => {
      saveFilters(values);
      findAllFilter(values, setLabels);
    }
  });

  useEffect(() => {
    findAllFilter(formik.initialValues, setLabels);
    findAllPlaces(setPlaces);
  }, []);

  const handleFiltro = () => {
    setFiltro(!filtro);
  };

  useEffect(() => {
    if (!savedFilters) formik.resetForm();
  }, [savedFilters])

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container={true} spacing={4}>
            <Grid item lg={12} md={12} xs={12} xl={12} container>
              <Grid item xs={6} md={6} lg={6}>
                <Typography variant="h4">
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6} container justifyContent="flex-end">
                <Button
                  component={Link}
                  to="/etiquetas/crear"
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Crear
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <Chip
                avatar={<Avatar>{filtro ? <Remove /> : <Add />}</Avatar>}
                label={filtro ? "Ocultar filtros" : "Mostrar filtros"}
                onClick={handleFiltro}
                variant="outlined"
                style={{ fontSize: "18px" }}
              />
            </Grid>
            {filtro && (
              <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined">
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container={true} spacing={2} alignItems="center">
                        <Grid item={true} xs={12} md={6}>
                          <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Nombre"
                            type="text"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                          <Autocomplete
                            disablePortal
                            id="place"
                            name="place"
                            options={places}
                            getOptionLabel={(option) => option.description}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Lugar"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "place",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.place
                              );
                            }}
                          />
                        </Grid>

                        <Grid item={true} xs={12} md={10}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                          >
                            Filtrar
                          </Button>
                        </Grid>
                        <Grid item={true} xs={12} md={2}>
                          <DeleteFilter filtersName={filtersName} setSavedFilters={setSavedFilters} formik={formik} />
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item={true} xs={12} md={12}>
              <div style={{ height: 560, width: "100%" }}>
                <DataGrid
                  rows={labels}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                  disableColumnMenu
                  localeText={customLocaleText}
                  componentsProps={componentPropsDataGrid}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default IndexLabel;

import { getData } from '../util/localStorageService';
import apiInstance from './apiInstance';

const subUrl = `/tool`;

const findAll = async (id, setTools) => {

    const user = getData("user");
    await apiInstance.post(`${subUrl}/tools`, {
        id
    },
        {
            headers: {
                "x-auth-token": user.authToken
            }
        }).then((response) => {

            setTools(response.data);
        });


}

export { findAll }

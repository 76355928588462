import React from "react";

function NotFoundPage(props) {
  return (
    <>
      <div
        style={{
          padding: "50px",
          width: "100%",
          textAlign: "center",
        }}
      >
        La pagina buscada no existe.
      </div>
    </>
  );
}

export default NotFoundPage;

import { getData } from "../util/localStorageService";
import apiInstance from './apiInstance';

const subUrl = `/support`;

const findAll = async (setSupports) => {

    const user = getData("user");
    const account = getData("account");
    await apiInstance.get(`${subUrl}/supports`, {
        params: {
            id_account: account.id
        },
        headers: {
            "x-auth-token": user.authToken
        }
    }).then((response) => {
        setSupports(response.data);
    });

}

export { findAll }

import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl, FormHelperText,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select, TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect, useParams } from "react-router-dom";
import { findAll as findAllAccount } from "../../api/account";
import { findById, update } from "../../api/client";
import { findAll as findAllLabels } from "../../api/label";
import { findAll, findAllCommunes, findAllProvinces } from "../../api/region";
import { findAll as findAllZones } from "../../api/zone";
import { clientFormValidationSchema } from "../../validations/clientValidations";
import Section from "../Section";

const title = "Editar sucursal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditClient() {
  const { idCliente } = useParams();

  const [client, setClient] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [accounts, setAccounts] = useState([]);
  const [zones, setZones] = useState([]);
  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [labels, setLabels] = useState([]);

  let labelsIds = [];

  useEffect(() => {
    findById(idCliente, setClient);
  }, []);

  useEffect(() => {
    findAllLabels(setLabels, 1);
  }, []);

  // useEffect(() => {
  //   findAllAccount(null, setAccounts);
  // }, []);

  useEffect(() => {
    findAllZones(setZones);
  }, []);

  useEffect(() => {
    findAll(setRegions);
  }, []);

  useEffect(() => {
    let regionId = client?.commune?.province?.region?.id;
    if (regionId) {
      findAllProvinces(regionId, setProvinces);
    }
  }, [client])

  useEffect(() => {
    let provinceId = client?.commune?.province?.id;
    if (provinceId) {
      findAllCommunes(provinceId, setCommunes);
    }
  }, [client])

  if (client !== []) {
    client?.clientLabels?.map((label) => labelsIds.push(label.id))
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      identifier: client?.identifier ?? "",
      enterprise: client?.enterprise ?? "",
      division: client?.division ?? "",
      branchOffice: client?.branch_office ?? "",
      zone: client?.id_zone ?? "",
      account: client?.id_account ?? "",
      region: client?.commune?.province?.region?.id ?? "",
      province: client?.commune?.province?.id ?? "",
      commune: client?.commune?.id ?? "",
      labels: labelsIds
    },
    onSubmit: (values) => {
      setLoading(true);
      update(idCliente, values, setRedirect, setLoading);
    },
    validationSchema: clientFormValidationSchema,
  });

  const handleProvincesChange = (value) => {
    if (value !== null && value > 0) {
      findAllProvinces(value, setProvinces);
    } else {
      setProvinces([]);
    }
  }

  const handleCommuneChange = (value) => {
    if (value !== null && value > 0) {
      findAllCommunes(value, setCommunes);
    } else {
      setCommunes([]);
    }
  }

  if (client === null) {
    return <Redirect to="/clientes/index" />
  }

  if (redirect) {
    return <Redirect to="/clientes/index" />;
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">{title}</Typography>
              <Typography style={{ fontSize: "15px" }}>
                Los campos marcados con * son obligatorios.
              </Typography>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="identifier"
                      name="identifier"
                      label="Identificador *"
                      type="text"
                      disabled
                      variant="outlined"
                      value={formik.values.identifier}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.identifier &&
                        Boolean(formik.errors.identifier)
                      }
                      helperText={
                        formik.touched.identifier && formik.errors.identifier
                      }
                    />
                  </Grid>
                  {/* <Grid item={true} xs={12} md={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      id="account"
                      name="account"
                      options={accounts}
                      value={{
                        id: formik.values.account ?? "",
                        name: accounts.find((account) => account.id === formik.values.account)?.name ?? "",
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cuenta *"
                          variant="outlined"
                          error={
                            formik.touched.account &&
                            Boolean(formik.errors.account)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "account",
                          value !== null
                            ? value.id
                            : formik.initialValues.account
                        );
                      }}
                    />
                    {formik.touched.account && formik.errors.account && (
                      <FormHelperText style={{ color: "red" }}>
                        Campo requerido
                      </FormHelperText>
                    )}
                  </Grid> */}
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="enterprise"
                      name="enterprise"
                      label="Empresa *"
                      type="text"
                      variant="outlined"
                      value={formik.values.enterprise}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.enterprise &&
                        Boolean(formik.errors.enterprise)
                      }
                      helperText={
                        formik.touched.enterprise && formik.errors.enterprise
                      }
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="division"
                      name="division"
                      label="División *"
                      type="text"
                      variant="outlined"
                      value={formik.values.division}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.division &&
                        Boolean(formik.errors.division)
                      }
                      helperText={
                        formik.touched.division && formik.errors.division
                      }
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      id="zone"
                      name="zone"
                      options={zones}
                      getOptionLabel={(option) => option.description || ""}
                      value={{
                        id: formik.values.zone ?? "",
                        description: zones.find((zone) => zone.id === formik.values.zone)?.description ?? "",
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Zonas *"
                          variant="outlined"
                          error={
                            formik.touched.zone && Boolean(formik.errors.zone)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          "zone",
                          value !== null ? value.id : formik.initialValues.zone
                        );
                      }}
                    />
                    {formik.touched.zone && formik.errors.zone && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.zone}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="branchOffice"
                      name="branchOffice"
                      label="Nombre sucursal *"
                      type="text"
                      variant="outlined"
                      value={formik.values.branchOffice}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.branchOffice &&
                        Boolean(formik.errors.branchOffice)
                      }
                      helperText={
                        formik.touched.branchOffice &&
                        formik.errors.branchOffice
                      }
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      id="region"
                      name="region"
                      options={regions}
                      getOptionLabel={(option) => option.region || ""}
                      value={{
                        id: formik.values.region ?? "",
                        region: regions.find((region) => region.id === formik.values.region)?.region ?? ""
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Región *"
                          variant="outlined"
                          error={
                            formik.touched.region &&
                            Boolean(formik.errors.region)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        let regionId = value !== null ? value.id : formik.initialValues.region
                        formik.setFieldValue("region", regionId);
                        formik.setFieldValue("province", "");
                        formik.setFieldValue("commune", "");
                        handleProvincesChange(regionId);
                      }}
                    />
                    {formik.touched.region && formik.errors.region && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.region}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item={true} xs={12} md={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      id="province"
                      name="province"
                      options={provinces}
                      getOptionLabel={(option) => option.provincia || ""}
                      value={{
                        id: formik.values.province ?? "",
                        provincia: provinces.find((province) => province.id === formik.values.province)?.provincia ?? ""
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Provincia *"
                          variant="outlined"
                          error={
                            formik.touched.province &&
                            Boolean(formik.errors.province)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        let provinceId = value !== null ? value.id : formik.initialValues.province;
                        formik.setFieldValue("province", provinceId);
                        formik.setFieldValue("commune", "");
                        handleCommuneChange(provinceId);

                      }}
                    />
                    {formik.touched.province && formik.errors.province && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.province}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item={true} xs={12} md={6} lg={6}>
                    <Autocomplete
                      disablePortal
                      id="commune"
                      name="commune"
                      options={communes}
                      getOptionLabel={(option) => option.comuna}
                      value={{
                        id: formik.values.commune ?? "",
                        comuna: communes.find((commune) => commune.id === formik.values.commune)?.comuna ?? ""
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Comuna *"
                          variant="outlined"
                          error={
                            formik.touched.commune &&
                            Boolean(formik.errors.commune)
                          }
                        />
                      )}
                      onChange={(e, value) => {
                        let communeId = value !== null ? value.id : formik.initialValues.commune;
                        formik.setFieldValue("commune", communeId);

                      }}
                    />
                    {formik.touched.commune && formik.errors.commune && (
                      <FormHelperText style={{ color: "red" }}>
                        {formik.errors.commune}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item={true} xs={12} md={6} lg={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="labls">Etiquetas</InputLabel>
                      <Select
                        labelId="accounts-label"
                        id="labels"
                        name="labels"
                        multiple
                        value={formik.values.labels}
                        onChange={formik.handleChange}
                        // error={
                        //   formik.touched.labels && Boolean(formik.errors.labels)
                        // }
                        input={<Input />}
                        MenuProps={MenuProps}
                        renderValue={(selected) =>
                          labels
                            .filter((name) => selected.includes(name.id))
                            .map((record) => record.name)
                            .join(", ")
                        }
                      >
                        {labels.map((label) => (
                          <MenuItem key={label.id} value={label.id}>
                            <Checkbox
                              checked={
                                formik.values.labels.indexOf(label.id) > -1
                              }
                            />
                            <ListItemText primary={label.name} />
                          </MenuItem>
                        ))}
                      </Select>
                      {/* {formik.touched.labels && formik.errors.labels && (
                        <FormHelperText style={{ color: "red" }}>
                          Campo requerido
                        </FormHelperText>
                      )} */}
                    </FormControl>
                  </Grid>

                  <Grid item lg={12} md={12} xs={12} xl={12} container>
                    <Grid item lg={6} md={6} xs={6} xl={6} container>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        component={Link}
                        to="/clientes/index"
                      >
                        Volver
                      </Button>
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={6}
                      xl={6}
                      justifyContent="flex-end"
                      container
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <CircularProgress size={25} color='inherit' /> : 'Guardar'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default EditClient;

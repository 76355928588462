import * as yup from 'yup';
import { passOrActualDateValidationMessage, requiredMessage } from "./validationMessages";

const caseAndAttentionFormValidationSchema = yup.object({

    person: yup.number().required(requiredMessage),
    subject: yup.string().trim().required(requiredMessage),
    problem: yup.number().required(requiredMessage),
    subProblem: yup.number().required(requiredMessage),
    confidential: yup.number().required(requiredMessage),
    complexity: yup.number().required(requiredMessage),
    caseStatus: yup.number().required(requiredMessage),
    // closeDate: yup.string().trim().required(requiredMessage),

    attentionDate: yup.string().trim().required(requiredMessage).test('is-valid-date', passOrActualDateValidationMessage, (value) => !value || new Date(value) <= new Date()),
    channel: yup.number().required(requiredMessage),
    channelOption: yup.number().required(requiredMessage),
    support: yup.number().required(requiredMessage),
    tool: yup.number().required(requiredMessage),
    optionSub: yup.number().required(requiredMessage),
    report: yup.number().required(requiredMessage),
    description: yup.string().trim().required(requiredMessage)

});

const caseFormValidationSchema = yup.object({
    person: yup.number().required(requiredMessage),
    subject: yup.string().trim().required(requiredMessage),
    problem: yup.number().required(requiredMessage),
    subProblem: yup.number().required(requiredMessage),
    confidential: yup.number().required(requiredMessage),
    complexity: yup.number().required(requiredMessage),
    caseStatus: yup.number().required(requiredMessage)
});

export { caseAndAttentionFormValidationSchema, caseFormValidationSchema }
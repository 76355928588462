import * as yup from 'yup';
import { passOrActualDateValidationMessage, requiredMessage } from "./validationMessages";

const attentionFormValidationSchema = yup.object({
    attentionDate: yup.string().trim().required(requiredMessage)
        .test('is-valid-date', passOrActualDateValidationMessage, (value) => !value || new Date(value) <= new Date()),
    channel: yup.number().required(requiredMessage),
    channelOption: yup.number().required(requiredMessage),
    support: yup.number().required(requiredMessage),
    tool: yup.number().required(requiredMessage),
    optionSub: yup.number().required(requiredMessage),
    report: yup.number().required(requiredMessage),
    description: yup.string().trim().required(requiredMessage)
});

export { attentionFormValidationSchema }
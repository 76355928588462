import * as yup from "yup";
import { nineDigitsRegEx, rutRegEx } from "./regexValidations";
import { requiredMessage } from "./validationMessages";

const personFormValidationSchema = yup.object({
    rut: yup.string().trim().matches(rutRegEx, "RUT inválido").required(requiredMessage),
    name: yup.string().trim().required(requiredMessage),
    // email: yup.string().trim().required(requiredMessage),
    // phone: yup.string().trim().matches(nineDigitsRegEx, "Must be only digits").min(9, "Debe tener 9 dígitos").max(9, "Debe tener 9 dígitos").required(requiredMessage),
    client: yup.number().required(requiredMessage)
});

export { personFormValidationSchema }
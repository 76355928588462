import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import Section from "../Section";

import { Add, Delete, Remove } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { Autocomplete } from "@material-ui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment/moment";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { exportExcel, findAll, findAllRoles } from "../../api/user";
import { ChangeUserStatus, DeleteUser } from "./UserTableActions";
import { componentPropsDataGrid, customLocaleText } from "../CustomToolbar";
import { getData, saveData } from "../../util/localStorageService";
import { DeleteFilter } from "../DeleteFilter";

const columns = [
  {
    field: "acciones",
    headerName: "Acciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <>
          <IconButton
            component={Link}
            to={`/usuarios/${params.row.id}`}
            variant="contained"
            size="small"
            color="secondary"
            title="Editar"
          >
            <EditIcon></EditIcon>
          </IconButton>
          <DeleteUser userId={params.row.id} />
        </>
      );
    },
  },
  {
    field: "habilitado",
    headerName: "Habilitado",
    width: 100,
    sortable: false,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <ChangeUserStatus status={params.row.status} userId={params.row.id} />
      );
    },
  },

  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "name",
    headerName: "Nombre",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "lastname",
    headerName: "Apellido",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "email",
    headerName: "Correo electrónico",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "note",
    headerName: "Nota",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "description",
    headerName: "Rol",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.type.description}</div>;
    },
  },
  {
    field: "created_at",
    headerName: "Fecha registro",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
  {
    field: "updated_at",
    headerName: "Fecha modificación",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
];

const title = "Usuarios";

function IndexUser() {

  const filtersName = "userFilters";

  const [pageSize, setPageSize] = useState(20);
  const [roles, setRoles] = useState([]);
  const [filtro, setFiltro] = useState(false);
  const [users, setUsers] = useState([]);
  const [savedFilters, setSavedFilters] = useState(getData(filtersName));

  const handleFiltro = () => {
    setFiltro(!filtro);
  };

  const saveFilters = (values) => {
    saveData(filtersName, JSON.stringify(values));
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: savedFilters?.name ?? "",
      lastname: savedFilters?.lastname ?? "",
      phone: savedFilters?.phone ?? "",
      note: savedFilters?.note ?? "",
      email: savedFilters?.email ?? "",
      role: savedFilters?.role ?? "",
    },
    onSubmit: (values) => {
      saveFilters(values);
      findAll(values, setUsers);
    },
  });

  const handleExport = () => {
    exportExcel(formik.values);
  };

  useEffect(() => {
    findAll(formik.initialValues, setUsers);
    findAllRoles(setRoles);
  }, []);

  useEffect(() => {
    if (!savedFilters) formik.resetForm();
  }, [savedFilters])

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container={true} spacing={4}>
            <Grid item lg={12} md={12} xs={12} xl={12} container>
              <Grid item xs={6} md={6} lg={6}>
                <Typography variant="h4">
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6} container justifyContent="flex-end">
                <Button
                  component={Link}
                  to="/usuarios/crear"
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Crear
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <Chip
                avatar={<Avatar>{filtro ? <Remove /> : <Add />}</Avatar>}
                label={filtro ? "Ocultar filtros" : "Mostrar filtros"}
                onClick={handleFiltro}
                variant="outlined"
                style={{ fontSize: "18px" }}
              />
            </Grid>
            {filtro && (
              <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined">
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container={true} spacing={2} alignItems="center">
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="name"
                            label="Nombre"
                            type="text"
                            name="name"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="name"
                            name="lastname"
                            label="Apellido"
                            type="text"
                            variant="outlined"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Correo electrónico"
                            type="text"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="phone"
                            name="phone"
                            label="Teléfono"
                            type="text"
                            variant="outlined"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="note"
                            name="note"
                            label="Nota"
                            type="text"
                            variant="outlined"
                            value={formik.values.note}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <Autocomplete
                            disablePortal
                            id="rol"
                            name="rol"
                            value={{
                              id: formik.values.role,
                              description: roles.find(role => role.id === formik.values.role)?.description ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            options={roles}
                            getOptionLabel={(option) => option.description}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Rol"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "role",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.role
                              );
                            }}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={10}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                          >
                            Filtrar
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={handleExport}
                            style={{ marginLeft: "2%" }}
                          >
                            Exportar
                          </Button>
                        </Grid>
                        <Grid item={true} xs={12} md={2}>
                          <DeleteFilter filtersName={filtersName} setSavedFilters={setSavedFilters} formik={formik} />
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item={true} xs={12} md={12}>
              <div style={{ height: 515, width: "100%" }}>
                <DataGrid
                  rows={users}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                  disableColumnMenu
                  localeText={customLocaleText}
                  componentsProps={componentPropsDataGrid}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default IndexUser;

import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { useFormik } from "formik";
import queryString from "query-string";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect, useLocation } from "react-router-dom";
import { resetPass } from "../../api/auth";
import { doublePassValidation } from "../../validations/authValidations";
import Section from "../Section";

const title = "Nueva contraseña";


function ResetPass() {

  const { search } = useLocation();
  const { token, type } = queryString.parse(search);
  const [redirect, setRedirect] = useState(false);

  const formik = useFormik({
    initialValues: {
      newPass: "",
      repeatNewPass: "",
    },
    onSubmit: (values) => {
      resetPass(token, values, setRedirect, type);
    },
    validationSchema: doublePassValidation,
  });

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Helmet title={title} />
      <Section
        bgImage="/fondoRAS.png"
        size="medium"
        // bgColor="transparent-blue"
        bgImageOpacity={0.8}
      >
        <Container maxWidth="sm">
          {/* <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
            <img src="https://probenefit.cl/wp-content/uploads/2020/07/Logo-probenefit-horizontal-fondo-blanco-300px.png" />
          </Grid> */}
          <Card >
            <CardContent>
              <Grid container={true} spacing={4}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h4" style={{ textAlign: "center" }}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item={true} xs={12} md={12}>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container={true} spacing={2}>
                      <Grid item={true} xs={12} md={12}>
                        <TextField
                          fullWidth
                          id="newPass"
                          name="newPass"
                          label="Nueva contraseña"
                          type="password"
                          variant="outlined"
                          value={formik.values.newPass}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.newPass &&
                            Boolean(formik.errors.newPass)
                          }
                          helperText={
                            formik.touched.newPass && formik.errors.newPass
                          }
                        />
                      </Grid>
                      <Grid item={true} xs={12} md={12}>
                        <TextField
                          fullWidth
                          id="repeatNewPass"
                          name="repeatNewPass"
                          label="Repetir nueva contraseña"
                          type="password"
                          variant="outlined"
                          value={formik.values.repeatNewPass}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.repeatNewPass &&
                            Boolean(formik.errors.repeatNewPass)
                          }
                          helperText={
                            formik.touched.repeatNewPass &&
                            formik.errors.repeatNewPass
                          }
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          type="submit"
                          fullWidth
                        >
                          Confirmar
                        </Button>
                      </Grid>
                      {/* {errorMsj && (
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography style={{ textAlign: "center", color: 'red' }}>
                        Las contraseñas deben coincidir
                      </Typography>
                    </Grid>
                  )} */}
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Section>
    </>
  );
}

export default ResetPass;

import { isRole } from "../config/roles";
import { downloadExcelFile } from "../util/downloadFiles";
import { getData } from "../util/localStorageService";
import { displayClientError, displaySuccessAlert } from "../util/swalMessages";
import { upperCaseWords } from "../validations/regexValidations";
import apiInstance from "./apiInstance";

const subUrl = `/user`;

const findAllActive = async (setUsers) => {

  const user = getData("user");
  await apiInstance.get(`${subUrl}/list`, {
    headers: {
      "x-auth-token": user.authToken,
    }
  }).then((response) => {
    setUsers(response.data);
  });

}

const findAll = async (values, setUsers) => {

  const user = getData("user");
  await apiInstance.get(
    `${subUrl}/users`,
    {
      params: {
        name: values.name,
        lastname: values.lastname,
        phone: values.phone,
        note: values.note,
        email: values.email,
        id_type: values.role,
      },
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    setUsers(response.data);
  });

}

const findHistoric = async (values, setHistoric) => {
  const user = getData("user");
  const account = getData("account");

  await apiInstance.get(
    `${subUrl}/historic`,
    {
      params: {
        rut: values.rut,
        id_account: account.id
      },
      headers: {
        "x-auth-token": user.authToken
      }
    }
  ).then((response) => {
    console.log(response)
    setHistoric(response.data.data);
  })
}

const save = async (values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/create`,
    {
      name: upperCaseWords(values.name),
      lastname: upperCaseWords(values.lastname),
      phone: values.phone,
      note: values.note,
      email: values.email,
      id_type: values.role,
      rut: values.rut,
      accounts: values.isAdmin ? [] : values.accounts,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    displaySuccessAlert(response);
    setRedirect(true);
  }).catch(() => {
    setLoading(false);
  });

}

const changeStatus = async (type, id, value, setUserStatus) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/change_status`,
    new URLSearchParams({
      id,
      type,
      value,
    }),
    {
      headers: {
        "x-auth-token": user.authToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then(() => {
    setUserStatus(true);
  });

}

const findById = async (id, setUser) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/user`,
    {
      id,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    if (!response.data.data) { setUser(null); displayClientError(); return }
    setUser(response.data.data);
  });

}

const update = async (id, values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/update`,
    {
      id,
      name: upperCaseWords(values.name),
      lastname: upperCaseWords(values.lastname),
      phone: values.phone,
      note: values.note,
      email: values.email,
      id_type: values.role,
      rut: values.rut,
      accounts: values.isAdmin ? [] : values.accounts,
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then(() => {
    setRedirect(true);
    displaySuccessAlert();
  }).catch(() => {
    setLoading(false);
  });

}

const exportExcel = async (values) => {

  const user = getData("user");
  await apiInstance.get(
    `${subUrl}/excel`,
    {
      responseType: "arraybuffer",
      params: {
        name: values.name,
        lastname: values.lastname,
        phone: values.phone,
        note: values.note,
        email: values.email,
        id_type: values.role,
      },
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    downloadExcelFile(response, "Reporte_usuarios");
  });

}

const findAllRoles = async (setRoles) => {

  const user = getData("user");
  await apiInstance.get(
    `${process.env.REACT_APP_BASE_URL}/roles/`,
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    setRoles(response.data);
  });

}

const findUserAccounts = async (setUserAccounts) => {

  const user = getData("user");
  await apiInstance.post(`${subUrl}/usercase`,
    {
      id: isRole(user.description, 0, 1) ? "0" : user.id
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      }
    }
  ).then((response) => {
    setUserAccounts(response.data.data);
  });

}

const findUserAccountsNoState = async (setUserAccounts) => {

  const user = getData("user");
  await apiInstance.post(`${subUrl}/usercase`,
    {
      id: isRole(user.description, 0, 1) ? "0" : user.id
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      }
    }
  ).then((response) => {
    setUserAccounts = response.data.data;
  });

}

export {
  findAllActive,
  findAll,
  save,
  findAllRoles,
  changeStatus,
  findById,
  update,
  exportExcel,
  findUserAccounts,
  findUserAccountsNoState,
  findHistoric
}


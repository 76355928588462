import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid, IconButton, Menu,
  TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Add, Delete, Remove } from "@material-ui/icons";
import { MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { findById } from "../../api/activityRecord";
import { exportExcel, findAll, masiveUpload } from "../../api/activityRecordRoster";
import { roles } from "../../config/roles";
import AuthContext from "../../context/AuthProvider";
import { displayLoading } from "../../util/swalMessages";
import { formatRut } from "../../validations/regexValidations";
import { componentPropsDataGrid, customLocaleText } from "../CustomToolbar";
import Section from "../Section";
import { DeleteRosterPerson } from "./ActivityRecordRosterTableActions";
import { getData, saveData } from "../../util/localStorageService";
import { DeleteFilter } from "../DeleteFilter";

const title = "Nómina de participantes";
const canSeeActions = roles.slice(0, 3);

const columns = [
  {
    field: "acciones",
    headerName: "Eliminar",
    width: 70,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <DeleteRosterPerson id={params.row.id} />
    },
  },
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div> {params.row.person.rut} </div>
    }
  },
  {
    field: "name",
    headerName: "Nombres",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div> {params.row.person.name} </div>
    }
  },
  {
    field: "email",
    headerName: "Correo electrónico",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div> {params.row.person.email} </div>
    }
  },
  {
    field: "client",
    headerName: "Sucursal",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div> {params.row.person.client.branch_office} </div>
    }
  },
  {
    field: "created_at",
    headerName: "Fecha registro",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
  {
    field: "updated_at",
    headerName: "Fecha modificación",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
];

function IndexActivityRecordRoster() {

  const { idRegistroActividad } = useParams();

  const filtersName = "activityRecordRosterFilters";
  const { role } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filtro, setFiltro] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [redirect, setRedirect] = useState({ status: false, data: [], errors: [] });

  const [activityRecordRoster, setActivityRecordRoster] = useState([]);
  const [activityRecord, setActivityRecord] = useState([]);
  const [savedFilters, setSavedFilters] = useState(getData(filtersName));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFiltro = () => {
    setFiltro(!filtro);
  };

  const saveFilters = (values) => {
    saveData(filtersName, JSON.stringify(values));
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rut: savedFilters?.rut ?? "",
      name: savedFilters?.name ?? "",
      email: savedFilters?.email ?? "",
      client: savedFilters?.client ?? ""
    },
    onSubmit: (values) => {
      saveFilters(values);
      findAll(idRegistroActividad, values, setActivityRecordRoster);
    }
  });

  useEffect(() => {
    findAll(idRegistroActividad, formik.initialValues, setActivityRecordRoster);
    findById(idRegistroActividad, setActivityRecord);
  }, []);

  const handleExport = () => {
    exportExcel(idRegistroActividad, formik.values);
  }

  const columnVisibilityModel = {
    acciones: role === roles[0]
  }

  function subirExcel(file) {
    Swal.fire({
      title: "¿Confirmas la carga de la nómina?",
      text: `${file.name}`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#10a4da",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, subir",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        masiveUpload(idRegistroActividad, file, setRedirect, setLoading);
      }
    });
  }

  useEffect(() => {
    if (loading) { displayLoading() } else { Swal.close() };
  }, [loading]);

  useEffect(() => {
    if (!savedFilters) formik.resetForm();
  }, [savedFilters]);

  if (redirect.status) {
    return <Redirect
      to={{
        pathname: `/nomina-actividad/resultado-carga/${idRegistroActividad}`,
        status: { data: redirect },
      }}
    />
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container={true} spacing={4}>
            <Grid item lg={12} md={12} xs={12} xl={12} container>
              <Grid item sm={12} lg={8}>
                <Typography variant="h4" display="inline" > {title} - </Typography>
                <Typography variant="h4" display="inline" style={{ fontWeight: "bold" }}>{activityRecord.activity?.name ?? ""}</Typography>
              </Grid>
              {
                roles.slice(0, 3).includes(role) &&
                <Grid item sm={12} lg={4} container justifyContent="flex-end" >
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="secondary"
                    variant="outlined"
                  >
                    Opciones
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <input
                        style={{ display: "none" }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => {
                          subirExcel(e.target.files[0]);
                          e.target.value = null
                        }}
                      />
                      <label
                        htmlFor="contained-button-file"
                        style={{ cursor: "pointer" }}
                      >
                        Importar Excel
                      </label>
                    </MenuItem>
                    <MenuItem component={Link} to={`/nomina-actividad/crear/${idRegistroActividad}`}>
                      Agregar participante
                    </MenuItem>
                  </Menu>
                </Grid>
              }
              <Grid item lg={12} md={12} xs={12} xl={12} container>
                <Typography variant="h4"></Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Chip
                avatar={<Avatar>{filtro ? <Remove /> : <Add />}</Avatar>}
                label={filtro ? "Ocultar filtros" : "Mostrar filtros"}
                onClick={handleFiltro}
                variant="outlined"
                style={{ fontSize: "18px" }}
              />
            </Grid>
            {filtro && (
              <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined">
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container={true} spacing={2} alignItems="center">
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="rut"
                            name="rut"
                            label="RUT"
                            type="text"
                            variant="outlined"
                            value={formik.values.rut}
                            onChange={formik.handleChange}
                            onKeyUp={(event) => formik.setFieldValue("rut", formatRut(event.target.value))}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Nombre"
                            type="text"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Correo electrónico"
                            type="text"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="client"
                            name="client"
                            label="Sucursal"
                            type="text"
                            variant="outlined"
                            value={formik.values.client}
                            onChange={formik.handleChange}
                          />
                        </Grid>

                        <Grid item={true} xs={12} md={10}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                          >
                            Filtrar
                          </Button>
                          {
                            canSeeActions.includes(role) &&
                            <Button
                              variant="contained"
                              color="secondary"
                              size="large"
                              onClick={handleExport}
                              style={{ marginLeft: "2%" }}
                            >
                              Exportar
                            </Button>
                          }
                        </Grid>
                        <Grid item={true} xs={12} md={2}>
                          <DeleteFilter filtersName={filtersName} setSavedFilters={setSavedFilters} formik={formik} />
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item={true} xs={12} md={12}>
              <div style={{ height: 515, width: "100%" }}>
                <DataGrid
                  rows={activityRecordRoster}
                  columns={columns}
                  pageSize={pageSize}
                  columnVisibilityModel={columnVisibilityModel}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                  disableColumnMenu
                  localeText={customLocaleText}
                  componentsProps={componentPropsDataGrid}
                />
              </div>
            </Grid>
            <Grid item={true} xs={6} container>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/registro-actividad/index"
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default IndexActivityRecordRoster;

import { Button, Container, Grid, Typography } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { componentPropsDataGrid, customLocaleText, CustomToolbar } from "../CustomToolbar";
import Section from "../Section";

const title = "Detalle de carga de actividades";
const colors = {
  0: {
    color: "#F31C4A",
    txt: "No ingresado",
  },
  1: {
    color: "#58DE56",
    txt: "Ingresado",
  },
  2: {
    color: "#E8B11C",
    txt: "Actualizado",
  },
  3: {
    color: "black",
    txt: "???",
  },
};

const columns = [
  {
    field: "identifier",
    headerName: "Identificador",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "name",
    headerName: "Nombre",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "description",
    headerName: "Descripción",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Estado",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      let { status } = params.row;
      return (
        <div style={{ color: colors[status].color }}> {colors[status].txt} </div>
      );
    },
  },
];

const errorsColumns = [

  {
    field: "linea",
    headerName: "Linea excel",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "error",
    headerName: "Dato incorrecto",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div style={{ color: 'red' }}>{params.row.error}</div>
      );
    },
  },
]


function IndexActivitiesExcel(props) {
  const [pageSize, setPageSize] = useState(20);

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={10} lg={10} xl={10}>
              <Typography variant="h4">{title}</Typography>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <div style={{ height: 515, width: "100%" }}>
                <DataGrid
                  rows={props.location.status.data.data}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  getRowId={() => Math.random()}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                  disableColumnMenu
                  localeText={customLocaleText}
                  componentsProps={componentPropsDataGrid}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                />
              </div>
            </Grid>
            {
              props.location.status.data.errors.length > 0 &&
              <>
                <Grid item xs={12} md={10} lg={10} xl={10}>
                  <Typography variant="h4">Detalle de inconsistencias</Typography>
                </Grid>


                <Grid item={true} xs={12} md={12}>
                  <div style={{ height: 300, width: "100%" }}>
                    <DataGrid
                      rows={props.location.status.data.errors}
                      columns={errorsColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[5, 10, 15, 20]}
                      getRowId={() => Math.random()}
                      disableSelectionOnClick
                      componentsProps={componentPropsDataGrid}
                      localeText={customLocaleText}
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                    />
                  </div>
                </Grid>
              </>
            }
            <Grid item xs={12} md={12} lg={12}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={"/actividades/index"}
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default IndexActivitiesExcel;

import Swal from "sweetalert2";
import { saveData } from "./localStorageService";

const displayAlert = (title = "Error", text = null, icon, setRedirect) => {
  Swal.fire({
    title,
    text,
    icon,
    allowOutsideClick: false,
    allowEscapeKey: false,
    confirmButtonText: "Cerrar",
  }).then((result) => {
    if (result.isConfirmed && setRedirect) {
      setRedirect(true);
    }
  });

}

const displaySelectedAccountAlert = (formik, value) => {
  Swal.fire({
    title: "¿Cambiar cuenta?",
    text: `${value.name}`,
    icon: "question",
    confirmButtonText: "Seleccionar",
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    allowOutsideClick: false,
    allowEscapeKey: false,
  }).then((result) => {
    if (result.isConfirmed) {
      saveData("account", JSON.stringify(value));
      formik.setFieldValue("account", value.id);
      window.dispatchEvent(new Event("accountStorage"));
    }
  })
    ;
}

const displayServerError = () => {
  Swal.fire({
    title: "Error de servidor",
    text: "Se ha producido un error interno en el servidor y no podemos procesar su solicitud.",
    icon: "error",
    allowOutsideClick: false,
    allowEscapeKey: false,
    confirmButtonText: "Cerrar",
  });
}

const displayClientError = (text = "Por favor, confirme los datos ingresados y vuelva a intentarlo.") => {
  Swal.fire({
    title: "Ocurrió un problema",
    text,
    icon: "error",
    allowOutsideClick: false,
    allowEscapeKey: false,
    confirmButtonText: "Cerrar",
  });
}


const displaySuccessAlert = () => {
  Swal.fire({
    title: "¡Éxito!",
    text: "La acción fue completada con éxito.",
    icon: "success",
    allowOutsideClick: false,
    allowEscapeKey: false,
    confirmButtonText: "Cerrar",
  });
}

const displayLoading = () => {
  Swal.fire({
    title:'Realizando carga masiva, por favor espere...',
    allowOutsideClick: false,
    allowEscapeKey: false,
  });
  Swal.showLoading();
}

const displayGenericLoading = () => {
  Swal.fire({
    title:'cargando, por favor espere...',
    allowOutsideClick: false,
    allowEscapeKey: false,
  });
  Swal.showLoading();
}


export {
  displaySuccessAlert,
  displayAlert,
  displaySelectedAccountAlert,
  displayServerError,
  displayClientError,
  displayLoading,
  displayGenericLoading
};

import { getData } from '../util/localStorageService';
import apiInstance from './apiInstance';

const subUrl = `/activity_channel`;

const findAll = async (setActivityChannels) => {

    const user = getData("user");
    await apiInstance.get(`${subUrl}/activity_channels`, {
        headers: {
            "x-auth-token": user.authToken
        }
    }).then((response) => {
        setActivityChannels(response.data);
    });

};

export { findAll }

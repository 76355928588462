import { IconButton, Switch } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { changeStatus } from "../../api/person";

const ChangePersonStatus = ({ status, personId }) => {

    const [personStatus, setPersonStatus] = useState(false);
    const [checkStatus, setCheckStatus] = useState(status === 0 ? true : false);
  
    const handlePersonStatus = (checked) => {
      changeStatus(1, personId, checked ? 0 : 1, setPersonStatus);
    };
  
    useEffect(() => {
      if (personStatus) {
        setCheckStatus(!checkStatus);
        setPersonStatus(false);
      }
    }, [personStatus]);
  
    return (
      <Switch
        color="primary"
        checked={checkStatus}
        onClick={(e) => {
            handlePersonStatus(e.target.checked);
        }}
      />
    );
  };

  const DeletePerson = ({ personId}) => {
  
    const [labelStatus, setLabelStatus] = useState(false);
  
    const deletePerson = () => {
      Swal.fire({
        title: "¿Eliminar persona?",
        text: "No será posible revertir esta acción.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#10a4da",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          changeStatus(2, personId, 0, setLabelStatus);
        }
      });
    };
  
      if (labelStatus) {
        window.location.reload();
      }
  
    return (
      <IconButton
        variant="contained"
        size="small"
        color="default"
        title="Borrar"
        onClick={() => deletePerson()}
      >
        <Delete></Delete>
      </IconButton>
    );
  };
  
  export { ChangePersonStatus, DeletePerson };
  
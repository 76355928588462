import moment from 'moment/moment';
import { isRole, roles } from '../config/roles';
import { downloadExcelFile } from '../util/downloadFiles';
import { getData } from '../util/localStorageService';
import { displayClientError, displaySuccessAlert } from '../util/swalMessages';
import apiInstance from './apiInstance';

const subUrl = `/case`;

const findAllByUser = async (setCases) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.post(
    `${subUrl}/casesbyuser`,
    {
      id_user: isRole(user.description, 0, 2) || roles.slice(-1).includes(user.description) ? "" : user.id,
      id_account: account.id
    },
    {
      headers: {
        "x-auth-token": user.authToken,
      },
    }
  ).then((response) => {
    setCases(response.data);
  });

}

const findAllFilter = async (values, setCases) => {

  const user = getData("user");
  const account = getData("account");

  await apiInstance.post(`${subUrl}/casesbyuserlist`, {
    id_user: isRole(user.description, 0, 2) || roles.slice(-1).includes(user.description) ? 0 : user.id,
    id_person: values.person,
    //id_problem: values.problem,
    id_sub_problem: values.subProblem,
    confidential: values.confidential,
    id_complexity: values.complexity,
    case_status: values.caseStatus,
    subject: values.subject,
    close_date: values.closeDate,
    rut: values.rut,
    id_account: account.id,
    identifier: values.identifier,
    branch_office: values.client,
    // id_filter: Number.isInteger(values.user) ? values.user : 0,
  },
    {
      headers: {
        "x-auth-token": user.authToken
      }
    }).then((response) => {
      setCases(response.data);
    });

}

const findById = async (id, setUserCase) => {

  const user = getData("user");
  await apiInstance.post(`${subUrl}/case`, {
    id
  }, {
    headers: {
      "x-auth-token": user.authToken
    }
  }).then((response) => {
    if (!response.data) { setUserCase(null); displayClientError(); return }
    setUserCase(response.data);
  });

}

const create = async (values, setRedirect, setLoading) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.post(`${subUrl}/create`, {

    id_person: values.person,
    subject: values.subject,
    id_sub_problem: values.subProblem,
    confidential: values.confidential,
    id_complexity: values.complexity,
    case_status: values.caseStatus,
    close_date: values.caseStatus !== 0 ? moment().format("YYYY-MM-DD") : null,
    labels: values.labels,
    id_user: user.id,
    id_account: account.id,

    description: values.description,
    id_channel_option: values.channelOption,
    id_support_tools_options_subs: values.optionSub,
    report: values.report,
    attention_date: values.attentionDate

  },
    {
      headers: {
        "x-auth-token": user.authToken
      }
    }).then(() => {
      setRedirect(true);
      displaySuccessAlert();
    }).catch(() => {
      setLoading(false);
    });

}

const changeStatus = async (type, id, value, setCaseStatus) => {

  const user = getData("user");
  await apiInstance.post(
    `${subUrl}/change_status`,
    new URLSearchParams({
      id,
      type,
      value,
    }),
    {
      headers: {
        "x-auth-token": user.authToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  ).then(() => {
    setCaseStatus(true);
  });

}

const exportExcel = async (values) => {

  const user = getData("user");
  const account = getData("account");
  await apiInstance.get(`${subUrl}/excel`, {
    responseType: "arraybuffer",
    params: {
      id_user: isRole(user.description, 0, 2) || roles.slice(-1).includes(user.description) ? "" : user.id,
      id_person: values.person,
      id_sub_problem: values.subProblem,
      confidential: values.confidential,
      id_complexity: values.complexity,
      case_status: values.caseStatus,
      subject: values.subject,
      close_date: values.closeDate,
      rut: values.rut,
      id_account: account.id,
      identifier: values.identifier,
      branch_office: values.client,
    },
    headers: {
      "x-auth-token": user.authToken,
    },
  }).then((request) => {
    downloadExcelFile(request, "Reporte_casos");
  });

}

const update = async (id, values, setRedirect, setLoading) => {

  const user = getData("user");
  await apiInstance.post(`${subUrl}/update`, {

    id_person: values.person,
    subject: values.subject,
    id_sub_problem: values.subProblem,
    confidential: values.confidential,
    id_complexity: values.complexity,
    labels: values.labels,
    case_status: values.caseStatus,
    close_date: values.caseStatus !== 0 ? moment().format("YYYY-MM-DD") : null,
    id_user: user.id,
    id

  },
    {
      headers: {
        "x-auth-token": user.authToken
      }
    }).then(() => {
      setRedirect(true);
      displaySuccessAlert();
    }).catch(() => {
      setLoading(false);
    });

}

export { findAllByUser, changeStatus, create, findAllFilter, findById, exportExcel, update }

import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { login } from "../../api/auth";
import AuthContext from "../../context/AuthProvider";
import { saveData } from "../../util/localStorageService";
import { LoginvalidationSchema } from "../../validations/authValidations";
import Section from "../Section";

const title = "Iniciar sesión";

function Login() {
  const [errorMsj, setErrorMsj] = useState(false);
  const [userAccounts, setUserAccounts] = useState([]);
  const { setAuth } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      pass: "",
    },
    onSubmit: (values) => {
      login(values, setErrorMsj, setUserAccounts);
    },
    validationSchema: LoginvalidationSchema,
  });

  useEffect(() => {
    if (userAccounts.length > 0) {
      saveData("account", JSON.stringify(userAccounts[0]));
      setAuth(true);
    }
  }, [userAccounts]);

  return (
    <>
      <Helmet title={title} />
      <Section
        bgImage="/fondoRAS.png"
        size="medium"
        // bgColor="transparent-blue"
        bgImageOpacity={0.8}
      >
        <Container maxWidth="sm" >
          {/* <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
            <img src="https://probenefit.cl/wp-content/uploads/2020/07/Logo-probenefit-horizontal-fondo-blanco-300px.png" />
          </Grid> */}
          <Grid >
            <Card style={{ marginTop: "5%", padding: "3%" }}>
              <CardContent >
                <Grid container={true} spacing={4} >
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h4" style={{ textAlign: "center" }}>
                      Iniciar sesión
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={12} md={12}>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container={true} spacing={2}>
                        <Grid item={true} xs={12} md={12}>
                          <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Correo electrónico"
                            type="text"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.email && Boolean(formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={12}>
                          <TextField
                            fullWidth
                            id="pass"
                            name="pass"
                            label="Contraseña"
                            type="password"
                            variant="outlined"
                            value={formik.values.pass}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.pass && Boolean(formik.errors.pass)
                            }
                            helperText={formik.touched.pass && formik.errors.pass}
                          />
                        </Grid>

                        <Grid item={true} xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            fullWidth
                          >
                            Iniciar sesión
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          style={{ textAlign: "center", color: "red" }}
                        >
                          <Typography style={{ textAlign: "center" }}>
                            <Link href="/recover">¿Olvidaste tu contraseña?</Link>
                          </Typography>
                        </Grid>
                        {errorMsj && (
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography
                              style={{ textAlign: "center", color: "red" }}
                            >
                              Los datos ingresados son incorrectos, intentalo
                              denuevo.
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default Login;

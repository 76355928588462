import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Add, Delete, PermContactCalendar, Remove } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { Autocomplete } from "@material-ui/lab";
import { Avatar, Chip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { findAll as findAllClients, findAllActive } from "../../api/client";
import { exportExcel, findAll, masiveUpload } from "../../api/person";
import { roles } from "../../config/roles";
import AuthContext from "../../context/AuthProvider";
import { getData, saveData } from "../../util/localStorageService";
import { displayLoading } from "../../util/swalMessages";
import { formatRut } from "../../validations/regexValidations";
import { componentPropsDataGrid, customLocaleText } from "../CustomToolbar";
import Section from "../Section";
import { ChangePersonStatus, DeletePerson } from "./PersonTableActions";
import { DeleteFilter } from "../DeleteFilter";

const title = "Personas";

const canSeeActionsAndStatus = roles.slice(0, 2);

const columns = [
  {
    field: "acciones",
    headerName: "Acciones",
    width: 100,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <>
          <IconButton
            component={Link}
            to={"/personas/" + params.row.id}
            variant="contained"
            size="small"
            color="secondary"
            title="Editar"
          >
            <EditIcon></EditIcon>
          </IconButton>
          <DeletePerson personId={params.row.id} />
        </>
      );
    },
  },
  {
    field: "ficha",
    headerName: "Ver ficha",
    width: 80,
    sortable: false,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <>
          <IconButton
            component={Link}
            to={"/personas/ficha/" + params.row.id}
            variant="contained"
            size="small"
            color="secondary"
            title="Ficha"
          >
            <PermContactCalendar></PermContactCalendar>
          </IconButton>
        </>
      )
    }
  },
  {
    field: "habilitado",
    headerName: "Habilitado",
    width: 100,
    sortable: false,
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <ChangePersonStatus
          status={params.row.status}
          personId={params.row.id}
        />
      );
    },
  },
  {
    field: "rut",
    headerName: "RUT",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "name",
    headerName: "Nombre",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "email",
    headerName: "Correo electrónico",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "identifier",
    headerName: "Sucursal",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return <div>{params.row.client.branch_office}</div>;
    },
  },
  {
    field: "created_at",
    headerName: "Fecha registro",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
  {
    field: "updated_at",
    headerName: "Fecha modificación",
    flex: 1,
    minWidth: 150,
    headerAlign: "center",
    align: "center",
    valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
];

function IndexPerson() {

  const { role } = useContext(AuthContext);
  const filtersName = "personFilters";

  const [isEventChanged, setIsEventChanged] = useState(false);

  const columnVisibilityModel = {
    ficha: roles.includes(role),
    acciones: canSeeActionsAndStatus.includes(role),
    habilitado: canSeeActionsAndStatus.includes(role),
  };

  const [persons, setPersons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [filtro, setFiltro] = useState(false);
  const [pageSize, setPageSize] = React.useState(20);
  const [redirect, setRedirect] = useState({ status: false, data: [], errors: [] });
  const [accountName, setaccountName] = useState(getData("account").name)
  const [savedFilters, setSavedFilters] = useState(getData(filtersName));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveFilters = (values) => {
    saveData(filtersName, JSON.stringify(values));
  }

  const handleFiltro = () => {
    setFiltro(!filtro);
  };

  const handleExport = () => {
    exportExcel(formik.values);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: savedFilters?.name ?? "",
      phone: savedFilters?.phone ?? "",
      note: savedFilters?.note ?? "",
      email: savedFilters?.email ?? "",
      rut: savedFilters?.rut ?? "",
      client: savedFilters?.client ?? "",
      status: savedFilters?.status ?? "",
    },
    onSubmit: (values) => {
      saveFilters(values);
      findAll(values, setPersons);
    },
  });

  useEffect(() => {
    findAllActive(setClients);
    findAll(formik.initialValues, setPersons);
  }, []);


  useEffect(() => {
    window.addEventListener('accountStorage', () => {
      if (!isEventChanged) {
        setIsEventChanged(true);
        findAll(formik.values, setPersons);
        findAllClients(null, setClients);
        setaccountName(getData("account").name);
      }
    });
  }, []);


  const uploadExcel = (file) => {
    Swal.fire({
      title: "¿Cargar Excel?",
      text: file.name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#10a4da",
      cancelButtonColor: "#d33",
      confirmButtonText: "Cargar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        masiveUpload(file, setRedirect, setLoading);
      }
    });
  };

  useEffect(() => {
    if (loading) { displayLoading() } else { Swal.close() };
  }, [loading]);

  useEffect(() => {
    if (!savedFilters) formik.resetForm();
  }, [savedFilters]);

  if (redirect.status) {
    return (
      <Redirect
        to={{
          pathname: "/personas/resultado-carga",
          status: { data: redirect },
        }}
      />
    );
  }

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container={true} spacing={4}>
            <Grid item lg={12} md={12} xs={12} xl={12} container>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h4" display="inline" > {title} - </Typography>
                <Typography variant="h4" display="inline" style={{ fontWeight: "bold" }}> {accountName} </Typography>
              </Grid>

              {
                canSeeActionsAndStatus.includes(role) &&
                <Grid item lg={6} md={6} xs={12} xl={6} container justifyContent="flex-end">
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="secondary"
                    variant="outlined"
                  >
                    Opciones
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <input
                        // accept="image/*"
                        style={{ display: "none" }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => {
                          uploadExcel(e.target.files[0]);
                          e.target.value = null
                        }}
                      />
                      <label
                        htmlFor="contained-button-file"
                        style={{ cursor: "pointer" }}
                      >
                        Cargar Excel
                      </label>
                    </MenuItem>
                    <MenuItem component={Link} to="/personas/crear">
                      Crear
                    </MenuItem>
                  </Menu>
                </Grid>
              }
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "0px", paddingBottom: "0px" }} >
              <Chip
                avatar={<Avatar>{filtro ? <Remove /> : <Add />}</Avatar>}
                label={filtro ? "Ocultar filtros" : "Mostrar filtros"}
                onClick={handleFiltro}
                variant="outlined"
                style={{ fontSize: "18px" }}
              />
            </Grid>
            {filtro && (
              <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined">
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container={true} spacing={2} alignItems="center">
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <TextField
                            fullWidth
                            id="rut"
                            name="rut"
                            label="RUT"
                            type="text"
                            variant="outlined"
                            value={formik.values.rut}
                            onChange={formik.handleChange}
                            onKeyUp={(event) => formik.setFieldValue("rut", formatRut(event.target.value))}

                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Nombre"
                            type="text"
                            variant="outlined"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Correo electrónico"
                            type="text"
                            variant="outlined"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <TextField
                            fullWidth
                            id="phone"
                            name="phone"
                            label="Teléfono"
                            type="text"
                            variant="outlined"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <TextField
                            fullWidth
                            id="note"
                            name="note"
                            label="Nota"
                            type="text"
                            variant="outlined"
                            value={formik.values.note}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={6} lg={3}>
                          <Autocomplete
                            disablePortal
                            id="client"
                            name="client"
                            value={{
                              id: formik.values.client,
                              branch_office: clients.find(client => client.id === formik.values.client)?.branch_office ?? "",
                              identifier: clients.find(client => client.id === formik.values.client)?.identifier ?? "",
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            options={clients}
                            getOptionLabel={(option) => `${option.branch_office} ( ${option.identifier} )`}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Sucursal"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "client",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.client
                              );
                            }}
                          />
                        </Grid>

                        <Grid item={true} xs={12} md={10}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                          >
                            Filtrar
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={handleExport}
                            style={{ marginLeft: "2%" }}
                          >
                            Exportar
                          </Button>
                        </Grid>
                        <Grid item={true} xs={12} md={2}>
                          <DeleteFilter filtersName={filtersName} setSavedFilters={setSavedFilters} formik={formik} />
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item={true} xs={12} md={12}>
              <div style={{ height: 515, width: "100%" }}>
                <DataGrid
                  rows={persons}
                  columns={columns}
                  columnVisibilityModel={columnVisibilityModel}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                  disableColumnMenu
                  localeText={customLocaleText}
                  componentsProps={componentPropsDataGrid}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default IndexPerson;

import { Button, Card, CardContent, CircularProgress, Container, Grid, TextField, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { attentionReport } from "../../api/report";
import { reportingValidationSchema } from "../../validations/reportingValidations";
import Section from "../Section";

const title = "Reportería";

function Reporting() {

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      dateInit: "",
      dateFinish: ""
    },
    onSubmit: (values) => {
      setLoading(true);
      attentionReport(values, setLoading);
    },
    validationSchema: reportingValidationSchema
  })

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="lg">
          <Grid container={true} spacing={4}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h4">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card variant="outlined">
                <CardContent>
                  <form onSubmit={formik.handleSubmit} >
                    <Grid container={true} spacing={2} alignItems="center">

                      <Grid item={true} xs={12} md={4} lg={4} >
                        <TextField
                          fullWidth
                          id="dateInit"
                          name="dateInit"
                          label="Fecha inicio"
                          type="date"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={formik.values.dateInit}
                          onChange={formik.handleChange}
                          error={formik.touched.dateInit && Boolean(formik.errors.dateInit)}
                          helperText={formik.touched.dateInit && formik.errors.dateInit}
                        />
                      </Grid>
                      <Grid item={true} xs={12} md={4} lg={4} >
                        <TextField
                          fullWidth
                          id="dateFinish"
                          name="dateFinish"
                          label="Fecha fín"
                          type="date"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={formik.values.dateFinish}
                          onChange={formik.handleChange}
                          error={formik.touched.dateFinish && Boolean(formik.errors.dateFinish)}
                          helperText={formik.touched.dateFinish && formik.errors.dateFinish}

                        />
                      </Grid>

                      <Grid item={true} xs={12} md={4} align="center">
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          type="submit"
                        >
                          Generar reporte
                        </Button>

                      </Grid>

                    </Grid>

                  </form>

                </CardContent>
              </Card>
            </Grid>
            {
              loading &&
              <>
                <Grid item={true} xs={12} md={12} align="center" style={{ marginTop: "10%" }}>
                  <CircularProgress size='15vh' />
                </Grid>
                <Grid item={true} xs={12} md={12} align="center">
                  <Typography variant="h4" >
                    Generando reporte...
                  </Typography>
                </Grid>
              </>
            }
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default Reporting;

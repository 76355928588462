import { Switch } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { changeStatus } from "../../api/attention";

const ChangeAttentionStatus = ({ status, idAttention }) => {
  const [attention, setAttention] = useState(false);
  const [checkStatus, setCheckStatus] = useState(status === 0 ? true : false);

  const handleAttention = (checked) => {
    changeStatus(1, idAttention, checked ? 0 : 1, setAttention);
  };

  useEffect(() => {
    if (attention) {
      setCheckStatus(!checkStatus);
      setAttention(false);
    }
  }, [attention]);

  return (
    <Switch
      color="primary"
      checked={checkStatus}
      onClick={(e) => {
        handleAttention(e.target.checked);
      }}
    />
  );
};

const DeleteAttention = ({ idAttention, idCase }) => {
  const [attention, setAttention] = useState(false);

  const deleteAttention = () => {
    Swal.fire({
      title: "¿Eliminar atención?",
      text: "No será posible revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#10a4da",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus(2, idAttention, 0, setAttention);
      }
    });
  };

    if (attention) {
      window.location.reload();
    }

  return (
      <Delete onClick={() => deleteAttention()} title="Borrar" ></Delete>
  );
};

export { ChangeAttentionStatus, DeleteAttention };


import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import NotFoundPage from "../../pages/404";

function PrivateRoute({ component: Component, requires, ...rest }) {

  const { auth, role } = useContext(AuthContext);

  if (!auth) {
    return <Redirect to="/login" />
  }
  return (
    <Route
      {...rest}
      render={() => {
        return ( requires?.includes(role) ?? false ) ? (
          <Component/>
        ) : (
          <NotFoundPage/>
        );
      }}
    />
  );

}

export default PrivateRoute;

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  TextField
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Add, Delete, Remove } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from "@material-ui/icons/People";
import { Autocomplete } from "@material-ui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { findAll as findAllActivities, findAllActive } from "../../api/activity";
import { findAll as findAllActivityChannels } from '../../api/activityChannel';
import { exportExcel, findAll } from "../../api/activityRecord";
import { roles } from "../../config/roles";
import { getData, saveData } from "../../util/localStorageService";
import { componentPropsDataGrid, customLocaleText } from "../CustomToolbar";
import Section from "../Section";
import { ChangeActivityRecordStatus, DeleteActivityRecord } from "./ActivityRecordTableActions";
import { findAllActive as findAllUserActives } from "../../api/user";
import AuthContext from "../../context/AuthProvider";
import { useContext } from "react";
import { findAll as findAllActivityTypes } from "../../api/activityType";
import { DeleteFilter } from "../DeleteFilter";

const title = "Registros de actividad";
const canSeeActions = roles.slice(0, 3);

const columns = [
  {
    field: "nomina",
    headerName: "Nómina",
    width: 100,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      return (
        <>
          <IconButton
            component={Link}
            to={"/nomina-actividad/index/" + params.row.id}
            variant="contained"
            size="small"
            color="secondary"
            title="Administrar nómina"
          >
            <PeopleIcon></PeopleIcon>
          </IconButton>
        </>
      );
    },
  },
  {
    field: "acciones",
    headerName: "Acciones",
    width: 100,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      return (
        <>
          <IconButton
            component={Link}
            to={"/registro-actividad/" + params.row.id}
            variant="contained"
            size="small"
            color="secondary"
            title="Editar"
          >
            <EditIcon></EditIcon>
          </IconButton>
          <DeleteActivityRecord activityRecordId={params.row.id} />

        </>
      );
    },
  },
  {
    field: "habilitado",
    headerName: "Habilitado",
    width: 100,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: (params) => {
      return <ChangeActivityRecordStatus activityRecordId={params.row.id} status={params.row.status} />;
    },
  },
  {
    field: "user", headerName: "Usuario", flex: 1, headerAlign: 'center', align: 'center', minWidth: 120, renderCell: (params) => {
      return <div> {params.row.user.name} {params.row.user.lastname}</div>
    }
  },
  {
    field: "activity", headerName: "Actividad", flex: 1, headerAlign: 'center', align: 'center', minWidth: 150, renderCell: (params) => {
      return <div> {params.row.activity.name} </div>
    }
  },
  {
    field: "activityChannel", headerName: "Canal actividad", flex: 1, headerAlign: 'center', align: 'center', minWidth: 180, renderCell: (params) => {
      return <div> {params.row.activity_channel.description} </div>
    }
  },
  {
    field: "date", headerName: "Fecha Actividad", flex: 1, headerAlign: 'center', align: 'center', minWidth: 150, valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
  {
    field: "clientIdentifier", headerName: "Identificador sucursal", flex: 1, headerAlign: 'center', align: 'center', minWidth: 180, renderCell: (params) => {
      return <div> {params.row.client?.identifier} </div>
    }
  },
  {
    field: "client", headerName: "Nombre sucursal", flex: 1, headerAlign: 'center', align: 'center', minWidth: 150, renderCell: (params) => {
      return <div> {params.row.client?.branch_office} </div>
    }
  },
  {
    field: "type", headerName: "Tipo", flex: 1, headerAlign: 'center', align: 'center', minWidth: 150, renderCell: (params) => {
      return <div> {params.row.activity_record_type?.description} </div>
    }
  },
  {
    field: "created_at", headerName: "Fecha registro", flex: 1, headerAlign: 'center', align: 'center', minWidth: 150, valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
  {
    field: "updated_at", headerName: "Fecha modificación", flex: 1, headerAlign: 'center', align: 'center', minWidth: 150, valueFormatter: (params) =>
      moment(params.value).format("DD/MM/YYYY hh:mm"),
  },
];

// rcfe
function IndexActivityRecord() {

  const filtersName = "activityRecordFilters";
  const { role } = useContext(AuthContext);

  const [filtro, setFiltro] = useState(false);
  const [pageSize, setPageSize] = React.useState(20);
  const [isEventChanged, setIsEventChanged] = useState(false);
  const [accountName, setaccountName] = useState(getData("account").name)
  const [savedFilters, setSavedFilters] = useState(getData(filtersName));

  const [users, setUsers] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activityChannels, setActivityChannels] = useState([]);
  const [activityRecords, setActivityRecords] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);

  const columnVisibilityModel = {
    acciones: canSeeActions.includes(role),
    habilitado: canSeeActions.includes(role),
  };

  const saveFilters = (values) => {
    saveData(filtersName, JSON.stringify(values));
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: savedFilters?.date ?? "",
      activity: savedFilters?.activity ?? "",
      activityChannel: savedFilters?.activityChannel ?? "",
      user: savedFilters?.user ?? "",
      identifier: savedFilters?.identifier ?? "",
      client: savedFilters?.client ?? "",
      activityType: savedFilters?.activityType ?? "",
      rut: savedFilters?.rut ?? "",
      enterprise: savedFilters?.enterprise ?? ""

    },
    onSubmit: (values) => {
      saveFilters(values);
      findAll(values, setActivityRecords);
    }
  });

  useEffect(() => {
    findAll(formik.initialValues, setActivityRecords);
    findAllActivityTypes(setActivityTypes);
    findAllActive(setActivities);
    findAllActivityChannels(setActivityChannels);
    findAllUserActives(setUsers);
  }, []);

  const handleFiltro = () => {
    setFiltro(!filtro);
  };

  const handleExport = () => {
    exportExcel(formik.values);
  };

  useEffect(() => {
    if (!savedFilters) formik.resetForm();
  }, [savedFilters])

  useEffect(() => {
    window.addEventListener('accountStorage', () => {
      if (!isEventChanged) {
        setIsEventChanged(true);
        findAllActive(setActivities);
        findAll(formik.values, setActivityRecords);
        setaccountName(getData("account").name);
      }
    });
  }, []);

  return (
    <>
      <Helmet title={title} />
      <Section bgColor="transparent" size="medium" bgImageOpacity={1}>
        <Container maxWidth="xl">
          <Grid container={true} spacing={4}>
            <Grid item lg={12} md={12} xs={12} xl={12} container>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="h4" display="inline" > {title} - </Typography>
                <Typography variant="h4" display="inline" style={{ fontWeight: "bold" }}> {accountName} </Typography>
              </Grid>
              {
                canSeeActions.includes(role) &&
                <Grid item xs={12} md={6} lg={6} xl={6} container justifyContent="flex-end">
                  <Button
                    component={Link}
                    to="/registro-actividad/crear"
                    variant="contained"
                    size="large"
                    color="secondary"
                  >
                    Crear
                  </Button>
                </Grid>
              }
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <Chip
                avatar={<Avatar>{filtro ? <Remove /> : <Add />}</Avatar>}
                label={filtro ? "Ocultar filtros" : "Mostrar filtros"}
                onClick={handleFiltro}
                variant="outlined"
                style={{ fontSize: "18px" }}
              />
            </Grid>
            {filtro && (
              <Grid item xs={12} md={12} lg={12}>

                <Card variant="outlined">
                  <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container={true} spacing={2} alignItems="center">
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="identifier"
                            name="identifier"
                            label="Identificador sucursal"
                            type="text"
                            variant="outlined"
                            value={formik.values.identifier}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="client"
                            name="client"
                            label="Nombre sucursal"
                            type="text"
                            variant="outlined"
                            value={formik.values.client}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="enterprise"
                            name="enterprise"
                            label="Empresa"
                            type="text"
                            variant="outlined"
                            value={formik.values.enterprise}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="rut"
                            name="rut"
                            label="RUT"
                            type="text"
                            variant="outlined"
                            value={formik.values.rut}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <Autocomplete
                            disablePortal
                            id="activity"
                            name="activity"
                            value={{
                              id: formik.values.activity,
                              name: activities.find(activity => activity.id === formik.values.activity)?.name ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            options={activities}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Actividad"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "activity",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.activity
                              );
                            }}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <Autocomplete
                            disablePortal
                            id="activityType"
                            name="activityType"
                            value={{
                              id: formik.values.activityType,
                              description: activityTypes.find(activityType => activityType.id === formik.values.activityType)?.description ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            options={activityTypes}
                            getOptionLabel={(option) => option.description}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Tipo"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "activityType",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.activityType
                              );
                            }}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <Autocomplete
                            disablePortal
                            id="activityChannel"
                            name="activityChannel"
                            value={{
                              id: formik.values.activityChannel,
                              description: activityChannels.find(activityChannel => activityChannel.id === formik.values.activityChannel)?.description ?? ""
                            }}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            options={activityChannels}
                            getOptionLabel={(option) => option.description}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Canal de actividad"
                                variant="outlined"
                              />
                            )}
                            onChange={(e, value) => {
                              formik.setFieldValue(
                                "activityChannel",
                                value !== null
                                  ? value.id
                                  : formik.initialValues.activityChannel
                              );
                            }}

                          />
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <TextField
                            fullWidth
                            id="date"
                            name="date"
                            label="Fecha actividad"
                            type="date"
                            variant="outlined"
                            value={formik.values.date}
                            onChange={formik.handleChange}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>

                        <Grid item={true} xs={12} md={10}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                          >
                            Filtrar
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"

                            size="large"
                            style={{ marginLeft: "1%" }}
                            onClick={handleExport}
                          >
                            Exportar
                          </Button>
                        </Grid>
                        <Grid item={true} xs={12} md={2}>
                          <DeleteFilter filtersName={filtersName} setSavedFilters={setSavedFilters} formik={formik} />
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item={true} xs={12} md={12}>
              <div style={{ height: 560, width: "100%" }}>
                <DataGrid
                  rows={activityRecords}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  disableSelectionOnClick
                  disableColumnMenu
                  columnVisibilityModel={columnVisibilityModel}
                  localeText={customLocaleText}
                  componentsProps={componentPropsDataGrid}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
}

export default IndexActivityRecord;
